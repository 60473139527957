import OffersPanel from "components/offers/offersPanel";

const Test: React.FC = () => {
  return (
    <div>
      <OffersPanel offerType="sell"></OffersPanel>
      <div style={{ marginTop: "27px" }}></div>
      <OffersPanel offerType="buy"></OffersPanel>
    </div>
  );
};

export default Test;
