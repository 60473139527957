import styled from "styled-components";

const versionsHistory: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(versionsHistory)`
  .versionsHistory {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    background-color: white;
    padding: 20px;

    .mt-19 {
      margin-top: 19px;
    }

    .MuiTableHead-root {
      tr {
        th {
          font-weight: bold !important;
          font-size: 11px;
        }
      }
    }
    .MuiTableBody-root {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  .MuiTableHead-root {
    th {
      background-color: white !important;
    }
  }
  .MuiTableBody-root {
  }

  .signed-text {
    color: #5DC73F;
  }

  .genericInfos {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    box-shadow: rgba(238, 238, 238, 0.3);
    padding: 20px;
  }
  line-height: 200%;
  .textcontentContainer {
    margin-top: 20px;
    padding: 8px 24px;
    background-color: rgba(238, 238, 238, 0.3);
  }
  .resumeContainer {
    display: flex;
    > div {
      padding-right: 80px;
    }
  }
  .description {
  }
  .value {
    font-weight: bold;
  }
  .taxesinfoContainer {
    margin-top: 20px;
  }
  .paymentinfoContainer {
    margin-top: 20px;
  }
  .section {
    margin-top: 35px;
    font-weight: bold;
    .sectionContent {
      margin-top: 20px;
    }
  }
  .MuiTableCell-root {
    padding: 10px 0px;
  }
  .paymentContainer {
    margin-top: 20px;
    padding: 24px 24px;
    background-color: #eee;
    display: flex;
    align-items: center;
    .payment {
      font-weight: bold;
    }
  }
  .inputsContainer {
    margin-top: 20px;
    padding: 24px 24px;
    padding-bottom: 45px;
    background-color: rgba(0, 83, 38, 0.14);
    .bid {
      margin-right: 50px;
      font-weight: bold;
      float: left;
      line-height: 200%;
      margin-top: 16px;
    }
  }
  .formProblems {
    color: red;
    margin-top: 20px;
    font-weight: bold;
    min-height: 50px;
    padding: 8px 24px;
  }
  .MuiAccordion-root {
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    .MuiAccordionSummary-root {
      border-bottom: 1px solid #e0e0e0;
      // padding: 0;
    }
    .shipment {
      width: 100%;
    }
    .documents {
      width: 100%;
    }
  }
  .controlledPadding {
    padding: 8px 24px;
    ${/* padding padrão do modal do material */ ""}
  }
  .noBorderTable {
    td {
      border: unset !important;
    }
  }
`