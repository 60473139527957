import ContractDetailsStyled from "./contractDetailsPanel.styled";
import DeliverySchedule from "./deliverySchedule";
import GenericInfos from "./genericInfos";
import Header from "./header";
import SignaturesList from "./signaturesList";
import { useEffect } from 'react';
import { createApiService } from "service";
import { useState } from 'react';
import { useSelector } from "react-redux";
import { AppState } from "store/app/reducer";

const ContractDetails: React.FC<any> = (...props) => {
  const [contract, setContract] = useState(null);
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();

  useEffect(() => {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    async function getContract(id: number) {
      if (participantId && stockbrockerId) {
        const { data } = await service.get(`/contracts/${id}?participant=${
          participantId
        }&stockbroker=${stockbrockerId}`);
        setContract(data);
      }
    }
    getContract(props[0].match.params.id);
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  return (
    <ContractDetailsStyled>
      <div className="contractDetailsContainer">
        <Header contract={contract}></Header>
      </div>
      <GenericInfos contract={contract}></GenericInfos>
      <SignaturesList contract={contract}></SignaturesList>
      <DeliverySchedule contract={contract}></DeliverySchedule>
    </ContractDetailsStyled>
  );
};

export default ContractDetails;
