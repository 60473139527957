import axios, { AxiosRequestConfig } from "axios";
import { store } from "store";

export const createApiService = () => {
  const service = axios.create({
    baseURL: `${process.env.REACT_APP_BFF_SERVER_ADDRESS}`,
    timeout: 30000,
  });

  service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config.url?.startsWith("https://viacep.com.br")) {
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        config.headers["Content-Type"] = "application/json";
        config.headers.Authorization = store.getState().auth.accessToken
          ? `Bearer ${store.getState().auth.accessToken}`
          : undefined;
      }

      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  return service;
};
