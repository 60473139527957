import { createContext, useState } from "react";

export const Context = createContext({ value: null, setValue: (v: any) => {} });

const ContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<any>();
  return (
    <Context.Provider value={{ value, setValue } as any}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
