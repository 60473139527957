import { Button, FormControl, Grid } from "@material-ui/core";
import { useState } from "react";
import { createApiService } from "service";
import HeaderStyled from "./header.styled";

const Header: React.FC<any> = ({ product }) => {
  const service = createApiService();
  const [isProductActive, setIsProductActive] = useState(product?.isActive);

  const activateOrInactivateProduct = async () => {
    const uri = isProductActive ? `products/inactivate/${product?.baseProductId}` : `products/activate/${product?.baseProductId}`;
    await service.get(uri);
  }
  return (
    <HeaderStyled>
      <div className="productDetailsHeaderContainer">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <span style={{ fontWeight: "bold" }}>
              Versões do Produto: 
            </span>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <Button variant="outlined" color="primary" onClick={() => {
                activateOrInactivateProduct().then(res => {
                  setIsProductActive(!isProductActive);
                });
              }}>
                {isProductActive ? 'INATIVAR PRODUTO' : 'ATIVAR PRODUTO'}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </HeaderStyled>
  );
};

export default Header;
