import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import HeaderStyled from "./header.styled";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useSocket from "service/socket";
import { useCallback, useEffect, useRef, useState } from "react";
import { OffersState } from "store/offers/reducer";
import { useSelector } from "react-redux";
import { createApiService } from "service";
import { checkMarketIsClosed } from "util/format";
import { AppState } from "store/app/reducer";

type props = {
  offerType: "buy" | "sell";
};

const Header: React.FC<props> = ({ offerType }) => {
  const history = useHistory();
  const {
    setSellFilters,
    setBuyFilters,
    setSellPersonalFilter,
    setBuyPersonalFilter,
  } = useSocket();
  const stateFilter = useRef("*");
  const productFilter = useRef("*");
  const [personalFilter, setPersonalFilter] = useState("*");
  const [changingFilter, setChangingFilter] = useState(false);
  const OffersStore: OffersState = useSelector((state: any) => state.offers);
  const [products, setProducts] = useState([]);
  const AppStore: AppState = useSelector((state: any) => state.app);

  useEffect(() => {
    if (changingFilter) {
      setChangingFilter(false);
    }
  }, [OffersStore]);

  const service = createApiService();

  useEffect(() => {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    if (participantId && stockbrockerId) {
      (async () => {
        service.get("/products/active-products").then(({ data: productTypes }) => {
          setProducts(productTypes);
        });
      })();
    }
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  function applyProductAndLocationFilters() {
    setChangingFilter(true);
    if (offerType === "sell") {
      setSellFilters(
        stateFilter.current, 
        productFilter.current
      );
    } else {
      setBuyFilters(
        stateFilter.current, 
        productFilter.current
      );
    }
  }

  const systemTime = useSelector((state: any) => state.app.systemTime);
  const currentLocalTime = useSelector(
    (state: any) => state.app.currentLocalTime
  );
  const systemTimeSetAt = useSelector(
    (state: any) => state.app.systemTimeSetAt
  );

  const marketIsClosed = useCallback(() => {
    return checkMarketIsClosed(
      new Date(systemTime + (currentLocalTime - systemTimeSetAt))
    );
  }, [currentLocalTime]);

  const selectedStockBroker: AppState["selectedStockBroker"] = useSelector(
    (state: any) => state.app.selectedStockBroker
  );

  const selectedParticipant: AppState["selectedParticipant"] = useSelector(
    (state: any) => state.app.selectedParticipant
  );

  return (
    <HeaderStyled>
      <div className="offersHeaderContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="offerTypeTitle">
            <div
              className={"offerTypeColorIcon offerTypeColorIcon" + offerType}
            />
            <span>
              {offerType === "buy" ? "Ofertas de compra" : "Ofertas de venda"}
            </span>
          </div>
          <div
            className={
              "offerTypeActionButton offerTypeActionButton" + offerType
            }
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                history.push(
                  offerType === "buy"
                    ? "/registrar-oferta-compra"
                    : "/registrar-oferta-venda"
                );
              }}
              disabled={
                marketIsClosed() ||
                (selectedStockBroker !== null &&
                  selectedStockBroker.id === "*") ||
                (selectedParticipant !== null &&
                  selectedParticipant.id === "none") ||
                (selectedParticipant !== null &&
                  selectedParticipant.allowedAtBroker === false)
              }
            >
              {offerType === "buy"
                ? "Registrar Oferta de Compra"
                : "Registrar Oferta de Venda"}
            </Button>
          </div>
        </Grid>
        <div className="filters">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="selectFilters">
              <FormControl>
                <InputLabel id={"product-type-select-label" + offerType}>
                  Produto
                </InputLabel>
                <Select
                  labelId={"product-type-select-label" + offerType}
                  id={"product-type-select" + offerType}
                  style={{ width: "200px" }}
                  onChange={(e: any) => {
                    productFilter.current = e.target.value;
                  }}
                  defaultValue={productFilter.current}
                >
                  <MenuItem value={"*"}>Todos</MenuItem>
                  {products.map((p: any) => (
                    <MenuItem key={p.id} value={`${p.baseProductId}`}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginLeft: "16px" }}>
                <InputLabel id="product-uf-select-label">UF</InputLabel>
                <Select
                  labelId="product-uf-select-label"
                  id={"product-uf-select" + offerType}
                  style={{ width: "80px" }}
                  defaultValue={stateFilter.current}
                  onChange={(e: any) => {
                    stateFilter.current = e.target.value;
                  }}
                  MenuProps={{ className: "menuState" }}
                >
                  <MenuItem value={"AC"}>AC</MenuItem>
                  <MenuItem value={"AL"}>AL</MenuItem>
                  <MenuItem value={"AP"}>AP</MenuItem>
                  <MenuItem value={"AM"}>AM</MenuItem>
                  <MenuItem value={"BA"}>BA</MenuItem>
                  <MenuItem value={"CE"}>CE</MenuItem>
                  <MenuItem value={"DF"}>DF</MenuItem>
                  <MenuItem value={"ES"}>ES</MenuItem>
                  <MenuItem value={"GO"}>GO</MenuItem>
                  <MenuItem value={"MA"}>MA</MenuItem>
                  <MenuItem value={"MT"}>MT</MenuItem>
                  <MenuItem value={"MS"}>MS</MenuItem>
                  <MenuItem value={"MG"}>MG</MenuItem>
                  <MenuItem value={"PA"}>PA</MenuItem>
                  <MenuItem value={"PB"}>PB</MenuItem>
                  <MenuItem value={"PR"}>PR</MenuItem>
                  <MenuItem value={"PE"}>PE</MenuItem>
                  <MenuItem value={"PI"}>PI</MenuItem>
                  <MenuItem value={"RJ"}>RJ</MenuItem>
                  <MenuItem value={"RN"}>RN</MenuItem>
                  <MenuItem value={"RS"}>RS</MenuItem>
                  <MenuItem value={"RO"}>RO</MenuItem>
                  <MenuItem value={"RR"}>RR</MenuItem>
                  <MenuItem value={"SC"}>SC</MenuItem>
                  <MenuItem value={"SP"}>SP</MenuItem>
                  <MenuItem value={"SE"}>SE</MenuItem>
                  <MenuItem value={"TO"}>TO</MenuItem>
                  <MenuItem value={"*"}>Todos</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginLeft: "16px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "12px" }}
                  onClick={applyProductAndLocationFilters}
                  disabled={changingFilter}
                >
                  CONSULTAR
                </Button>
              </FormControl>
            </div>
            <div className="radioFilters">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={personalFilter}
                  onChange={(e: any) => {
                    if (offerType === "sell") {
                      setSellPersonalFilter(e.target.value);
                    } else {
                      setBuyPersonalFilter(e.target.value);
                    }
                    setPersonalFilter(e.target.value);
                  }}
                  row
                  // onChange={handleChange}
                >
                  <FormControlLabel
                    value="*"
                    control={<Radio />}
                    label="Todas as ofertas"
                  />
                  <FormControlLabel
                    value="myOffers"
                    control={<Radio />}
                    label="Minhas ofertas"
                  />
                  <FormControlLabel
                    value="myBids"
                    control={<Radio />}
                    label="Meus lances"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
        </div>
      </div>
    </HeaderStyled>
  );
};

export default Header;
