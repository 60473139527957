import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Input,
  InputLabel,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useEffect, useReducer, useRef, useState } from "react";
import MaskedInput from "react-text-mask";
import DeliveryScheduleStyled from "./deliverySchedule.styled";
import { DateTime } from "luxon";

function DateMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
      guide={false}
    />
  );
}

function ValueMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={" "}
      showMask
      guide={false}
    />
  );
}

export type deliverySchedules = {
  date: string;
  value: number;
}[];

type props = {
  quanty: number;
  value: deliverySchedules;
  onChange?: (v: deliverySchedules) => void;
  allowMultiple: boolean;
  unit?: string;
};

const DeliverySchedule: React.FC<props> = ({
  value,
  quanty,
  onChange,
  allowMultiple,
  unit
}) => {
  const [deliveryDates, setDeliveryDates] = useState<deliverySchedules>([
    ...value,
  ]);
  const [sumOfDeliveries, setSumOfDeliveries] = useState(
    value.reduce((acum, current) => {
      return acum + current.value;
    }, 0)
  );

  useEffect(() => {
    setDeliveryDates([...value]);
    setSumOfDeliveries(
      value.reduce((acum, current) => {
        return acum + current.value;
      }, 0)
    );
  }, [value]);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const refValueDate = useRef("");
  const refQuanty = useRef("");

  function handleDateChange(e: any) {
    refValueDate.current = e.target.value;
    forceUpdate();
  }

  function handleQuantyChange(e: any) {
    refQuanty.current = e.target.value;
    forceUpdate();
  }

  useEffect(() => {
    setDeliveryDates(deliveryDates.splice(1, 99999));
    setSumOfDeliveries(
      deliveryDates.reduce((acum, current) => {
        return acum + current.value;
      }, 0)
    );

    if (onChange) {
      onChange(deliveryDates);
    }
  }, [allowMultiple]);

  function addDelivery() {
    const newDeliveryDates = [
      ...deliveryDates,
      {
        date: refValueDate.current,
        value: parseInt(refQuanty.current),
      },
    ]
      .sort((d1, d2) => {
        return (
          parseInt(d1.date.split("/")[0]) - parseInt(d2.date.split("/")[0])
        );
      })
      .sort((d1, d2) => {
        return (
          parseInt(d1.date.split("/")[1]) - parseInt(d2.date.split("/")[1])
        );
      });

    setDeliveryDates(newDeliveryDates);
    setSumOfDeliveries(
      newDeliveryDates.reduce((acum, current) => {
        return acum + current.value;
      }, 0)
    );

    if (onChange) {
      onChange(newDeliveryDates);
    }

    refValueDate.current = " ";
    refQuanty.current = "";

    forceUpdate();
  }

  function canIncludeDelivery() {
    return (
      parseInt(refQuanty.current) <= quanty - sumOfDeliveries &&
      parseInt(refQuanty.current) > 0 &&
      refValueDate.current.length === 7 &&
      refValueDate.current.match(/\d\d\/\d\d\d\d/) !== null &&
      (deliveryDates.length === 0 || allowMultiple)
    );
  }

  function removeDelivery(index: number) {
    const newDeliveryDates = [
      ...deliveryDates.slice(0, index),
      ...deliveryDates.slice(index + 1, deliveryDates.length),
    ];

    setDeliveryDates(newDeliveryDates);
    setSumOfDeliveries(
      newDeliveryDates.reduce((acum, current) => {
        return acum + current.value;
      }, 0)
    );

    if (onChange) {
      onChange(newDeliveryDates);
    }
  }

  return (
    <DeliveryScheduleStyled>
      <div className="inputsContainer">
        <FormControl style={{ minWidth: "150px" }}>
          <InputLabel htmlFor="deliverydateinput">Período (MM/AAAA)</InputLabel>
          <Input
            value={refValueDate.current}
            onChange={handleDateChange}
            id="deliverydateinput"
            inputComponent={DateMask}
            onBlur={forceUpdate}
            onKeyDown={(e) => {
              // console.log(e);
              if (
                e.key !== "Backspace" &&
                ((refValueDate.current.length === 0 &&
                  e.key !== "1" &&
                  e.key !== "0") ||
                  (refValueDate.current.replace(/\s/g, "").length === 1 &&
                    refValueDate.current[0] === "1" &&
                    e.key !== "0" &&
                    e.key !== "1" &&
                    e.key !== "2") ||
                  (refValueDate.current.replace(/\s/g, "").length === 1 &&
                    refValueDate.current[0] === "0" &&
                    e.key === "0") ||
                  (refValueDate.current.replace(/\s/g, "").length === 3 &&
                    e.key !== "2") ||
                  (refValueDate.current.replace(/\s/g, "").length === 2 &&
                    e.key !== "2") ||
                  (refValueDate.current.replace(/\s/g, "").length === 4 &&
                    e.key !== "0") ||
                  (refValueDate.current.replace(/\s/g, "").length === 5 &&
                    parseInt(
                      (refValueDate.current + e.key + "9").split("/")[1]
                    ) < 2021) ||
                  (refValueDate.current.replace(/\s/g, "").length === 6 &&
                    parseInt((refValueDate.current + e.key).split("/")[1]) <
                      DateTime.now().get("year")))
              ) {
                e.preventDefault();
                e.stopPropagation();
              }
              // console.log(refValueDate.current.replace(/\s/g, "").length);
              // console.log(refValueDate.current);
              // console.log(e.key);
              // e.preventDefault();
              // e.stopPropagation();
            }}
          />
        </FormControl>
        <FormControl style={{ marginLeft: "20px", minWidth: "150px" }}>
          <InputLabel htmlFor="deliveryvolumeinput">Volume</InputLabel>
          <Input
            value={refQuanty.current}
            onChange={handleQuantyChange}
            id="deliveryvolumeinput"
            inputComponent={ValueMask}
            onKeyDown={(e) => {
              if (
                e.code &&
                e.code.toLowerCase() === "enter" &&
                canIncludeDelivery()
              ) {
                addDelivery();
              }
            }}
          />
        </FormControl>
        <Button
          style={{ marginLeft: "20px", height: "100%" }}
          variant="outlined"
          color="primary"
          onClick={() => {
            addDelivery();
          }}
          disabled={!canIncludeDelivery()}
        >
          Incluir
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="tabela de entregas"
          style={{ marginTop: "30px" }}
        >
          <TableBody>
            {deliveryDates.map((delivery, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">{delivery.date}</TableCell>
                  <TableCell align="left">{delivery.value} {unit}</TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CancelIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeDelivery(i);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <span
        className="remainingDelivery"
        style={{ color: quanty - sumOfDeliveries > 0 ? "red" : "green" }}
      >
        Volume restante: {quanty - sumOfDeliveries} {unit}
      </span>
    </DeliveryScheduleStyled>
  );
};

export default DeliverySchedule;
