import ContractsPanel from "components/contracts/contractsPanel";

const Test: React.FC = () => {
  return (
    <div>
      <ContractsPanel></ContractsPanel>
    </div>
  );
};

export default Test;
