import styled from "styled-components";

const checkGroupStyled: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default styled(checkGroupStyled)`
  .checkgroupcontainer {
    display: flex;
    align-items: center;
    .label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .checkgrouperror {
    // .label {
    //   color: red;
    // }
    .MuiCheckbox-root {
      color: red;
    }
    .checklabel {
      color: red;
    }
  }
  .checklist {
    display: flex;
  }
  .checkBox {
    display: inline-block;
  }
  .checklabel {
    display: inline-block;
  }
`;
