import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormAccordionStyled from "./index.styled";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useState } from "react";

type props = {
  expand: boolean;
  title: string;
  onExpand?: () => void;
  onHide?: () => void;
  isComplete: boolean;
};

const FormAccordion: React.FC<props> = ({
  expand,
  children,
  title,
  isComplete,
  onHide,
  onExpand,
}) => {
  function handleExpand() {
    if (expand) {
      if (onHide) {
        onHide();
      }
    } else {
      if (onExpand) {
        onExpand();
      }
    }
  }
  return (
    <FormAccordionStyled>
      <Accordion
        expanded={expand}
        onChange={handleExpand}
        style={{ padding: "0" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={{ padding: "22px" }}
        >
          <span>{title}</span>
          {isComplete ? (
            <CheckCircleIcon className="iconGreen" />
          ) : (
            <InfoIcon className="iconOrange" />
          )}
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0" }}>{children}</AccordionDetails>
      </Accordion>
    </FormAccordionStyled>
  );
};

export default FormAccordion;
