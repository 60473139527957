import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  InputLabel,
  FormControl,
  Input,
  MenuItem,
  Select,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableHead,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FullOfferDTO, OfferDTO } from "models/offer.model";
import BidModalStyled from "./index.styled";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import { createApiService } from "service";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import MaskedInput from "react-text-mask";
import createNumberMask from "util/createNumberMaskDatagro";
import { AppState } from "store/app/reducer";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { BidDTO } from "models/bid.model";
import { ProgressTimer } from "./updateTimer";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  checkMarketIsClosed,
  formatCNPJ,
  formatCurrency,
  formatProductUnit,
  formatQuanty,
  getCurrencyPrefix,
} from "util/format";
import { OffersState } from "store/offers/reducer";
import useSocket from "service/socket";
import { OfferResume } from "./offerResume";

const quantityMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const QuantyInput: React.FC<any> = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...quantityMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

type CancelBidProps = {
  open: boolean;
  bidId: number;
  offerId: string;
  participantId: string;
  stockbrokerId: string;
  onClose: () => void;
  onAccept: () => void;
  value: number;
  unit: string;
  coin: string;
};

const CancelBid: React.FC<CancelBidProps> = (props) => {
  const {
    onClose,
    open,
    bidId,
    offerId,
    onAccept,
    participantId,
    stockbrokerId,
    value,
    unit,
    coin,
  } = props;
  const [cancellingBid, setCancellingBid] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const service = createApiService();

  const { enqueueSnackbar } = useSnackbar();

  function cancelBid() {
    if (cancellingBid) {
      return;
    }
    setCancellingBid(true);

    service
      .put(
        `/bids/${bidId}/cancel?offerId=${offerId.replace(
          "/",
          "-"
        )}&participant=${participantId}&stockbroker=${stockbrokerId}`,
        {}
      )
      .then(() => {
        handleClose();
        setCancellingBid(false);
        enqueueSnackbar("Lance cancelado com sucesso", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        onAccept();
      })
      .catch((e) => {
        setCancellingBid(false);
        if (e.response.data.code === 1003) {
          enqueueSnackbar("Erro: mercado encerrado", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else if (e.response.data.code === 1006) {
          enqueueSnackbar(
            "Lance não pode ser cancelado pois é um lance vencedor",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
        } else if (e.response.status === 404) {
          enqueueSnackbar("Lance não encontrado", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else {
          enqueueSnackbar("Erro ao cancelar lance", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
        onClose();
      });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Deseja realmente cancelar o lance no valor de{" "}
        {formatCurrency(value / 100, coin)} / {formatProductUnit(unit)} ?
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: "20px" }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={cancelBid}
            disabled={cancellingBid}
          >
            Sim
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type ConfirmSetWinnerBidProps = {
  open: boolean;
  bidId: number;
  offerId: string;
  participantId: string;
  stockbrokerId: string;
  onClose: () => void;
  onAccept: () => void;
  partialBid: boolean;
};

const ConfirmSetWinnerBid: React.FC<ConfirmSetWinnerBidProps> = (props) => {
  const {
    onClose,
    open,
    bidId,
    offerId,
    onAccept,
    participantId,
    stockbrokerId,
    partialBid,
  } = props;
  const [settingWinnerBid, setSettingWinnerBid] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const service = createApiService();

  const { enqueueSnackbar } = useSnackbar();

  function setWinnerBid() {
    if (settingWinnerBid) {
      return;
    }
    setSettingWinnerBid(true);

    service
      .post(
        `/offers/${offerId.replace("/", "-")}/setwinnerbid?partialbid=${
          partialBid ? "true" : "false"
        }`,
        {
          bidId,
          participantId,
          stockbrokerId,
        }
      )
      .then(({ data }) => {
        handleClose();
        setSettingWinnerBid(false);
        enqueueSnackbar(
          `Lance aceito com sucesso, ${
            data.offerFinished
              ? "oferta encerrada"
              : "oferta parcialmente executada"
          }`,
          {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
        onAccept();
      })
      .catch((e) => {
        setSettingWinnerBid(false);
        if (e.response.data.code === 1003) {
          enqueueSnackbar("Erro: mercado encerrado", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else if (e.response.data.code === 1008) {
          enqueueSnackbar(
            "Erro: Oferta não tem quantidade suficiente para atender ao lance",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
        } else {
          enqueueSnackbar("Erro ao confirmar lance como vencedor", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
        onClose();
      });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Deseja realmente aceitar o lance como vencedor da oferta?
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: "20px" }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={setWinnerBid}
            disabled={settingWinnerBid}
          >
            Sim
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type NotBestBidDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
};

const NotBestBidDialog: React.FC<NotBestBidDialogProps> = (props) => {
  const { onClose, onConfirm, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Erro: Seu lance deve ser superar o melhor lance atual
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
          >
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type ConfirmDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  offerId: string;
  quanty: number;
  value: number;
  unit: string;
  unitDescription: string;
  coin: string;
  award: number;
  valuetobedefined: boolean;
};

const ConfirmBidDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    onClose,
    onConfirm,
    open,
    offerId,
    quanty,
    value,
    unit,
    unitDescription,
    coin,
    award,
    valuetobedefined,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Deseja confirmar o lance?
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "14px" }}>
            Atenção. Este é um aviso de confirmação de lance na oferta nº{" "}
            {offerId} de {formatQuanty(quanty)} {unitDescription}.
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px", color: "red" }}>
            Após clicar em confirmar lance não será mais possível cancelar
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            {!valuetobedefined && (
              <b>
                Deseja confirmar o lance de: {formatCurrency(value, coin)} por{" "}
                {unit}?
              </b>
            )}
            {valuetobedefined && (
              <b>
                Deseja confirmar o lance com prêmio de:{" "}
                {formatCurrency(award / 100, coin)} por {unit}?
              </b>
            )}
          </span>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type props = {
  open: boolean;
  offer?: OfferDTO;
  onClose?: () => void;
  onConfirm?: (winner: boolean) => void;
  id: string;
  visualizerMode: boolean;
};

type member = {
  id: string;
  cpfCnpj: string;
  companyName: string | null;
};

type broker = {
  id: string;
  cpfCnpj: string;
  companyName: string | null;
};

const BidModal: React.FC<props> = (props) => {
  const { onClose, onConfirm, open, visualizerMode } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const [offer, setOffer] = useState<FullOfferDTO | null>(null);
  const [bids, setBids] = useState<BidDTO[]>([]);
  const [updatingBids, setUpdatingBids] = useState(false);
  const [showSelectWinnerBidModal, setShowSelectWinnerBidModal] =
    useState(false);
  const [showCancelBidModal, setShowCancelBidModal] = useState(false);
  const [bidIdSelectWinnerBidModal, setBidIdSelectWinnerBidModal] = useState(0);
  const [bidIdCancelBidModal, setBidIdCancelBidModal] = useState(0);
  const [bidValueCancelBidModal, setBidValueCancelBidModal] = useState(0);
  const [bidUnitCancelBidModal, setBidUnitCancelBidModal] = useState("m3");

  const [value, setValue] = useState(0);
  const [award, setAward] = useState(0);
  const [stringValue, setStringValue] = useState("");
  const [awardStringValue, setAwardStringValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showNotBestBidDialog, setShowNotBestBidDialog] = useState(false);
  const [expandAcordeonShipment, setExpandAcordeonShipment] = useState(false);
  const [expandAcordeonDocuments, setExpandAcordeonDocuments] = useState(false);
  const [expandAcordeonClients, setExpandAcordeonClients] = useState(false);
  const [showConfirmBidModal, setShowConfirmBidModal] = useState(false);

  const [paymentBank, setPaymentBank] = useState("");
  const [paymentAgency, setPaymentAgency] = useState("");
  const [paymentAccount, setPaymentAccount] = useState("");
  const [paymentPix, setPaymentPix] = useState("");

  const AppStore: AppState = useSelector((state: any) => state.app);
  const OffersStore: OffersState = useSelector((state: any) => state.offers);
  const service = createApiService();

  function getBids() {
    setUpdatingBids(true);
    console.log("getting bids...2");
    service
      .get(
        `/bids?offerId=${props.id.replace("/", "-")}&participant=${
          AppStore.selectedParticipant?.id
        }&stockbroker=${AppStore.selectedStockBroker?.id}`
      )
      .then(({ data: bids }) => {
        setBids(bids);
        setUpdatingBids(false);
      })
      .catch((e) => {
        setUpdatingBids(false);
      });
  }

  function getOffer() {
    service
      .get(
        `/offers/${props.id.replace("/", "-")}?participant=${
          AppStore.selectedParticipant?.id
        }&stockbroker=${AppStore.selectedStockBroker?.id}`
      )
      .then(({ data: offer }) => {
        setOffer(offer);
      });
  }

  function getPaymentInfo() {
    if (
      AppStore.selectedStockBroker === null ||
      AppStore.selectedParticipant === null
    ) {
      return;
    }
    const stockBrokerId = AppStore.selectedStockBroker.id;
    const participantId = AppStore.selectedParticipant.id;

    service
      .get(
        `/offers/${props.id.replace(
          "/",
          "-"
        )}/mylastPaymentInfo?stockbroker=${stockBrokerId}&participant=${participantId}`
      )
      .then(({ data: payment }) => {
        if (payment) {
          setPaymentAccount(payment.account);
          setPaymentAgency(payment.agency);
          setPaymentBank(payment.bankCode);
          setPaymentPix(payment.pix);
        }
      })
      .catch((e) => {});
  }

  useEffect(() => {
    (async () => {
      if (props.id !== "0") {
        getOffer();

        getBids();

        getPaymentInfo();
      }
    })();
  }, [props.id, open]);

  // useEffect(() => {
  //   if (
  //     OffersStore.buy_offers.some((b) => b.id === props.id && b.changed) ||
  //     OffersStore.sell_offers.some((s) => s.id === props.id && s.changed)
  //   ) {
  //     getBids();
  //   }
  // }, [OffersStore]);

  useEffect(() => {
    if (!open) {
      setValue(0);
      setAward(0);
      setQuantity("");
      setPaymentAccount("");
      setPaymentAgency("");
      setPaymentPix("");
      setPaymentBank("");
    } else {
      getPaymentInfo();
    }
  }, [open]);

  const { enqueueSnackbar } = useSnackbar();

  async function sendBid() {
    setShowConfirmBidModal(false);
    try {
      if (!AppStore.selectedStockBroker || !AppStore.selectedParticipant) {
        return;
      }
      const { data } = await service.post(
        `/bids?partialOffer=${offer?.acceptPartialBid ? "true" : "false"}`,
        {
          stockBrokerId: AppStore.selectedStockBroker.id,
          participantId: AppStore.selectedParticipant.id,
          offerId: offer?.id.replace("/", "-"),
          value: offer?.valueToBeDefined ? null : parseInt(value * 100 + ""),
          award: offer?.valueToBeDefined ? parseInt(award * 100 + "") : null,
          productId: offer?.product.id,
          quantity: offer?.acceptPartialBid ? parseInt(quantity) : null,
          payment:
            offer !== null && offer.type === "PURCHASE_OFFER"
              ? {
                  bankCode: paymentBank,
                  agency: paymentAgency,
                  account: paymentAccount,
                  pix: paymentPix,
                }
              : null,
        }
      );

      if (onConfirm) {
        onConfirm(data.winner);
      }
    } catch (e: any) {
      if (e.response.data.code === 1002) {
        setShowNotBestBidDialog(true);
      } else if (e.response.data.code === 1004) {
        enqueueSnackbar(
          "Erro: participante não pode dar um lance em uma oferta que ele mesmo criou",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      } else if (e.response.status === 403) {
        enqueueSnackbar(
          "Erro: impedido de realizar operações para este participante",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      } else if (e.response.data.code === 1003) {
        enqueueSnackbar("Erro: mercado encerrado", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else if (e.response.data.code === 1009) {
        enqueueSnackbar(
          "Este participante não está autorizado a negociar este produdo. Favor entrar em contato com o administrador!",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
    }
  }

  const systemTime = useSelector((state: any) => state.app.systemTime);
  const currentLocalTime = useSelector(
    (state: any) => state.app.currentLocalTime
  );
  const systemTimeSetAt = useSelector(
    (state: any) => state.app.systemTimeSetAt
  );

  const marketIsClosed = useCallback(() => {
    return checkMarketIsClosed(
      new Date(systemTime + (currentLocalTime - systemTimeSetAt))
    );
  }, [currentLocalTime]);

  const canSubmit = useCallback(() => {
    if (!offer) {
      return false;
    }
    if (marketIsClosed()) {
      return false;
    }
    if (offer.valueToBeDefined) {
      return (
        (parseInt(quantity) <= offer.remainingquantity ||
          !offer.acceptPartialBid) &&
        (offer.type !== "SALE_OFFER" || award <= offer?.offerAward / 100) &&
        (offer.type !== "PURCHASE_OFFER" || award >= offer?.offerAward / 100) &&
        awardStringValue.indexOf(",") > 0 &&
        awardStringValue.split(",")[1].length === 2 &&
        (offer.type !== "PURCHASE_OFFER" ||
          (paymentBank !== "" && paymentAgency !== "" && paymentAccount !== ""))
      );
    }
    return (
      value > 0 &&
      (parseInt(quantity) <= offer.remainingquantity ||
        !offer.acceptPartialBid) &&
      (offer.type !== "SALE_OFFER" || value <= offer?.unitPrice / 100) &&
      (offer.type !== "PURCHASE_OFFER" || value >= offer?.unitPrice / 100) &&
      stringValue.indexOf(",") > 0 &&
      stringValue.split(",")[1].length === 2 &&
      (offer.type !== "PURCHASE_OFFER" ||
        (paymentBank !== "" && paymentAgency !== "" && paymentAccount !== ""))
    );
  }, [
    value,
    stringValue,
    paymentBank,
    paymentAgency,
    paymentAccount,
    currentLocalTime,
  ]);

  // console.log(props.id);

  const formProblems = useCallback(() => {
    if (!offer) {
      return "";
    }
    if (offer.type === "SALE_OFFER" && value > offer?.unitPrice / 100) {
      return "* Valor maior que o necessário para arrematar a oferta";
    }
    if (offer.type === "PURCHASE_OFFER" && value < offer?.unitPrice / 100) {
      return "* Valor menor que o necessário para arrematar a oferta";
    }
    if (offer.remainingquantity < parseInt(quantity)) {
      return "* O volume disponível na oferta é menor que o volume pedido";
    }
  }, [value, quantity]);

  const { watchOffer } = useSocket();

  useEffect(() => {
    if (open) {
      const stopWatch = watchOffer(props.id, () => {
        getOffer();

        getBids();

        console.log("getting bids...");
      });
      return stopWatch;
    }
  }, [props.id, open]);

  const defaultMaskOptions = {
    prefix: "R$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
    requireDecimal: true,
  };

  const CurrencyInputNegative: React.FC<any> = useCallback(
    ({ maskOptions, ...inputProps }) => {
      const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        allowNegative: true,
        prefix: "",
        ...maskOptions,
      });

      return <MaskedInput mask={currencyMask} {...inputProps} />;
    },
    [offer]
  );

  const CurrencyInput: React.FC<any> = useCallback(
    ({ maskOptions, ...inputProps }) => {
      console.log(maskOptions);

      const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        prefix: getCurrencyPrefix(offer!.coin),
        ...maskOptions,
      });

      return <MaskedInput mask={currencyMask} {...inputProps} />;
    },
    [offer]
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "left" }}>
        {offer && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Oferta de {offer.type === "SALE_OFFER" ? "venda" : "compra"}:{" "}
              {offer.id}{" "}
            </span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        )}
      </DialogTitle>
      {offer && (
        <DialogContent style={{ padding: "0" }}>
          <BidModalStyled>
            <div
              className="textcontentContainer"
              style={{ width: "100%", minWidth: "1000px" }}
            >
              <OfferResume offer={offer} />
            </div>
            <div className="controlledPadding">
              {visualizerMode && (
                <div className="bidsContainer">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>
                      <b>Lances da oferta: </b>
                    </span>
                    {/* {updatingBids && "atualizando..."} */}
                    {/* {!updatingBids && (
                      <ProgressTimer
                        timeInSeconds={20}
                        functionExec={() => {
                          getBids();
                        }}
                      />
                    )} */}
                  </div>
                  {bids.length === 0 && (
                    <span>
                      <br />
                      Nenhum lance recebido
                    </span>
                  )}
                  {bids.length > 0 && (
                    <TableContainer
                      component={Paper}
                      className={bids.length === 1 ? "noBorderTable" : ""}
                      style={{ marginTop: "20px" }}
                    >
                      <Table
                        // className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Hora</TableCell>
                            <TableCell align="left">Operador</TableCell>
                            <TableCell align="left">Participante</TableCell>
                            <TableCell align="left">Corretora</TableCell>
                            <TableCell align="left">Ordem</TableCell>
                            <TableCell align="left">
                              {!offer.valueToBeDefined
                                ? "Valor ofertado"
                                : "Prêmio ofertado"}
                            </TableCell>
                            {offer.acceptPartialBid && (
                              <TableCell align="left">Quantidade</TableCell>
                            )}
                            {offer.isOfferCreator ||
                              ((bids as any).some((b: any) => b.show) && (
                                <TableCell align="left"></TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bids.map((doc, key) => {
                            return (
                              <TableRow key={key}>
                                <TableCell align="left">
                                  {doc.winnerBid && (
                                    <>
                                      <CheckCircleIcon
                                        style={{ color: "green" }}
                                      />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    color:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "red",
                                  }}
                                >
                                  {doc.status === "CREATED"
                                    ? doc.winnerBid
                                      ? "Aceito"
                                      : "Criado"
                                    : "Cancelado"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  {DateTime.fromISO(doc.createdAt).toFormat(
                                    "dd/MM/yyyy"
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  {DateTime.fromISO(doc.createdAt).toFormat(
                                    "HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  <span>
                                    {(doc as any).show
                                      ? doc.bidCreatorName || doc.bidCreatorId
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  <span>
                                    {(doc as any).show
                                      ? doc.participantName || ""
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  <span>
                                    {(doc as any).show
                                      ? doc.stockBrokerName || ""
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  {offer.type === "SALE_OFFER"
                                    ? "Lance de compra"
                                    : "Lance de venda"}{" "}
                                  {/* {doc.id} */}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    textDecoration:
                                      doc.status === "CREATED"
                                        ? "unset"
                                        : "line-through",
                                  }}
                                >
                                  {formatCurrency(
                                    (!offer.valueToBeDefined
                                      ? doc.value
                                      : doc.award) / 100,
                                    offer.coin
                                  )}{" "}
                                  / {offer.product.measurementUnit.abbreviation}
                                </TableCell>
                                {offer.acceptPartialBid && (
                                  <TableCell
                                    align="left"
                                    style={{
                                      textDecoration:
                                        doc.status === "CREATED"
                                          ? "unset"
                                          : "line-through",
                                    }}
                                  >
                                    {formatQuanty(doc.quantity)}{" "}
                                    {formatProductUnit(offer.product.unit)}
                                  </TableCell>
                                )}
                                {offer.isOfferCreator && (
                                  <TableCell align="right">
                                    <div className="acceptBitButtonContainer">
                                      <Button
                                        variant="contained"
                                        disabled={
                                          (bids.some(
                                            (b, k) =>
                                              b.status === "CREATED" && k < key
                                          ) &&
                                            !offer.acceptPartialBid) ||
                                          offer.offerStatus !== "CREATED" ||
                                          doc.status !== "CREATED" ||
                                          doc.quantity >
                                            offer.remainingquantity ||
                                          doc.winnerBid
                                        }
                                        onClick={() => {
                                          setBidIdSelectWinnerBidModal(doc.id);
                                          setShowSelectWinnerBidModal(true);
                                        }}
                                      >
                                        ACEITAR
                                      </Button>
                                    </div>
                                  </TableCell>
                                )}
                                {(doc as any).show &&
                                  doc.participantId ===
                                    AppStore.selectedParticipant!.id && (
                                    <TableCell align="right">
                                      <div className="acceptBitButtonContainer">
                                        <Button
                                          variant="contained"
                                          disabled={
                                            offer.offerStatus !== "CREATED" ||
                                            doc.status !== "CREATED" ||
                                            doc.winnerBid
                                          }
                                          onClick={() => {
                                            setBidIdCancelBidModal(doc.id);
                                            setBidValueCancelBidModal(
                                              doc.value
                                            );
                                            setBidUnitCancelBidModal(
                                              offer.product.unit
                                            );
                                            setShowCancelBidModal(true);
                                          }}
                                        >
                                          CANCELAR
                                        </Button>
                                      </div>
                                    </TableCell>
                                  )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              )}
              <Accordion
                expanded={expandAcordeonShipment}
                onChange={(v) => {
                  setExpandAcordeonShipment((v) => !v);
                }}
                style={{
                  borderBottom: !expandAcordeonShipment ? "0px" : undefined,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <b>Cronograma de Entrega</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="shipment">
                    <div className="sectionContent">
                      <TableContainer
                        component={Paper}
                        className={
                          (offer.deliverySchedules as any[]).length === 1
                            ? "noBorderTable"
                            : ""
                        }
                      >
                        <Table
                          // className={classes.table}
                          size="small"
                          aria-label="a dense table"
                          stickyHeader
                        >
                          <TableBody>
                            {offer.deliverySchedules.map((d: any) => {
                              return (
                                <TableRow>
                                  <TableCell align="left">
                                    {d.date.split("-")[1]}/
                                    {d.date.split("-")[0]}
                                  </TableCell>
                                  <TableCell align="left">
                                    {formatQuanty(d.quantity)} m³{" "}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {visualizerMode && offer.allowedParticipants.length > 0 && (
                <Accordion
                  expanded={expandAcordeonClients}
                  onChange={(v) => {
                    setExpandAcordeonClients((v) => !v);
                  }}
                  style={{
                    borderBottom: !expandAcordeonClients ? "0px" : undefined,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <b>Clientes selecionados</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="shipment">
                      <div className="sectionContent">
                        <TableContainer
                          component={Paper}
                          className={
                            (offer.allowedParticipants as any[]).length === 1
                              ? "noBorderTable"
                              : ""
                          }
                        >
                          <Table
                            // className={classes.table}
                            size="small"
                            aria-label="a dense table"
                            stickyHeader
                          >
                            <TableBody>
                              {offer.allowedParticipants.map((d, key) => {
                                return (
                                  <TableRow key={key}>
                                    <TableCell align="left">
                                      {formatCNPJ(d.companyCnpj)}
                                    </TableCell>
                                    <TableCell align="left">
                                      {d.companyName}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {offer.documents.length > 0 && (
                <Accordion
                  expanded={expandAcordeonDocuments}
                  onChange={(v) => {
                    setExpandAcordeonDocuments((v) => !v);
                  }}
                  style={{
                    borderBottom: !expandAcordeonDocuments ? "0px" : undefined,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <b>Documentos</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="documents">
                      <div className="sectionContent">
                        <TableContainer
                          component={Paper}
                          className={
                            offer.documents.length === 1 ? "noBorderTable" : ""
                          }
                        >
                          <Table
                            // className={classes.table}
                            size="small"
                            aria-label="a dense table"
                            stickyHeader
                          >
                            <TableBody>
                              {offer.documents.map((doc, key) => {
                                return (
                                  <TableRow>
                                    <TableCell align="left">
                                      {doc.originalFilename}
                                    </TableCell>
                                    <TableCell align="right">
                                      <a
                                        style={{ color: "black" }}
                                        href={doc.downloadURL as string}
                                      >
                                        {" "}
                                        <GetAppIcon
                                          style={{ marginRight: "20px" }}
                                        />
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
            {!visualizerMode && offer.type === "PURCHASE_OFFER" && (
              <div className="paymentContainer">
                <span className="payment">Dados de pagamento:</span>
                <TextField
                  style={{ marginLeft: "20px" }}
                  label="Cód Banco"
                  value={paymentBank}
                  onChange={(e) => {
                    setPaymentBank(e.target.value);
                  }}
                />
                <TextField
                  style={{ marginLeft: "20px" }}
                  label="Agência"
                  value={paymentAgency}
                  onChange={(e) => {
                    setPaymentAgency(e.target.value);
                  }}
                />
                <TextField
                  style={{ marginLeft: "20px" }}
                  label="Conta"
                  value={paymentAccount}
                  onChange={(e) => {
                    setPaymentAccount(e.target.value);
                  }}
                />
                <TextField
                  style={{ marginLeft: "20px" }}
                  label="Chave PIX (opcional)"
                  value={paymentPix}
                  onChange={(e) => {
                    setPaymentPix(e.target.value);
                  }}
                />
              </div>
            )}
            {!visualizerMode && (
              <div className="inputsContainer">
                <span className="bid">Dar lance:</span>
                {!offer.valueToBeDefined && (
                  <FormControl>
                    <InputLabel htmlFor="valueinput">
                      Valor {getCurrencyPrefix(offer.coin)} /{" "}
                      {offer.product.measurementUnit.abbreviation}
                    </InputLabel>
                    <Input
                      // value={""}
                      inputComponent={CurrencyInput}
                      onChange={(e: any) => {
                        setValue(
                          parseFloat(
                            e.target.value
                              .replace(/\./g, "")
                              .replace(",", ".")
                              .replace(getCurrencyPrefix(offer!.coin), "")
                          )
                        );
                        setStringValue(e.target.value);
                      }}
                      id="valueinput"
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-28px",
                        left: "0",
                        width: "300px",
                        fontSize: "10px",
                      }}
                    >
                      Preencher valor com casas decimais
                      {JSON.stringify(offer.valueToBeDefined)}
                    </div>
                  </FormControl>
                )}
                {offer.valueToBeDefined && (
                  <FormControl>
                    <InputLabel htmlFor="awardinput">Prêmio</InputLabel>
                    <Input
                      // value={value.replace(".", ",")}
                      inputComponent={CurrencyInputNegative}
                      onChange={(e: any) => {
                        setAward(
                          e.target.value
                            .replace(/\./g, "")
                            .replace(",", ".")
                            .replace("R$", "")
                        );
                        setAwardStringValue(e.target.value);
                      }}
                      id="awardinput"
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-28px",
                        left: "0",
                        width: "300px",
                        fontSize: "10px",
                      }}
                    >
                      Preencher valor com casas decimais
                    </div>
                  </FormControl>
                )}
                {offer.acceptPartialBid && (
                  <FormControl style={{ marginLeft: "20px" }}>
                    <InputLabel htmlFor="productquantyinput">
                      Quantidade
                    </InputLabel>
                    <Input
                      value={
                        quantity === ""
                          ? undefined
                          : formatQuanty(parseInt(quantity))
                      }
                      inputComponent={QuantyInput}
                      onChange={(e) => {
                        setQuantity(e.target.value.replace(/\./g, ""));
                      }}
                      id="productquantyinput"
                      fullWidth
                    />
                    {/* <div
                    style={{
                      position: "absolute",
                      bottom: "-28px",
                      left: "0",
                      width: "300px",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Valor não pode ser maior que a quantia restante disponível
                  </div> */}
                  </FormControl>
                )}
              </div>
            )}
            {!visualizerMode && (
              <div className="formProblems">{formProblems()}</div>
            )}
            {visualizerMode && (
              <div>
                <br />
                <br />
              </div>
            )}
          </BidModalStyled>
        </DialogContent>
      )}
      {!visualizerMode && (
        <DialogActions style={{ padding: "24px" }}>
          <div style={{ display: "flex" }}>
            <Button fullWidth onClick={onClose} style={{ marginRight: "20px" }}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                setShowConfirmBidModal(true);
              }}
              disabled={!canSubmit()}
            >
              Continuar
            </Button>
          </div>
        </DialogActions>
      )}
      <NotBestBidDialog
        onClose={() => {
          setShowNotBestBidDialog(false);
        }}
        onConfirm={() => setShowNotBestBidDialog(false)}
        open={showNotBestBidDialog}
      />
      {AppStore.selectedParticipant && (
        <ConfirmSetWinnerBid
          bidId={bidIdSelectWinnerBidModal}
          offerId={props.id}
          participantId={AppStore.selectedParticipant!.id}
          stockbrokerId={AppStore.selectedStockBroker!.id}
          onClose={() => {
            setShowSelectWinnerBidModal(false);
          }}
          onAccept={() => {
            getOffer();
            getBids();
          }}
          open={showSelectWinnerBidModal}
          partialBid={offer?.acceptPartialBid || false}
        />
      )}
      {offer !== null && AppStore.selectedParticipant && (
        <CancelBid
          bidId={bidIdCancelBidModal}
          offerId={props.id}
          participantId={AppStore.selectedParticipant!.id}
          stockbrokerId={AppStore.selectedStockBroker!.id}
          onClose={() => {
            setShowCancelBidModal(false);
          }}
          onAccept={() => {
            getOffer();
            getBids();
          }}
          open={showCancelBidModal}
          value={bidValueCancelBidModal}
          unit={bidUnitCancelBidModal}
          coin={offer!.coin}
        />
      )}
      {offer !== null && (
        <ConfirmBidDialog
          open={showConfirmBidModal}
          offerId={offer.id}
          value={value}
          quanty={offer!.quantity}
          unit={offer!.product.measurementUnit.abbreviation}
          unitDescription={offer!.product.measurementUnit.description}
          onClose={() => {
            setShowConfirmBidModal(false);
          }}
          onConfirm={sendBid}
          coin={offer!.coin}
          award={offer!.offerAward}
          valuetobedefined={offer!.valueToBeDefined}
        />
      )}
    </Dialog>
  );
};

export default BidModal;
