import ProductVsParticipantsStyled from "./productsVsParticipantsPanel.styled";
import Header from "./header";
import { useEffect } from "react";
import { createApiService } from "service";
import { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Button, Checkbox } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CheckGroupStyled from "components/inputs/checkGroup.styled";
import { formatCNPJ } from 'util/format';
import { Autocomplete, TextField } from "@mui/material";

const ProductsVsParticipants: React.FC<any> = (...props) => {
  const service = createApiService();
  const [products, setProducts] = useState([]);
  const [participants, setParticipants] = useState([] as Participant[]);
  const [selectedProductsIds, setSelectedProductsIds] = useState([] as string[]);
  const [selectedParticipantId, setSelectedParticipantId] = useState('' as string);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      await getProducts();
      await getParticipants();
    })();
  }, []);

  useEffect(() => {
    if (selectedParticipantId) {
      getParticipantProducts();
    }
  }, [selectedParticipantId]);

  async function getProducts() {
    const { data } = await service.get(
      '/products/baseProducts'
    );
    data.sort((a: any, b: any) => a.name > b.name ? 1 : -1);
    setProducts(data);
  }

  async function getParticipantProducts() {
    const { data } = await service.get(
      `/products/participants-vs-products/${selectedParticipantId}`
    );
    const { allowedProductsBaseIds = [] } = data;
    const productsIds = products.filter((p: any) => allowedProductsBaseIds.includes(p.baseProductId)).map((p: any) => `${p.id}`);
    setSelectedProductsIds(productsIds);
  }

  async function getParticipants() {
    const { data } = await service.get('/participants/all');
    data.sort((a: Participant, b: Participant) => a.nomeFantasia > b.nomeFantasia ? 1 : -1);
    setParticipants(data);
  }

  async function sendForm() {
    try {
      const baseProductIds = products.filter((p: any) => selectedProductsIds.includes(`${p.id}`)).map((p: any) => p.baseProductId);
      await service.post('/products/participants-vs-products', {
        participantUniqueId: selectedParticipantId,
        allowedProductsBaseIds: baseProductIds
      });
      enqueueSnackbar("Os produtos foram associados ao participante.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (error) {
      enqueueSnackbar("Erro ao associar produtos ao participante", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  }

  type Participant = {
    uniqueId: string,
    nomeFantasia: string,
    nome: string,
    documento: string,
    clienteCorretoraStatus: boolean
  };

  const options = [{ code: `0`, name: 'Selecionar Todos' }].concat(products.map((p: any) => {
    return {
      code: `${p.id}`,
      name: p.name,
    };
  }))

  return (
    <ProductVsParticipantsStyled>
      <div className="productVsParticipantsContainer">
        <Header></Header>
        <div style={{ padding: "20px" }}>
          <FormControl fullWidth>

            <Autocomplete
              id="select-unit"
              fullWidth
              disableClearable
              options={participants.map((p: Participant, i: number) => {
                return (
                    {
                      label: `${p.nomeFantasia} - ${formatCNPJ(p.documento)}`,
                      value: p.uniqueId
                    }
                );
              })}
              onChange={(e, newValue) => {
                setSelectedParticipantId(newValue?.value || '');
              }}
              renderInput={(params) => <TextField {...params} label="Participante" variant="standard"/>}
            />
          </FormControl>
          <FormControl>
            <CheckGroupStyled>
              <div className="checkgroupcontainer" style={{marginTop: "15px"}}>
                <div className="label">Associar Produtos:</div>
                {options.map(option => {
                  return (
                    <div className="checkitem" key={option.code}>
                      <div className="checkBox">
                        < Checkbox
                          disabled={selectedParticipantId !== '' ? false : true}
                          checked={selectedProductsIds.some((i) => i === option.code)}
                          color="primary"
                          onChange={(e) => {
                            if (option.code === '0' && e.target.checked === true) {
                              setSelectedProductsIds(['0'].concat(products.map((p: any) => `${p.id}`)));
                            } else if (option.code === '0' && e.target.checked === false) {
                              setSelectedProductsIds([]);
                            } else {
                              if (e.target.checked === true) {
                                setSelectedProductsIds([...selectedProductsIds, option.code])
                              } else {
                                setSelectedProductsIds(selectedProductsIds.filter((i => i !== option.code)));
                              }
                            }
                          }}
                        />
                      </div>
                      <span>{option.name}</span>
                    </div>
                  )
                })
                }
              </div>
            </CheckGroupStyled>
          </FormControl>
        </div>

        <div style={{
          textAlign: 'center',
          padding: '20px'
        }}>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedParticipantId !== '' ? false : true}
            style={{
              width: "10%"
            }}
            onClick={() => {
              sendForm();
            }}
          >
            Salvar
          </Button>
        </div>
      </div>
    </ProductVsParticipantsStyled>
  );
};

export default ProductsVsParticipants;
