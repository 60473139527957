import { Button, Grid } from "@material-ui/core";
import Util from "utils/util";
import HeaderStyled from "./header.styled";

const getHeaderTitle = (offerHistory: any) => {
  const translatedType = Util.translateOfferType(offerHistory?.type);
  const headerTitle = `Oferta de ${translatedType} - ${offerHistory?.id}`;
  return headerTitle;
};

const Header: React.FC<any> = ({ offerHistory }) => {
  return (
    <HeaderStyled>
      <div className="offerHistoryDetailsHeaderContainer">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <span style={{ fontWeight: "bold" }}>
              {getHeaderTitle(offerHistory)}
            </span>
          </Grid>
        </Grid>
      </div>
    </HeaderStyled>
  );
};

export default Header;
