import styled from "styled-components";

const OfferResume: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export const OfferResumeStyled = styled(OfferResume)`
  .resumeContainer {
    display: flex;
    > div {
      padding-right: 80px;
    }
  }
  .description {
  }
  .value {
    font-weight: bold;
  }
  .taxesinfoContainer {
    margin-top: 20px;
  }
  .paymentinfoContainer {
    margin-top: 20px;
  }
`;
