import styled from "styled-components";

const deliverySchedule: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default styled(deliverySchedule)`
  .inputsContainer {
    display: flex;
    align-items: flex-end;
  }
  table {
    width: 70%;
  }
  .remainingDelivery {
    display: inline-block;
    font-weight: bold;
    margin-top: 30px;
  }
`;
