import styled from "styled-components";
import { VariantType } from "theme/variants";

const offersList: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(offersList)`
  .offersListContainer {
    max-width: 100vw;
    margin-top: 20px;
    .MuiTableHead-root {
      tr {
        th {
          font-weight: bold !important;
          padding: 5px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        th:first-child {
          padding: 16px;
        }
      }
    }
    .MuiTableBody-root {
      tr {
        td {
          padding: 5px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        td:first-child {
          padding: 16px;
        }
      }
    }
    .offerButtonContainer {
      .MuiButton-contained {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.main};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
      .MuiButton-contained:hover {
        background: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.hover};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
      .MuiButton-contained:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    .lowBid {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BadBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #e37714;
        }
      }
    }

    .lowBidChanged0 {
      animation: lowBidAnimation0 1s linear;
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BadBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #e37714;
        }
      }
    }

    .lowBidChanged1 {
      animation: lowBidAnimation2 1s linear;
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BadBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #e37714;
        }
      }
    }

    .bestBid {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.GoodBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #5dc73f;
        }
      }
    }

    .bestBidChanged1 {
      animation: bestBidAnimation 1s linear;
      background-color: ${(props) =>
        (props.theme as VariantType).palette.GoodBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #5dc73f;
        }
      }
    }

    .bestBidChanged0 {
      animation: bestBidAnimation2 1s linear;
      background-color: ${(props) =>
        (props.theme as VariantType).palette.GoodBidBackground.main};
      .bidIcon {
        .MuiSvgIcon-root {
          color: #5dc73f;
        }
      }
    }

    .changed0 {
      animation: changedAnimation 1s linear;
      background-color: white;
      .bidIcon {
        .MuiSvgIcon-root {
          color: #5dc73f;
        }
      }
    }

    <<<<<<< Updated upstream .changed1 {
      animation: changedAnimation2 1s linear;
      background-color: white;
      .bidIcon {
        .MuiSvgIcon-root {
          color: #5dc73f;
        }
      }
    }
  }

  @keyframes bestBidAnimation {
    from {
      background-color: yellow;
    }
    to {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.GoodBidBackground.main};
    }
  }

  @keyframes bestBidAnimation2 {
    from {
      background-color: yellow;
    }
    to {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.GoodBidBackground.main};
    }
  }

  @keyframes lowBidAnimation {
    from {
      background-color: yellow;
    }
    to {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BadBidBackground.main};
    }
  }

  @keyframes lowBidAnimation2 {
    from {
      background-color: yellow;
    }
    to {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BadBidBackground.main};
    }
  }

  @keyframes changedAnimation {
    from {
      background-color: yellow;
    }
    to {
      background-color: white;
    }
  }

  @keyframes changedAnimation2 {
    from {
      background-color: yellow;
    }
    to {
      background-color: white;
    }
  }
  ======= .lowBid {
    background-color: ${(props) =>
      (props.theme as VariantType).palette.BadBidBackground.main};
    .bidIcon {
      .MuiSvgIcon-root {
        color: #e37714;
      }
    }
  }

  .bestBid {
    background-color: ${(props) =>
      (props.theme as VariantType).palette.GoodBidBackground.main};
    .bidIcon {
      .MuiSvgIcon-root {
        color: #5dc73f;
      }
    }
  }
  >>>>>>>Stashed changes .MuiTableHead-root {
    th {
      background-color: white !important;
    }
    // display: block !important;
    // overflow-y: scroll;
    // scrollbar-color: transparent transparent ${/* apenas p/ firefox  */ `;`}
    // ::-webkit-scrollbar
    // {
    //   background-color: transparent;
    //     // width: 0px;
    // }
    // ::-webkit-scrollbar-track-piece
    // {
    //     background-color: transparent;
    //     -webkit-border-radius: 6px;
    // }
    // tr {
    //   display: flex;
    //   th {
    //     font-weight: bold !important;
    //   }
    // }
  }
  .MuiTableBody-root {
    // display: block !important;
    // width: 100%;
    // height: 300px !important;
    // overflow-y: scroll;
    // tr {
    //   width: 100%;
    //   display: flex;
    //   td {
    //     padding-top: 15px;
    //     padding-bottom: 15px;
    //   }
    // }
  }
  // td:nth-child(1), th:nth-child(1) {
  //   width: 10%;
  // }
  // td:nth-child(2), th:nth-child(2) {
  //   width: 10%;
  // }
  // td:nth-child(3), th:nth-child(3) {
  //   width: 10%;
  // }
  // td:nth-child(4), th:nth-child(4) {
  //   width: 10%;
  // }
  // td:nth-child(5), th:nth-child(5) {
  //   width: 5%;
  // }
  // td:nth-child(6), th:nth-child(6) {
  //   width: 10%;
  // }
  // td:nth-child(7), th:nth-child(7) {
  //   width: 10%;
  // }
  // td:nth-child(8), th:nth-child(8) {
  //   width: 8%;
  // }
  // td:nth-child(9), th:nth-child(9) {
  //   width: 8%;
  // }
  // td:nth-child(10), th:nth-child(10) {
  //   width: 8%;
  // }
  // td:nth-child(11), th:nth-child(11) {
  //   width: 12%;
  // }
  // td:nth-child(12), th:nth-child(12) {
  //   width: 5%;
  // }
  .blur {
    filter: blur(3px);
  }
`;
