import { createApiService } from "service";

export const createAppService = () => {
  const service = createApiService();

  const getTime = () => {
    return service.get(`/system/time`);
  };

  return { getTime };
};
