import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import { FullOfferDTO } from "models/offer.model";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useEffect, useState } from "react";
import { AppState } from "store/app/reducer";
import { useSelector } from "react-redux";
import { createApiService } from "service";
import styled from "styled-components";
import { formatCurrency, formatProductUnit } from "util/format";
import createNumberMask from "util/createNumberMaskDatagro";
import MaskedInput from "react-text-mask";
import { useSnackbar } from "notistack";

type showAlreadyBidExistsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AlreadyBidExistsDialog: React.FC<showAlreadyBidExistsDialogProps> = ({
  open,
  onClose,
}) => {
  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Erro: Oferta já tem um lance superior ao novo valor
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "right",
          }}
        >
          <Button variant="contained" color="primary" onClick={onClose}>
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type updateOfferDialogProps = {
  offerId: string;
  onClose: () => void;
  open: boolean;
  newValue: string;
  newAward: string;
  valueToBeDefined: boolean;
  offer: FullOfferDTO;
  onConfirm: () => void;
};

const UpdateOfferDialog: React.FC<updateOfferDialogProps> = (
  props: updateOfferDialogProps
) => {
  const {
    onClose,
    open,
    offerId,
    offer,
    newValue,
    newAward,
    valueToBeDefined,
    onConfirm,
  } = props;
  const service = createApiService();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Confirmar atualização da oferta número <b>{props.offerId}</b> para o
        {valueToBeDefined ? " prêmio" : "valor"} de{" "}
        {!valueToBeDefined
          ? formatCurrency(parseFloat(newValue))
          : formatCurrency(parseFloat(newAward))}{" "}
        {!valueToBeDefined ? "/ " : ""}
        {!valueToBeDefined ? formatProductUnit(offer.product.unit) : ""}?
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Sim
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const defaultMaskOptions = {
  prefix: "R$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: true,
};

const CurrencyInput: React.FC<any> = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

const CurrencyInputNegative: React.FC<any> = ({
  maskOptions,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    allowNegative: true,
    prefix: "",
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

type props = {
  show: boolean;
  offer?: FullOfferDTO;
  offerId: string;
  onClose: () => void;
};

const EditOfferDialogS: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

const EditOfferDialogStyled = styled(EditOfferDialogS)`
  .description {
  }
  .value {
    font-weight: bold;
  }
`;

export const EditOfferDialog: React.FC<props> = ({
  show,
  offerId,
  onClose,
}) => {
  const [offer, setOffer] = useState<FullOfferDTO | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();
  const [value, setValue] = useState("");
  const [award, setAward] = useState("");
  const [updatingOffer, setUpdatingOffer] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showAlreadyBidExistsModal, setShowAlreadyBidExistsModal] =
    useState(false);

  function getOffer() {
    service
      .get(
        `/offers/${offerId.replace("/", "-")}?participant=${
          AppStore.selectedParticipant?.id
        }&stockbroker=${AppStore.selectedStockBroker?.id}`
      )
      .then(({ data: offer }) => {
        setOffer(offer);
      });
  }

  useEffect(() => {
    setValue("");
    setAward("");

    (async () => {
      if (offerId !== "0") {
        getOffer();
      }
    })();
  }, [offerId, show]);

  function handleClose() {
    onClose();
  }

  const canSubmit = useCallback(() => {
    if (!offer) {
      return false;
    }
    const doubleValue = parseFloat(value.replace(",", ".").replace("R$", ""));
    const doubleAward = parseFloat(award.replace(",", ".").replace("R$", ""));

    return (
      (doubleValue > 0 &&
        value.indexOf(".") > 0 &&
        value.split(".")[0].length > 0 &&
        value.split(".")[1].replace("_", "").length === 2) ||
      (doubleAward > 0 &&
        award.indexOf(".") > 0 &&
        award.split(".")[0].length > 0 &&
        award.split(".")[1].replace("_", "").length === 2)
    );
  }, [value, award]);

  function updateOffer() {
    setShowConfirmDialog(false);
    setUpdatingOffer(true);

    service
      .put(
        `/offers/${offerId.replace("/", "-")}?stockbroker=${
          AppStore.selectedStockBroker!.id
        }&participant=${AppStore.selectedParticipant!.id}`,
        {
          price: Math.round(
            parseFloat(value.replace(",", ".").replace("R$", "")) * 100
          ),
          award: Math.round(
            parseFloat(award.replace(",", ".").replace("R$", "")) * 100
          ),
        }
      )
      .then(({ data }) => {
        console.log(data);
        onClose();
        let message = "Oferta alterada com sucesso";
        if (data.offerExecuted) {
          message = `Oferta alterada e executada automaticamente para ${
            offer?.acceptPartialBid && false ? " um ou mais lances " : " lance "
          } com novo ${
            offer?.valueToBeDefined ? "prmêmio" : "valor"
          } escolhido`;
        }
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          onClose();
          enqueueSnackbar("Oferta não encontrada", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else if (e.response.data.code === 1005) {
          setShowAlreadyBidExistsModal(true);
        }
      })
      .finally(() => {
        setUpdatingOffer(false);
      });
  }

  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={show}
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "left" }}>
        {offer && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Alterar oferta de{" "}
              {offer.type === "SALE_OFFER" ? "venda" : "compra"}: {offer.id}{" "}
            </span>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        )}
      </DialogTitle>
      {offer && (
        <DialogContent>
          <EditOfferDialogStyled>
            <div style={{ minWidth: "500px" }}>
              <div>
                <span className="description">
                  {offer.valueToBeDefined
                    ? "Prêmio unitário atual"
                    : "Valor unitário atual"}
                  :{" "}
                </span>
                <span className="value">
                  {formatCurrency(
                    (offer.valueToBeDefined
                      ? offer.offerAward
                      : offer.unitPrice) / 100
                  )}{" "}
                  {!offer.valueToBeDefined ? "/ ": ""}
                  {!offer.valueToBeDefined
                    ? formatProductUnit(offer.product.unit)
                    : ""}
                </span>
              </div>
              <div style={{ marginTop: "20px" }}>
                <span className="description">
                  {} {offer.valueToBeDefined ? "Novo prêmio" : "Novo valor"}:{" "}
                </span>
                <br />
                {!offer.valueToBeDefined && (
                  <FormControl>
                    <InputLabel htmlFor="valueinput">
                      Preço (R$) / {formatProductUnit(offer.product.unit)}
                    </InputLabel>
                    <Input
                      key="inputvalue"
                      defaultValue={""}
                      value={value.replace(".", ",")}
                      inputComponent={CurrencyInput}
                      onChange={(e: any) => {
                        setValue(
                          e.target.value
                            .replace(/\./g, "")
                            .replace(",", ".")
                            .replace("R$", "")
                        );
                      }}
                      id="valueinput"
                    />
                  </FormControl>
                )}
                {offer.valueToBeDefined && (
                  <FormControl>
                    <InputLabel htmlFor="valueinput">Prêmio (R$)</InputLabel>
                    <Input
                      key="inputvalue"
                      defaultValue={""}
                      // value={value.replace(".", ",")}
                      inputComponent={CurrencyInputNegative}
                      onChange={(e: any) => {
                        setAward(
                          e.target.value
                            .replace(/\./g, "")
                            .replace(",", ".")
                            .replace("R$", "")
                        );
                      }}
                      id="valueinput"
                    />
                  </FormControl>
                )}{" "}
              </div>
            </div>
          </EditOfferDialogStyled>
        </DialogContent>
      )}
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowConfirmDialog(true);
            }}
            disabled={!canSubmit() || updatingOffer}
          >
            Alterar
          </Button>
        </div>
      </DialogActions>
      {offer && AppStore.selectedParticipant && (
        <UpdateOfferDialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          offerId={offerId}
          newValue={value}
          offer={offer!}
          onConfirm={updateOffer}
          newAward={award}
          valueToBeDefined={offer.valueToBeDefined}
        />
      )}
      <AlreadyBidExistsDialog
        open={showAlreadyBidExistsModal}
        onClose={() => setShowAlreadyBidExistsModal(false)}
      />
    </Dialog>
  );
};
