import { Grid } from "@material-ui/core";
import GenericInfosStyled from "./genericInfos.styled";
import Util from "../../../utils/util";
import { formatCurrency } from "util/format";

const GenericInfos: React.FC<any> = ({ contract }) => {
  return (
    <GenericInfosStyled>
      <div className="genericInfos">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <span>
              Data de Emissão:{" "}
              {Util.formatDate(contract?.createdAt, "dd/MM/yy")}
            </span>
          </Grid>
          <Grid item xs={3}>
            <span>Comprador: {Util.cnpjMask(contract?.buyer || "") || ""}</span>
          </Grid>
          <Grid item xs={3}>
            <span>Vendedor: {Util.cnpjMask(contract?.seller || "") || ""}</span>
          </Grid>
          <Grid item xs={3}>
            <span style={{ fontWeight: "bold" }}>
              Quantidade: {contract?.offer.quantity}{" "}
              {contract?.offer.product.unit}
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <span>Produto: {contract?.offer.product.name}</span>
          </Grid>
          <Grid item xs={3}>
            <span>
              Corretora: {Util.cnpjMask(contract?.buyerStockbroker || "") || ""}
            </span>
          </Grid>
          <Grid item xs={3}>
            <span>
              Corretora:{" "}
              {Util.cnpjMask(contract?.sellerStockbroker || "") || ""}
            </span>
          </Grid>
          <Grid item xs={3}>
            <span style={{ fontWeight: "bold" }}>
              Preço Total:{" "}
              {contract ? formatCurrency(contract?.totalPrice) : ""}{" "}
              {contract?.offer.coin} aa
            </span>
          </Grid>
        </Grid>

        <br />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <span>
              Classificação do Produto: {contract?.offer.classification}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span>
              Frete:{" "}
              {contract?.offer.shippingModes
                .map((sm: any) => {
                  return sm.shippingMode.type;
                })
                .join(", ")}
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <span>
              Unidade Fornecedora:{" "}
              {Util.formatAddress(
                contract?.offer.sellerUnitAddress ||
                  contract?.offer.buyerUnitAddress
              )}
            </span>
          </Grid>
        </Grid>
        {contract?.offer.productSnapshot &&
          contract?.offer.productSnapshot.length && <br />}
        {contract?.offer.productSnapshot?.map((productSnapshot: any) => {
          return (
            <>
              <div>
                <span className="description">{productSnapshot.label}</span>
                <span
                  className="value"
                  style={{ marginLeft: "5px", fontWeight: "bold" }}
                >
                  {productSnapshot.fieldType !== "boolean"
                    ? productSnapshot.value
                    : productSnapshot.value === "true"
                    ? "Sim"
                    : "Não"}
                </span>
              </div>
            </>
          );
        })}
        <br />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <span>
              Condições de Pagamento:{" "}
              <span style={{ fontWeight: "bold" }}>
                {contract?.offer.payment.paymentCondition.description}
              </span>
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <span>
              Detalhes:{" "}
              <span style={{ fontWeight: "bold" }}>
                {contract?.offer.payment.details}
              </span>
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <span>
              Tributos que serão de responsabilidade de recolhimento pelo
              vendedor:{" "}
              <span style={{ fontWeight: "bold" }}>
                {contract?.offer.payment.sellerTributes}
              </span>
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <span>
              Tributos que serão de responsabilidade de recolhimento pelo
              comprador:{" "}
              <span style={{ fontWeight: "bold" }}>
                {contract?.offer.payment.buyerTributes}
              </span>
            </span>
          </Grid>
        </Grid>
      </div>
    </GenericInfosStyled>
  );
};

export default GenericInfos;
