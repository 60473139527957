import styled from "styled-components";

const signaturesList: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(signaturesList)`
  .signaturesList {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    background-color: white;
    padding: 20px;

    .mt-19 {
      margin-top: 19px;
    }

    .MuiTableHead-root {
      tr {
        th {
          font-weight: bold !important;
          font-size: 11px;
        }
      }
    }
    .MuiTableBody-root {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  .MuiTableHead-root {
    th {
      background-color: white !important;
    }
  }
  .MuiTableBody-root {
  }

  .signed-text {
    color: #5DC73F;
  }
`