import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import OffersHistoryListStyled from "./offersHistoryList.styled";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Util from "../../utils/util";

const OffersHistoryList: React.FC<any> = ({ offers }) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedOffer, setSelectedOffer] = useState({} as any);

  function handleShowOptionsClick(e: any, offerNumber: any) {
    setAnchorEl(e.currentTarget);
    setShowMenu(true);
  }

  function handleClose() {
    setShowMenu(false);
  }

  const OFFER_STATUS: any = {
    CREATED: { description: "Aberta", class: "open-offer" },
    FINISHED: { description: "Negociada", class: "negotiated-offer" },
    CLOSED: { description: "Expirada", class: "closed-offer" },
    CANCELED: { description: "Cancelada", class: "canceled-offer" },
    PARTIALLY_NEGOTIATED: {
      description: "Parcialmente negociada",
      class: "partially-negotiated-offer",
    },
  };

  return (
    <OffersHistoryListStyled>
      <div className="offersHistoryListContainer">
        <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
          <Table
            // className={classes.table}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Data</TableCell>
                <TableCell align="left">Produto</TableCell>
                <TableCell align="left">Nº Oferta</TableCell>
                <TableCell align="left">Participante</TableCell>
                <TableCell align="left">Operador</TableCell>
                <TableCell align="left">Corretora</TableCell>
                <TableCell align="left">Operador</TableCell>
                <TableCell align="left">Local - UF</TableCell>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="left">Situação</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer: any) => (
                <TableRow key={offer.id}>
                  <TableCell align="left">
                    {Util.formatDate(offer.createdAt, "dd/MM/yy")}
                  </TableCell>
                  <TableCell align="left">
                    <span style={{ fontWeight: "bold" }}>
                      {offer.product.name}
                    </span>
                  </TableCell>
                  <TableCell align="left">{offer.id}</TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(offer.participantCpfCnpj || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {offer.offerCreatorName || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(offer.stockBrokerCpfCnpj || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {offer.offerCreatorName || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {offer.address.city} - {offer.address.state}
                  </TableCell>
                  <TableCell align="left">
                    <span style={{ fontWeight: "bold" }}>
                      {offer.type === "SALE_OFFER" ? "Venda" : "Compra"}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span className={OFFER_STATUS[offer.offerStatus].class}>
                      {OFFER_STATUS[offer.offerStatus].description}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleShowOptionsClick(e, offer);
                        setSelectedOffer(offer);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={showMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
              boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.25)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setShowMenu(false);
              const formatedId = selectedOffer.id.replace("/", "-");
              history.push(`/historico-ofertas/${formatedId}`);
            }}
          >
            Visualizar
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `${
                  selectedOffer.type === "SALE_OFFER"
                    ? "registrar-oferta-venda"
                    : "registrar-oferta-compra"
                }?from=${selectedOffer.id.replace("/", "-")}`
              );
            }}
          >
            Clonar
          </MenuItem>
          {selectedOffer.contract ? (
            <MenuItem
              // onClick={() => {
              //   setShowMenu(false);
              //   const contract = selectedOffer.contract;
              //   window.open(contract?.signedFileUrl || contract?.originalFileUrl, '_blank'); // eslint-disable-line
              // }}
              onClick={() => {
                history.push(
                  `/contratos?offerId=${selectedOffer.id.replace("/", "-")}`
                );
              }}
            >
              Contratos
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
      </div>
    </OffersHistoryListStyled>
  );
};

export default OffersHistoryList;
