import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import SignaturesListStyled from "./signaturesList.styled";
import Paper from '@material-ui/core/Paper';
import { DateTime } from "luxon";

function createData(
  signer: string,
  participant: string,
  socialName: string,
  situation: string
) {
  return {
    signer,
    participant,
    socialName,
    situation,
  };
}

const rows = [
  createData(
    "Maria José",
    "ABCD",
    "Coca Cola",
    "Pendente",
  ),
  createData(
    "João Paulo",
    "ABCD",
    "Coca Cola",
    "Pendente",
  ),
  createData(
    "Rosana Soares",
    "ABCD",
    "Coca Cola",
    "Assinado (12/08/21 12:45h)",
  )
];

const SignaturesList: React.FC<any> = ({ contract }) => {
  const SIGNED_STATUS = (signer: any) => {
    const STATUS: any = {
      'SIGNED': { ptBr: `Assinado (${DateTime.fromJSDate(new Date(signer.signedAt)).setZone('America/Sao_Paulo').toFormat('dd/MM/yyyy HH:mm')})`, class: 'signed-text' },
      'PENDING': { ptBr: 'Pendente', class: 'unsigned-text' },
    };
    return STATUS[signer.status] || STATUS['PENDING'];
  };

  const buyerSigners = contract?.signers?.filter((signer: any) => signer.signerType === 'BUYER');
  const sellerSigners = contract?.signers?.filter((signer: any) => signer.signerType === 'SELLER');

  return (
    <SignaturesListStyled>
      <div className="signaturesList">
        <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
          <span style={{fontWeight: "bold"}}>Assinantes / Comprador ({contract?.buyerCompanyName})</span>
          <Table
            className="mt-19"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Assinante</TableCell>
                <TableCell align="left">Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyerSigners?.map((signer: any) => {
                const signerStatus = SIGNED_STATUS(signer);
                return (
                  <TableRow>
                    <TableCell align="left">{signer.name}</TableCell>
                    <TableCell align="left">
                      <span className={signerStatus.class}>{signerStatus.ptBr}</span>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} style={{ maxHeight: "600px", marginTop: "25px" }}>
        <span style={{fontWeight: "bold"}}>Assinantes / Vendedor ({contract?.sellerCompanyName})</span>
          <Table
            className="mt-19"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Assinante</TableCell>
                <TableCell align="left">Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sellerSigners?.map((signer: any) => {
                const signerStatus = SIGNED_STATUS(signer);
                return (
                  <TableRow>
                    <TableCell align="left">{signer.name}</TableCell>
                    <TableCell align="left">
                      <span className={signerStatus.class}>{signerStatus.ptBr}</span>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </SignaturesListStyled>
  );
};

export default SignaturesList;