import { FullOfferDTO } from "models/offer.model";
import { DateTime } from "luxon";
import { formatCurrency, formatQuanty } from "util/format";
import { OfferResumeStyled } from "./offerResume.styled";

type props = {
  offer: FullOfferDTO;
};

const OfferResumeComponent: React.FC<props> = ({ offer }) => {
  return (
    <OfferResumeStyled>
      <div className="resumeContainer">
        <div className="column1">
          <span className="description">Data de Emissão: </span>
          <span className="value">
            {DateTime.fromISO(offer.createdAt).toFormat("dd/MM/yyyy")}
          </span>
          <br />
          <span className="description">Produto: </span>
          <span className="value">{offer.product.name}</span>
          <br />
          <span className="description">Volume original ofertado: </span>
          <span className="value">
            {formatQuanty(offer.quantity)}{" "}
            {offer.product.measurementUnit.abbreviation}
          </span>
          <br />
          <span className="description">Volume atual Ofertado (saldo): </span>
          <span className="value">
            {formatQuanty(offer.remainingquantity)}{" "}
            {offer.product.measurementUnit.abbreviation}
          </span>
          <br />

          <span className="description">Preço Unitário: </span>
          <span className="value">
            {offer.valueToBeDefined
              ? "A FIXAR"
              : `${formatCurrency(offer.unitPrice / 100, offer.coin)}`}
          </span>
          <br />
          {!offer.valueToBeDefined && (
            <>
              <span className="description">Preço Total: </span>
              <span className="value">
                {formatCurrency(
                  (offer.unitPrice * offer.quantity) / 100,
                  offer.coin
                )}
              </span>
            </>
          )}
          {offer.valueToBeDefined && (
            <>
              <span className="description">Prêmio: </span>
              <span className="value">
                {formatCurrency(offer.offerAward / 100, offer.coin)}
              </span>
            </>
          )}
          {offer.valueToBeDefined && (
            <>
              <br />
              <span className="description">Fórmula de fixaçao do preço: </span>
              <span className="value">{offer.valueDefinitionFormula}</span>
            </>
          )}
          {offer.valueToBeDefined && (
            <>
              <br />
              <span className="description">Índice: </span>
              <span className="value">{offer.priceindex.name}</span>
            </>
          )}
        </div>
        <div className="column2">
          <span className="description">Classificação do Produto: </span>
          <span className="value">{offer.classification}</span>
          {/* <br />
                  <span className="description">Participante: </span>
                  <span className="value">{offer.participantId}</span> */}
          {offer.stockBrokerName && (
            <div style={{ display: "inline" }}>
              <br />
              <span className="description">Corretora: </span>
              <span className="value">{offer.stockBrokerName}</span>
            </div>
          )}
          {(offer as any).participantName && (
            <div style={{ display: "inline" }}>
              <br />
              <span className="description">Ofertante: </span>
              <span className="value">{(offer as any).participantName}</span>
            </div>
          )}
          {(offer as any).firstShipmentDate && (
            <div style={{ display: "inline" }}>
              <br />
              <span className="description">
                Data prevista para o inicio de entrega:{" "}
              </span>
              <span className="value">
                {DateTime.fromISO((offer as any).firstShipmentDate).toFormat(
                  "dd/MM/yyyy"
                )}
              </span>
            </div>
          )}
          <div style={{ display: "inline" }}>
            <br />
            <span className="description">Limite máximo diário: </span>
            <span className="value">{(offer as any).shipmentDailyLimit}</span>
          </div>
        </div>
        <div className="column3">
          {offer.offerCreatorName !== undefined && (
            <div>
              <span className="description">Operador: </span>
              <span className="value">{offer.offerCreatorName}</span>
              <br />
            </div>
          )}
          <span className="description">Frete: </span>
          <span className="value">
            {offer.shippingModes.map((s) => s.shippingMode.type).join(", ")}
          </span>
          <br />
          {offer[
            offer.type === "SALE_OFFER"
              ? "sellerUnitAddress"
              : "buyerUnitAddress"
          ] && (
            <span className="description">
              Unidade{" "}
              {offer.type === "SALE_OFFER" ? "Fornecedora" : "Compradora"}:{" "}
            </span>
          )}
          {offer[
            offer.type === "SALE_OFFER"
              ? "sellerUnitAddress"
              : "buyerUnitAddress"
          ] && (
            <span className="value">
              {
                offer[
                  offer.type === "SALE_OFFER"
                    ? "sellerUnitAddress"
                    : "buyerUnitAddress"
                ].addressLine
              }{" "}
              {
                offer[
                  offer.type === "SALE_OFFER"
                    ? "sellerUnitAddress"
                    : "buyerUnitAddress"
                ].city
              }
              /
              {
                offer[
                  offer.type === "SALE_OFFER"
                    ? "sellerUnitAddress"
                    : "buyerUnitAddress"
                ].state
              }{" "}
              CEP:
              {
                offer[
                  offer.type === "SALE_OFFER"
                    ? "sellerUnitAddress"
                    : "buyerUnitAddress"
                ].zipCode
              }{" "}
              {/* Rua da Soja 2021 Itumbiara/GO CEP: 000000-000 */}
            </span>
          )}
          {offer[
            offer.type === "SALE_OFFER"
              ? "sellerUnitAddress"
              : "buyerUnitAddress"
          ] && <br />}

          <span className="description">
            Outras informações referentes ao custo de entrega:{" "}
          </span>
          <span className="value">{offer.anotherShipmentCosts}</span>
        </div>
      </div>
      <div className="taxesinfoContainer">
        <span className="description">
          Enquadramento e regime especial de tributação (se houver):{" "}
        </span>
        <span className="value">{offer.taxRegime}</span>
      </div>
      {offer.productSnapshot && offer.productSnapshot.length && <br />}
      {offer.productSnapshot?.map((productSnapshot: any) => {
        return (
          <>
            <div>
              <span className="description">{productSnapshot.label}</span>
              <span className="value" style={{ marginLeft: "5px" }}>
                {productSnapshot.fieldType !== "boolean"
                  ? productSnapshot.value
                  : productSnapshot.value === "true"
                  ? "Sim"
                  : "Não"}
              </span>
            </div>
          </>
        );
      })}

      <div className="paymentinfoContainer">
        <div className="onlySellOfferContainer">
          <span className="description">Condições de Pagamento: </span>
          <span className="value">
            {offer.payment.paymentCondition.description}
          </span>
          <br />
          <span className="description">Detalhes: </span>
          <span className="value" style={{ paddingRight: "20px" }}>
            {offer.payment.details}
          </span>
          <br />
        </div>

        <span className="description">
          Tributos que serão de responsabilidade de recolhimento pelo vendedor:{" "}
        </span>
        <span className="value">{offer.payment.sellerTributes}</span>
        <br />
        <span className="description">
          Tributos que serão de responsabilidade de recolhimento pelo comprador:{" "}
        </span>
        <span className="value">{offer.payment.buyerTributes}</span>
      </div>
    </OfferResumeStyled>
  );
};

export const OfferResume = OfferResumeComponent;
