import useForm from "../../form/index";
import { formItemGroup } from "models/formItem.model";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import RegisterProductStyled from "./registerProduct.styled";
import FormAccordion from "components/formAccordion";
import FormGroupRender from "components/formAccordion/formGroupRender";
import { useReducer, useState } from "react";
import { Input } from "@material-ui/core";
import Metadata from "./metadata";
import { useHistory, useParams } from "react-router-dom";
import { createApiService } from "service";
import { useEffect } from "react";

const RegisterProduct: React.FC = () => {
  const form = useForm();
  const [{ values }] = form as any;
  const [metadata, setMetadata] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showFinishedDialog, setShowFinishedDialog] = useState(false);
  const [units, setUnits] = useState([] as any);

  const [product, setProduct] = useState({} as any);

  const params = useParams() as any;

  const service = createApiService();

  useEffect(() => {
    service.get("measurement-units").then((res: any) => {
      setUnits(res.data);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      service.get(`products/find-one/${params.id}`).then(({ data }: any) => {
        values.current.productName = data.name;
        values.current.templateId = data.contractTemplateId;
        values.current.productUnit = data.measurementUnitId;
        values.current.templateIdToBeFixed = data.contractTemplateIdIndexValue;
        setProduct(data);
        setMetadata(data.metadataDefinition);
      });
    }
  }, [units]);

  const history = useHistory();

  const sendForm = async (payload: any) => {
    try {
      setIsSubmiting(true);
      if (!params.id) {
        await service.post("products", payload);
      } else {
        await service.put(`products/${product.baseProductId}`, payload);
      }
      setShowFinishedDialog(true);
    } catch (e) {
      window.alert("Erro ao gravar produto");
    } finally {
      setIsSubmiting(false);
    }
  };

  type finishedDialogProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
  };

  function FinishedDialog(props: finishedDialogProps) {
    const { onClose, onConfirm, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
          Produto registrado com sucesso
        </DialogTitle>
        <DialogActions style={{ padding: "24px" }}>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onConfirm}
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  const form1: formItemGroup[] = [
    {
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="productName">Nome do Produto</InputLabel>
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id="productName"
                  fullWidth
                />
              </FormControl>
            ),
            value: params.id ? " " : "",
            collums: 3,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "" && v !== null && v !== undefined;
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "productName",
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel id="select-unit">Unidade de Medida</InputLabel>
                <Select
                  labelId="select-unit"
                  fullWidth
                  value={values.current.productUnit}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                >
                  {units.map((p: any, i: number) => {
                    return (
                      <MenuItem value={p.id} key={i} selected={value === null}>
                        {p.description} ({p.abbreviation})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
            value: params.id ? " " : "",
            collums: 2,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "" && v !== null && v !== undefined;
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "productUnit",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="templateId">
                  Identificador do Contrato ZapSign (Preço fixo)
                </InputLabel>
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id="templateId"
                  fullWidth
                />
              </FormControl>
            ),
            value: "",
            collums: 5,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "templateId",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="templateIdToBeFixed">
                  Identificador do Contrato ZapSign (Preço a fixar)
                </InputLabel>
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id="templateIdToBeFixed"
                  fullWidth
                />
              </FormControl>
            ),
            value: "",
            collums: 5,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "templateIdToBeFixed",
          },
        ],
      ],
    },
  ];

  const form2: formItemGroup[] = [
    {
      items: [
        [
          {
            onChange: (v) => {},
            render: () => {
              return <Metadata metadata={metadata} setMetadata={setMetadata} />;
            },
            valid: false,
            value: [] as any[],
            collums: 12,
            collumsxs: 12,
            validateFunctions: [],
            formid: "metadata",
          },
        ],
      ],
      groupTitle: undefined,
    },
  ];

  const [currentForm, setCurrentForm] = useState(1);
  const [isCompleteForm1, setIsCompleteForm1] = useState(false);
  const [isCompleteForm2, setIsCompleteForm2] = useState(false);

  return (
    <RegisterProductStyled>
      <form>
        <FormAccordion
          isComplete={isCompleteForm1}
          expand={currentForm === 1}
          title="Registro de Produto"
          onExpand={() => {
            setCurrentForm(1);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            metadata={true}
            groups={form1}
            form={form}
            onValidateSuccess={() => setIsCompleteForm1(true)}
            onValidateError={() => {
              setIsCompleteForm1(false);
            }}
            onFinish={() => {
              setCurrentForm(2);
            }}
            showNextButton={true}
          />
        </FormAccordion>
      </form>
      <form>
        <FormAccordion
          isComplete={isCompleteForm2}
          expand={currentForm === 2}
          title="Atributos"
          onExpand={() => {
            setCurrentForm(2);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            groups={form2}
            metadata={true}
            form={form}
            onValidateSuccess={() => setIsCompleteForm2(true)}
            onValidateError={() => {
              setIsCompleteForm2(false);
            }}
            onFinish={() => {
              setCurrentForm(2);
            }}
            showNextButton={true}
          />
        </FormAccordion>
      </form>
      <div className="rowOptions">
        <div className="alignedContent">
          <Button
            onClick={() => {
              history.push("/produtos");
            }}
            style={{ marginRight: "20px" }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const payload = {
                name: values.current.productName,
                contractTemplateId: values.current.templateId,
                metadataDefinition: metadata,
                measurementUnitId: values.current.productUnit,
                contractTemplateIdIndexValue:
                  values.current.templateIdToBeFixed,
              };
              sendForm(payload);
            }}
            disabled={!isCompleteForm1 || !isCompleteForm2 || isSubmiting}
          >
            Concluir
          </Button>
        </div>
      </div>
      <FinishedDialog
        open={showFinishedDialog}
        onConfirm={() => {
          setShowFinishedDialog(false);
          history.push("/produtos");
        }}
        onClose={() => {
          setShowFinishedDialog(false);
          history.push("/produtos");
        }}
      />
    </RegisterProductStyled>
  );
};

export default RegisterProduct;
