import { AppState } from "./reducer";

const pages: { [key: string]: AppState["currentPage"] } = {
  "/registrar-oferta-venda": {
    title: "Registrar oferta de venda",
    highlightMenu: 1,
  },
  "/registrar-oferta-compra": {
    title: "Registrar oferta de compra",
    highlightMenu: 1,
  },
  "/ofertas": {
    title: "Painel de ofertas",
    highlightMenu: 1,
  },
  "/contratos": {
    title: "Contratos",
    highlightMenu: 3,
  },
  "/historico-ofertas": {
    title: "Histórico de ofertas",
    highlightMenu: 2,
  },
  "/relatorios": {
    title: "Relatórios",
    highlightMenu: 4,
  },
  "/produtos": {
    title: "Produtos",
    highlightMenu: 5,
  },
  "/associar-produtos": {
    title: "Associar Produtos",
    highlightMenu: 6,
  },
};

export enum actionTypes {
  SET_CLOCK_BASE_TIME = "[APP] Set Clock Base Time", // horário de Brasília
  UPDATE_CURRENT_TIME = "[APP] Update Machine Current Time",
  SET_CURRENT_PAGE = "[APP] Set Current Page",
  SET_CONTEXT_STOCKBROKER_PARTICIPANT = "[APP] Set Context Stockbroker",
}

export interface AppActions {
  setClockBaseTime(time?: number): void;
  setCurrentPage(page: string): void;
  setContextStockBrokerParticipant(
    stockbrokerId: string,
    stockbrokerName: string,
    participantId: string,
    participantName: string,
    participantIsAllowedAtBroker: boolean
  ): void;
}

export const appActions = (): AppActions => {
  const actions = {
    setClockBaseTime(time: number, localTime: number) {
      return {
        type: actionTypes.SET_CLOCK_BASE_TIME,
        payload: {
          time,
          localTime,
        },
      };
    },
    setCurrentTime(localTime: number) {
      return {
        type: actionTypes.UPDATE_CURRENT_TIME,
        payload: {
          localTime,
        },
      };
    },
    setCurrentPage(page: AppState["currentPage"]) {
      return {
        type: actionTypes.SET_CURRENT_PAGE,
        payload: page,
      };
    },
    setContextStockBrokerParticipant(
      stockbrokerId: string,
      stockbrokerName: string,
      participantId: string,
      participantName: string,
      participantIsAllowedAtBroker: boolean
    ) {
      return {
        type: actionTypes.SET_CONTEXT_STOCKBROKER_PARTICIPANT,
        payload: {
          stockbrokerId,
          stockbrokerName,
          participantId,
          participantName,
          participantIsAllowedAtBroker,
        },
      };
    },
  };

  function setClockBaseTime(time?: number) {
    return (dispatch: any) => {
      const date = time ? time : new Date().getTime(); // toDo: buscar horário de brasília no servidor ou calcular timezone
      dispatch(actions.setClockBaseTime(time as number, new Date().getTime()));
      window.setInterval(() => {
        dispatch(actions.setCurrentTime(new Date().getTime()));
      }, 1000);
    };
  }

  function setCurrentPage(page: string) {
    return (dispatch: any) => {
      if (pages[page]) {
        document.title = `Datagro - ${pages[page].title}`;
        dispatch(actions.setCurrentPage(pages[page]));
      }
    };
  }

  function setContextStockBrokerParticipant(
    stockBrokerId: string,
    stockBrokerName: string,
    participantId: string,
    participantName: string,
    participantIsAllowedAtBroker: boolean
  ) {
    return (dispatch: any) => {
      dispatch(
        actions.setContextStockBrokerParticipant(
          stockBrokerId,
          stockBrokerName,
          participantId,
          participantName,
          participantIsAllowedAtBroker
        )
      );
    };
  }

  return { setClockBaseTime, setCurrentPage, setContextStockBrokerParticipant };
};
