import styled from "styled-components";
import { VariantType } from "theme/variants";

const header: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(header)`
  .offersHistoryHeaderContainer {
    padding: 1px 16px 10px;
    .filters {
      .MuiButton-root {
        border: 2px solid
          ${(props) => (props.theme as VariantType).palette.BBMprimary2.main};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimary2.main};
        font-weight: bold;
      }
    }

    .teste {
      transform: translate(0, 24px) scale(1);
    }
  }
`;