import { Button, Grid } from "@material-ui/core";
import { formItemGroup } from "models/formItem.model";
import { Context } from "./formItemContext";
import { useContext, useState } from "react";
import FormItemContext from "./formItemContext";
import { useEffect } from "react";

type props = {
  groups: formItemGroup[];
  showNextButton?: boolean;
  onFinish?: () => void;
  onValidateSuccess?: () => void;
  onValidateError?: () => void;
  form: any;
  isACloneOffer?: boolean;
  productType?: any;
  addressWasAutoSet?: boolean;
  metadata?: any;
};

const FormGroupRender: React.FC<props> = ({
  groups,
  form,
  onValidateError,
  onValidateSuccess,
  onFinish,
  showNextButton,
  isACloneOffer,
  productType,
  addressWasAutoSet,
  metadata,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value: v, setValue } = useContext<any>(Context);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ values }, handleChange] = form;

  const [validationMap, setValidationMap] = useState<any>(loadValidationMap());

  function loadValidationMap() {
    return groups
      .map((fg) => {
        return fg.items.map((fg_l) => {
          return fg_l.map((i) => {
            const validationErrors = (i.validateFunctions || [])
              .map((f) => {
                return {
                  valid: f.function(values.current[i.formid] || i.value),
                  error: f.message,
                };
              })
              .filter((v) => v.valid === false);

            return {
              formid: i.formid,
              valid: validationErrors.length === 0,
              errors: validationErrors.map((e) => e.error),
              human: i.autoCompletedValue || isACloneOffer || false,
            };
          });
        });
      })
      .flat()
      .flat()
      .reduce((acumulator, current) => {
        acumulator[current.formid] = {
          valid: current.valid,
          errors: current.errors,
          human: current.human,
        };
        return acumulator;
      }, {} as any);
  }

  useEffect(() => {
    setValidationMap(loadValidationMap());
  }, [isACloneOffer, productType, addressWasAutoSet, metadata]);

  // loadValidationMap();

  useEffect(() => {
    if (
      Object.keys(validationMap).some((k) => {
        return !validationMap[k].valid;
      })
    ) {
      if (onValidateError) {
        onValidateError();
      }
    } else {
      if (onValidateSuccess) {
        onValidateSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMap]);

  const validationArray = groups
    .map((fg) => {
      return fg.items.map((fg_l) => {
        return fg_l.map((i) => {
          return {
            formid: i.formid,
            valid: i.validateFunction ? i.validateFunction(i.value) : true,
          };
        });
      });
    })
    .flat()
    .flat()
    .reduce((acumulator, current) => {
      acumulator[current.formid] = current.valid;
      return acumulator;
    }, {} as any);

  return (
    <div style={{ width: "100%" }}>
      {groups.map((g, g_index) => {
        return (
          <div style={{ width: "100%" }}>
            {g.groupTitle && (
              <span
                className="groupTitle"
                style={{
                  backgroundColor: "#eaeaea",
                  padding: "15px",
                  width: "100%",
                }}
              >
                {g.groupTitle}
              </span>
            )}
            <div
              className="itemsPadded"
              style={{ padding: g.removePadding ? "unset" : "0 20px" }}
            >
              {g.items.map((l, l_index) => {
                return (
                  <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                    {l.map(
                      (
                        {
                          value,
                          onChange,
                          render,
                          collums,
                          collumsxs,
                          collumssm,
                          collumsmd,
                          validateFunction,
                          validateFunctions,
                          formid,
                        },
                        i_index
                      ) => {
                        handleChange({
                          name: formid,
                          value:
                            values.current[formid] === undefined
                              ? value
                              : values.current[formid],
                        });

                        return (
                          <Grid
                            item
                            xs={(collumsxs as any) || collums}
                            sm={(collumssm as any) || collums}
                            md={(collumsmd as any) || collums}
                            lg={(collumsmd as any) || collums}
                            xl={(collumsmd as any) || collums}
                          >
                            <FormItemContext>
                              {validationMap[formid] &&
                                render({
                                  value:
                                    values.current[formid] !== undefined
                                      ? values.current[formid]
                                      : value,
                                  valid:
                                    validationMap[formid].valid ||
                                    !validationMap[formid].human,
                                  onChange: (val) => {
                                    onChange(val);

                                    handleChange({
                                      name: formid,
                                      value: val,
                                    });

                                    const validationErrors = (
                                      validateFunctions || []
                                    )
                                      .map((f) => {
                                        return {
                                          valid: f.function(val),
                                          error: f.message,
                                        };
                                      })
                                      .filter((v) => v.valid === false);

                                    setValidationMap({
                                      ...validationMap,
                                      [formid]: {
                                        valid: validationErrors.length === 0,
                                        errors: validationErrors.map(
                                          (e) => e.error
                                        ),
                                        human: true,
                                      },
                                    });
                                  },
                                })}
                            </FormItemContext>
                            {validationMap[formid] &&
                              validationMap[formid].human &&
                              validationMap[formid].errors.map((e: string) => {
                                return (
                                  <>
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {" "}
                                      {e}{" "}
                                    </span>
                                    <br />
                                  </>
                                );
                              })}
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                );
              })}
            </div>
          </div>
        );
      })}
      <Grid container justifyContent="flex-end">
        {showNextButton && (
          <Button
            style={{ borderWidth: "2px", margin: "20px" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              if (onFinish) {
                onFinish();
              }
            }}
          >
            Próximo
          </Button>
        )}
      </Grid>
    </div>
  );
};

export default FormGroupRender;
