import { UserKC } from "models/user.mode";

export enum actionTypes {
  SET_USER_ACCESS_TOKEN = "[AUTH] Set User Access Token",
  SET_USER_INFO = "[AUTH] Set User Info",
  CLEAR_USER_INFO = "[AUTH] Clear User Info",
}

export type AuthState = {
  accessToken: string | null;
  user: UserKC;
};

const initialState: AuthState = {
  accessToken: null,
  user: {
    name: "",
    photo: null,
    isAdmin: null
  },
};

type actionType = {
  type: actionTypes;
  payload: any;
};

const reducer = (state: AuthState = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.SET_USER_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case actionTypes.SET_USER_INFO:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default reducer;
