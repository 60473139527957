import styled from "styled-components";

const contractDetails: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(contractDetails)`
  .productVsParticipantsContainer {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    background-color: white;
  }
`;
