import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import CheckGroupStyled from "./checkGroup.styled";

type props = {
  label: string;
  value: string[];
  options: {
    code: string;
    name: string;
  }[];
  onChange?: (v: string[]) => void;
  valid: boolean;
  style?: React.CSSProperties;
};

const CheckGroup: React.FC<props> = ({
  label,
  value,
  options,
  valid,
  onChange,
  style,
}) => {
  const [selectedItems, setSelectedItems] = useState([...value]);

  function handleChange(checked: boolean, code: string) {
    let selected = selectedItems;

    if (!checked) {
      selected = selectedItems.filter((i) => i !== code);
    } else {
      selected = [...selectedItems, code];
    }
    setSelectedItems(selected);
    if (onChange) {
      onChange(selected);
    }
  }

  return (
    <CheckGroupStyled>
      <div
        className={"checkgroupcontainer" + (valid ? "" : " checkgrouperror")}
        style={{ ...(style || {}) }}
      >
        <div className="label">{label}</div>
        <div className="checklist">
          {options.map((option) => {
            return (
              <div className="checkitem" key={option.code}>
                <div className="checkBox">
                  <Checkbox
                    checked={value.some((i) => i === option.code)}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(e) => {
                      handleChange(e.target.checked, option.code);
                    }}
                  />
                </div>
                <div className="checklabel">
                  <span>{option.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CheckGroupStyled>
  );
};

export default CheckGroup;
