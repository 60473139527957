import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ProductsListStyled from "./productsList.styled";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Util from "../../utils/util";

const ProductsList: React.FC<any> = ({ products }) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState({} as any);

  function handleShowOptionsClick(e: any, productNumber: any) {
    setAnchorEl(e.currentTarget);
    setShowMenu(true);
  }

  function handleClose() {
    setShowMenu(false);
  }

  return (
    <ProductsListStyled>
      <div className="productsListContainer">
        <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
          <Table
            // className={classes.table}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Data e Hora(Última Versão)</TableCell>
                <TableCell align="left">Nome</TableCell>
                <TableCell align="left">Unidade de Medida</TableCell>
                <TableCell align="left">Situação</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product: any) => (
                <TableRow key={product.id}>
                  <TableCell align="left">
                    {Util.formatDate(product.createdAt, "dd/MM/yy HH:mm")}
                  </TableCell>
                  <TableCell align="left">
                    <span style={{ fontWeight: "bold" }}>{product.name}</span>
                  </TableCell>
                  <TableCell align="left">
                    {product.measurementUnit
                      ? `${product.measurementUnit.description}(${product.measurementUnit?.abbreviation})`
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {product?.isActive === true ? "Ativo" : "Inativo"}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleShowOptionsClick(e, product);
                        setSelectedProduct(product);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={showMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
              boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.25)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              // setShowMenu(false);
              // const formatedId = selectedProduct.id.replace("/", "-");
              history.push(`/produto/${selectedProduct.baseProductId}`);
            }}
          >
            Visualizar
          </MenuItem>
          <MenuItem
            onClick={() => {
              // setShowMenu(false);
              // const formatedId = selectedProduct.id.replace("/", "-");
              history.push(`/editar-produto/${selectedProduct.id}`);
            }}
          >
            Editar
          </MenuItem>
          {selectedProduct.contractTemplateId && (
            <MenuItem
              onClick={() => {
                // setShowMenu(false);
                // history.push(`/simulador-contrato/${selectedProduct.id}`);
              }}
            >
              <a
                href={`/simulador-contrato/${selectedProduct.id}`}
                style={{ color: "unset", textDecoration: "none" }}
              >
                Pré-Visualizar Contrato
              </a>
            </MenuItem>
          )}
        </Menu>
      </div>
    </ProductsListStyled>
  );
};

export default ProductsList;
