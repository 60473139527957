import ContextPickerStyled from "./index.styled";
import EditIcon from "@material-ui/icons/Edit";
import { AppState } from "store/app/reducer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { createApiService } from "service";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "store/app/actions";
import { useAction } from "store";
import ReactLoading from "react-loading";
import { formatCNPJ } from "util/format";
import { authActions } from "store/auth/actions";

type member = {
  id: string;
  cpfCnpj: string;
  companyName: string | null;
  city: string;
  uf: string;
  isEnabledToStockbroker: boolean;
};

type broker = {
  id: string;
  cpfCnpj: string;
  companyName: string | null;
};

type changeContextModalProps = {
  show: boolean;
  onClose: () => void;
  configRequired: boolean;
};

const ChangeContextModal: React.FC<changeContextModalProps> = ({
  onClose,
  show,
  configRequired,
}) => {
  const handleClose = () => {
    if (configRequired) {
      return;
    }
    onClose();
  };

  const [members, setMembers] = useState<member[]>([]);
  const [brokers, setBrokers] = useState<broker[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [membersLoading, setMembersLoading] = useState<boolean>(false);
  const [brokersLoading, setBrokersLoading] = useState<boolean>(false);

  const [selectedStockBroker, setSelectedStockBroker] = useState<null | string>(
    "0"
  );
  const [selectedMember, setSelectedMember] = useState<null | string>("0");

  const accessToken: string = useSelector(
    (state: any) => state.auth.accessToken
  );

  const service = createApiService();

  const dispatch = useDispatch();
  const appAction = useAction(appActions());

  useEffect(() => {
    if (!accessToken || loaded) {
      return;
    }

    setLoaded(true);

    setBrokersLoading(true);
    service
      .get("/stockbrokers")
      .then(({ data: brokers }) => {
        setBrokers(brokers);
        if (brokers.length > 0) {
          if (brokers[0].id === "*") {
            dispatch(
              appAction.setContextStockBrokerParticipant(
                "*",
                "Todos",
                "*",
                "Todos",
                false
              )
            );
            if (!configRequired) {
              onClose();
            }
          } else {
            setSelectedStockBroker(brokers[0].id);
          }
        }
      })
      .finally(() => {
        setBrokersLoading(false);
      });
  }, [accessToken]);

  useEffect(() => {
    setMembersLoading(true);
    if (selectedStockBroker !== "0") {
      service
        .get(`/participants?stockbroker=${selectedStockBroker}`)
        .then(({ data: members }) => {
          setMembers(members);
          if (members.length > 0) {
            setSelectedMember(members[0].id);
          }
        })
        .finally(() => {
          setMembersLoading(false);
        });
      }
  }, [selectedStockBroker]);

  function setContext() {
    const selectedMemberFull = members.filter(
      (m) => m.id === selectedMember
    )[0];
    const selectedStockBrokerFull = brokers.filter(
      (b) => b.id === selectedStockBroker
    )[0];
    dispatch(
      appAction.setContextStockBrokerParticipant(
        selectedStockBrokerFull.id,
        selectedStockBrokerFull.companyName as string,
        selectedMember === "0" ? "none" : selectedMemberFull.id,
        selectedMember === "0"
          ? "Não selecionado"
          : (selectedMemberFull.companyName as string),
        selectedMember === "0"
          ? false
          : selectedMemberFull.isEnabledToStockbroker
      )
    );
    if (!configRequired) {
      onClose();
    }
  }

  const authAction = useAction(authActions());
  function logout() {
    dispatch(authAction.logout());
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={show}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "left" }}>
        Escolher corretora e participante
      </DialogTitle>
      <DialogContent>
        <ContextPickerStyled>
          <div style={{ width: "430px" }}>
            {!brokersLoading && brokers.length === 0 && (
              <span style={{ color: "red" }}>
                Nenhuma corretora ou participante associado ao usuário!
              </span>
            )}
            {!brokersLoading && brokers.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="select-broker">Corretora</InputLabel>
                <Select
                  labelId="select-broker"
                  value={selectedStockBroker}
                  onChange={(e) => {
                    setSelectedStockBroker(e.target.value as any);
                  }}
                >
                  {brokers.map((b, i) => {
                    return (
                      <MenuItem value={b.id} key={i}>
                        {b.companyName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {!membersLoading && members.length > 0 && (
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="select-member">Participante</InputLabel>
                <Select
                  labelId="select-member"
                  value={selectedMember}
                  onChange={(e) => {
                    setSelectedMember(e.target.value as any);
                  }}
                >
                  {members.map((m, i) => {
                    return (
                      <MenuItem value={m.id} key={i}>
                        {m.companyName} - {formatCNPJ(m.cpfCnpj)} - {m.city} -{" "}
                        {m.uf}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="loading-container">
            {(membersLoading || brokersLoading) && (
              <ReactLoading type="bubbles" color="#CCC" />
            )}
          </div>
        </ContextPickerStyled>
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {!configRequired && <Button onClick={onClose}>Cancelar</Button>}
          {configRequired && <div></div>}
          {!brokersLoading && brokers.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                selectedStockBroker === "0" ||
                (selectedMember === "0" &&
                  !membersLoading &&
                  !brokersLoading &&
                  members.length > 0)
              }
              onClick={setContext}
            >
              Aplicar
            </Button>
          )}
          {!brokersLoading && brokers.length === 0 && (
            <Button variant="contained" color="primary" onClick={logout}>
              Sair
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

const ContextPicker: React.FC = () => {
  const AppStore: AppState = useSelector((state: any) => state.app);

  const [configContextRequired, setConfigContextRequired] = useState(false);
  const [changeContextRequested, setChangeContextRequested] = useState(false);

  useEffect(() => {
    if (
      AppStore.selectedParticipant === null ||
      AppStore.selectedStockBroker === null
    ) {
      setConfigContextRequired(true);
    }
    if (
      AppStore.selectedParticipant !== null &&
      AppStore.selectedStockBroker !== null
    ) {
      setConfigContextRequired(false);
    }
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  return (
    <ContextPickerStyled>
      <div className="contextcontainer">
        <span style={{ marginRight: "5px" }}>
          Corretora:{" "}
          <b>
            {AppStore.selectedStockBroker
              ? AppStore.selectedStockBroker.name
              : "Não selecionado"}
          </b>
        </span>
        |
        <span style={{ marginLeft: "5px" }}>
          Participante:{" "}
          <b>
            {AppStore.selectedParticipant
              ? AppStore.selectedParticipant.name
              : "Não selecionado"}
          </b>
        </span>
        <div
          className={
            "editcontainer" +
            (AppStore.selectedStockBroker &&
            AppStore.selectedStockBroker.id === "*"
              ? " editicondisabled"
              : "")
          }
        >
          <EditIcon
            onClick={() => {
              if (
                AppStore.selectedStockBroker &&
                AppStore.selectedStockBroker.id !== "*"
              ) {
                setChangeContextRequested(true);
              }
            }}
          />
        </div>
        <ChangeContextModal
          show={changeContextRequested || configContextRequired}
          onClose={() => {
            setChangeContextRequested(false);
          }}
          configRequired={configContextRequired}
        />
      </div>
    </ContextPickerStyled>
  );
};

export default ContextPicker;
