import styled from "styled-components";

const documentsSelector: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default styled(documentsSelector)`
  .buttonRow {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
  }
  .fileformats {
    font-size: 12px;
  }
`;
