import styled from "styled-components";
import { VariantType } from "theme/variants";

const RegisterSellOffer: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(RegisterSellOffer)`
  table {
    background-color: ${(props) =>
      (props.theme as VariantType).palette.BBMtableBG1.main};
    td {
      padding: 9px 16px;
    }
  }
  .rowOptions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 70px;
    .MuiButton-label {
      font-weight: bold !important;
      font-size: 13px;
    }
  }
`;
