import styled from "styled-components";

const FormAccordion: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(FormAccordion)`
  .MuiAccordion-root {
    padding: 20px;
    margin-bottom: 16px !important;
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  }
  .MuiAccordionSummary-content {
    span:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
      margin-right: 10px;
    }
    .iconOrange {
      color: orange;
    }
    .iconGreen {
      color: green;
    }
  }
  .groupTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
    display: inline-block;
  }
`;
