import { DateTime } from "luxon";
class Util {
  static translateOfferType(offerType: string) {
    const OFFER_TYPES: any = {
      'SALE_OFFER': 'Venda',
      'PURCHASE_OFFER': 'Compra'
    };
    return OFFER_TYPES[offerType];
  }

  static translateSocialSeal(socialSeal: boolean) {
    return socialSeal ? 'Sim' : 'Não';
  }

  static formatDate(date: string, format: string) {
    return DateTime.fromISO(date).setZone('America/Sao_Paulo').toFormat(format);
  }

  static formatToISODateFromFormat(date: string, format: string) {
    return DateTime.fromFormat(date, format).setZone('America/Sao_Paulo').toISO();
  }

  static cnpjMask (value = '') {
    let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/) || '';
    if (x) x = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    return x;
  }

  static formatAddress (address: any) {
    if (address) {
      const formatedAddress = `${address?.addressLine} ${address?.number} ${address?.complement}, ${address?.city}/${address?.state} CEP: ${address?.zipCode}`;
      return formatedAddress;
    }
    return '';
  }
}

export default Util;