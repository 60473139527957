import { Grid, GridSize } from "@material-ui/core";
import { formatCurrency, formatProductUnit } from "util/format";
import Util from "utils/util";
import GenericInfosStyled from "./genericInfos.styled";
import { DateTime } from "luxon";
import { OfferResume } from "components/bidmodal/offerResume";

const CustomGrid = (label: string, value: string | any, rowSize: GridSize) => {
  return (
    <Grid item xs={rowSize}>
      <span>{label}: </span>
      <span style={{ fontWeight: "bold" }}>{value}</span>
    </Grid>
  );
};

const GenericInfos: React.FC<any> = ({ offerHistory }) => {
  return (
    <GenericInfosStyled>
      <div className="genericInfos">
        {offerHistory && <OfferResume offer={offerHistory} />}
        {/* <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Data de Emissão",
            Util.formatDate(offerHistory?.createdAt, "dd/MM/yy"),
            4
          )}
          {CustomGrid(
            "Classificação do Produto",
            offerHistory?.classification,
            4
          )}
          {CustomGrid("Operador", offerHistory?.offerCreatorName, 4)}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid("Produto", offerHistory?.product.name, 4)}
          {CustomGrid(
            "Ofertante",
            `${offerHistory?.participantName} (${Util.cnpjMask(
              offerHistory?.participantCpfCnpj
            )})`,
            4
          )}
          {CustomGrid(
            "Frete",
            offerHistory?.shippingModes
              .map((sm: any) => {
                return sm.shippingMode.type;
              })
              .join(","),
            4
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Quantidade",
            <span>
              {offerHistory?.quantity || ""}
              {formatProductUnit(offerHistory?.product.unit)}
            </span>,
            4
          )}
          {CustomGrid(
            "Corretora",
            `${offerHistory?.stockBrokerName} (${Util.cnpjMask(
              offerHistory?.stockBrokerCpfCnpj || ""
            )})`,
            4
          )}
          {CustomGrid(
            "Unidade Fornecedora",
            Util.formatAddress(
              offerHistory?.sellerUnitAddress || offerHistory?.buyerUnitAddress
            ),
            4
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Preço unitário",
            `${formatCurrency(offerHistory?.unitPrice / 100)}`,
            4
          )}
          {CustomGrid(
            "Limite máximo diário (cadência diária)",
            `${offerHistory?.shipmentDailyLimit}`,
            4
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Preço Total",
            `${formatCurrency(
              (offerHistory?.unitPrice * offerHistory?.quantity) / 100
            )}`,
            4
          )}
          {CustomGrid(
            "Data prevista para o inicio de entrega",
            `${DateTime.fromISO(offerHistory?.firstShipmentDate).toFormat(
              "dd/MM/yyyy"
            )}`,
            4
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <span></span>
          </Grid>
          {CustomGrid(
            "Outras informações referentes ao custo de entrega",
            offerHistory?.anotherShipmentCosts,
            8
          )}
        </Grid>
        <br />
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Enquadramento e regime especial de tributação(se houver)",
            offerHistory?.taxRegime,
            12
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "É detentor do selo social?",
            Util.translateSocialSeal(offerHistory?.hasSocialSeal),
            12
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {offerHistory?.otherSpecifications &&
            CustomGrid(
              "Outras informações pertinentes ao produto",
              offerHistory?.otherSpecifications,
              12
            )}
        </Grid>
        <br />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            {CustomGrid(
              "Condições de Pagamento",
              offerHistory?.payment?.paymentCondition.description,
              4
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid("Banco", offerHistory?.payment?.bankCode, 1)}
          {CustomGrid("Agência", offerHistory?.payment?.agency, 1)}
          {CustomGrid("Conta", offerHistory?.payment?.account, 1)}
          {CustomGrid("Chave PIX", offerHistory?.payment?.pix, 1)}
        </Grid>
        {offerHistory?.payment?.details && (
          <Grid container spacing={1} alignItems="center">
            {CustomGrid(
              "Outras informações pertinentes ao pagamento",
              offerHistory?.payment?.details,
              12
            )}
          </Grid>
        )}
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Tributos que serão de responsabilidade de recolhimento pelo vendedor",
            offerHistory?.payment?.sellerTributes,
            12
          )}
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {CustomGrid(
            "Tributos que serão de responsabilidade de recolhimento pelo comprador",
            offerHistory?.payment?.buyerTributes,
            12
          )}
        </Grid> */}
      </div>
    </GenericInfosStyled>
  );
};

export default GenericInfos;
