import styled from "styled-components";
import { VariantType } from "theme/variants";

const HomeBrokerLayoutStyled: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(HomeBrokerLayoutStyled)`
  // .MuiDrawer-paper {
  //   background-color: green !important;
  // }
  .MuiToolbar-root {
    @media screen and (max-width: 600px) {
      min-height: 100px;
    }
  }
  .drawerHeaderHeightSet {
    min-height: 52px;
    @media screen and (max-width: 600px) {
      min-height: 84px;
    }
  }
  .rightHeaderContent {
    display: flex;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  .userInfo {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 9px 18px;
    background-color: ${(props) =>
      (props.theme as VariantType).palette.BBMprimary2.main};
    .name {
      display: inline-block;
      margin-left: 7px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .menuItemActive {
    background-color: ${(props) =>
      (props.theme as VariantType).palette.BBMprimary2.main};
  }
  .headerContainer {
    display: flex;
    @media screen and (max-width: 600px) {
      display: block;
    }
    width: 100%;
    justify-content: space-between;
  }
`;
