import styled from "styled-components";
import { VariantType } from "theme/variants";

const clock: React.FC<any> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default styled(clock)`
  .datetime-container {
    background-color: ${(props) =>
      (props.theme as VariantType).palette.BBMprimitiveGrey2.main};
    padding: 6px 12px;
    border-radius: 20px;
    .datetime {
      font-weight: bold;
    }
    .location {
      // font-style: italic;
    }
  }
`;
