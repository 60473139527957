import { Button, Grid } from "@material-ui/core";
import HeaderStyled from "./header.styled";

const Header: React.FC<any> = ({ contract }) => {
  return (
    <HeaderStyled>
      <div className="contractDetailsHeaderContainer">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <span>Contrato: {contract?.contractNumber}</span>
          </Grid>
          <Grid
            item
            xs={8}
            alignItems="center"
            justifyContent="flex-end"
            spacing={4}
            container
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => window.open(contract?.signedFileUrl || contract?.originalFileUrl, '_blank')} // eslint-disable-line
              >
                Ver Contrato
              </Button>
            </Grid>
            {/* <Grid>
              <Button
                variant="outlined"
                color="primary"
              >
                Ver nota de negociação
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </div>
    </HeaderStyled>
  );
};

export default Header;
