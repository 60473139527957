import { Button, FormControl, Grid, Input, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import DeliveryScheduleStyled from "./deliverySchedule.styled";
import Util from '../../../utils/util';



const DeliverySchedule: React.FC<any> = ({ contract }) => {
  return (
    <DeliveryScheduleStyled>
      <div className="deliverySchedule">
        {/* <span style={{fontWeight: "bold"}}>Reportar Execução</span>
        <Grid
          container
          spacing={4}
          alignItems="center"
        > 
          <Grid item>
            <FormControl>
              <InputLabel id="period-label">
                Período (MM/AAAA)
              </InputLabel>
              <Input
                id={"inputPeriod"}
                value={""}
              />
            </FormControl>
          </Grid>
          <Grid item >
            <FormControl>
              <InputLabel id="volume-label">
                Volume
              </InputLabel>
              <Input
                id={"volume"}
                value={""}
              />
            </FormControl>
          </Grid>
          <Grid item >
            <FormControl>
              <InputLabel id="danf-label">
                Link DANF
              </InputLabel>
              <Input
                id={"danf"}
                value={""}
              />
            </FormControl>
          </Grid>
          <Grid item >
            <FormControl>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "12px" }}
                >
                  Incluir
                </Button>
            </FormControl>
          </Grid>
        </Grid>
        <br/><br/> */}
        <Grid
          container
          spacing={6}
        >
          <Grid item xs={6} md={3}>
            <span style={{fontWeight: "bold"}}>Cronograma planejado</span>
            <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
              <Table
                style={{marginTop: "19px"}}
                size="small"
                aria-label="a dense table"
                stickyHeader
              >
                <TableBody>
                  {contract?.offer.deliverySchedules.map((d: any) => {
                    return (
                      <TableRow>
                        <TableCell align="left">{Util.formatDate(d.date, 'dd/MM/yy')}</TableCell>
                        <TableCell align="left">{`${d.quantity} ${contract.offer.product.unit}`}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* <Grid item xs={6} md={5}>
            <span style={{fontWeight: "bold"}}>Cronograma Executado</span>
            <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
              <Table
                style={{marginTop: "19px", background: "#f9f9f9"}}
                size="small"
                aria-label="a dense table"
                stickyHeader
              >
                <TableBody>
                    <TableRow>
                      <TableCell align="left">12/2021</TableCell>
                      <TableCell align="left">999.999.999 m³</TableCell>
                      <TableCell align="left"><a href="">link DANF</a></TableCell>
                      <TableCell align="right">
                        <CancelIcon
                          style={{ cursor: "pointer", width: "13px", height: "13px" }}
                          onClick={() => {
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">12/2021</TableCell>
                      <TableCell align="left">999.999.999 m³</TableCell>
                      <TableCell align="left"><a href="">link DANF</a></TableCell>
                      <TableCell align="right">
                        <CancelIcon
                          style={{ cursor: "pointer", width: "13px", height: "13px" }}
                          onClick={() => {
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">12/2021</TableCell>
                      <TableCell align="left">999.999.999 m³</TableCell>
                      <TableCell align="left"><a href="">link DANF</a></TableCell>
                      <TableCell align="right">
                        <CancelIcon
                          style={{ cursor: "pointer", width: "13px", height: "13px" }}
                          onClick={() => {
                          }}
                        />
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
        </Grid>
      </div>
    </DeliveryScheduleStyled>
  );
};

export default DeliverySchedule;