import styled from "styled-components";
import { VariantType } from "theme/variants";

const contractsList: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(contractsList)`
  .contractsListContainer {
    margin-top: 20px;
    .MuiTableHead-root {
      tr {
        th {
          font-weight: bold !important;
        }
      }
    }
    .MuiTableBody-root {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
    .contractButtonContainer {
      .MuiButton-contained {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.main};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
      .MuiButton-contained:hover {
        background: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.hover};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
    }

  .MuiTableHead-root {
    th {
      background-color: white !important;
    }
  }
  .MuiTableBody-root {
  }

  .signed-text {
    color: #5DC73F;
  }

  .unsigned-text {
    color: #E37714;
  }
`;
