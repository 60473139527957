import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import OffersListStyled from "./offersList.styled";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useCallback, useEffect, useRef, useState } from "react";
import { OffersState } from "store/offers/reducer";
import { useSelector } from "react-redux";
import useSocket from "service/socket";
import BidModal from "components/bidmodal";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { OfferDTO } from "models/offer.model";
import { SnackbarProvider, useSnackbar } from "notistack";
import { createApiService } from "service";
import {
  checkMarketIsClosed,
  formatCurrency,
  formatProductUnit,
  formatQuanty,
} from "util/format";
import { AppState } from "store/app/reducer";
import { useHistory } from "react-router";
import { EditOfferDialog } from "./offerListEditOfferDialog";

type deleteOfferDialogProps = {
  offerId: string;
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  participantId: string;
  stockbrokerId: string;
};

const DeleteOfferDialog: React.FC<deleteOfferDialogProps> = (
  props: deleteOfferDialogProps
) => {
  const { onClose, onConfirm, open, offerId, participantId, stockbrokerId } =
    props;
  const service = createApiService();

  const handleClose = () => {
    onClose();
  };

  const [cancelOfferRequesting, setCancelOfferRequesting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function cancelOffer() {
    setCancelOfferRequesting(true);
    service
      .post(`/offers/${offerId.replace("/", "-")}/cancel`, {
        participantId,
        stockbrokerId,
      })
      .then(() => {
        enqueueSnackbar("Oferta cancelada!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      })
      .catch(() => {
        enqueueSnackbar("Erro ao cancelar oferta", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      })
      .finally(() => {
        setCancelOfferRequesting(false);
        onClose();
      });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Confirmar cancelamento da oferta número <b>{props.offerId}</b>?
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={cancelOfferRequesting}
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancelOffer}
          >
            Sim
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type finishedDialogProps = {
  winner: boolean;
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
};

const BidFinishedDialog: React.FC<finishedDialogProps> = (
  props: finishedDialogProps
) => {
  const { onClose, onConfirm, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        {props.winner && "Oferta executada com sucesso"}
        {!props.winner && "Lance de registrado com sucesso"}
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
          >
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

type props = {
  offerType: "buy" | "sell";
};

const OffersList: React.FC<props> = ({ offerType }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuOfferId, setShowMenuOfferId] = useState("0");
  const [showMenuOfferSelected, setShowMenuOfferSelected] =
    useState<OfferDTO | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBidDialog, setShowBigDialog] = useState(false);
  const [offerIdBidDialog, setOfferIdBidDialog] = useState<string>("0");
  const [showBidFinishedDialog, setShowBidFinishedDialog] = useState(false);
  const [offerWasFinished, setOfferWasFinished] = useState(false);
  const [showDeleteOfferDialog, setShowDeleteOfferDialog] = useState(false);
  const [showEditOfferDialog, setShowEditOfferDialog] = useState(false);
  const [offerDialogVisualizationOnly, setOfferDialogVIsualizationOnly] =
    useState(false);

  const AppStore: AppState = useSelector((state: any) => state.app);

  useSocket();

  const history = useHistory();

  const systemTime = useSelector((state: any) => state.app.systemTime);
  const currentLocalTime = useSelector(
    (state: any) => state.app.currentLocalTime
  );
  const systemTimeSetAt = useSelector(
    (state: any) => state.app.systemTimeSetAt
  );

  const marketIsClosed = useCallback(() => {
    return checkMarketIsClosed(
      new Date(systemTime + (currentLocalTime - systemTimeSetAt))
    );
  }, [currentLocalTime]);

  const OffersStore: OffersState = useSelector((state: any) => state.offers);

  const renderCounter = useRef(0);

  useEffect(() => {
    renderCounter.current++;
  }, [offerType === "sell" ? OffersStore.sell_offers : OffersStore.buy_offers]);

  function createData(
    number: string,
    name: string,
    broker: string,
    city: string,
    uf: string,
    product: string,
    amount: string,
    price: string,
    bestBid: string | null,
    myBid: string | null
  ) {
    return {
      number,
      name,
      broker,
      city,
      uf,
      product,
      amount,
      price,
      bestBid,
      myBid,
    };
  }

  const rows = (
    offerType === "sell" ? OffersStore.sell_offers : OffersStore.buy_offers
  ).map((s) => {
    return {
      number: s.id,
      name: s.offerParticipant,
      broker: s.stockBrokerName,
      city: s.city,
      uf: s.uf,
      product: s.product,
      amount: s.quantity,
      price: s.unitPrice,
      bestBid: s.bestBid,
      myBid: s.myBid,
      isOfferCreator: s.isOfferCreator,
      unit: s.unit,
      changed: s.changed,
      publishParticipantData: s.publishParticipantData,
      coin: s.coin,
      valuetobedefined: s.valuetobedefined,
      myAward: s.myAward,
      award: s.unitAward,
      bestAward: s.bestAward,
      isBestBid:
        (s.myBid !== null && s.bestBid !== null && s.myBid === s.bestBid) ||
        (s.myAward !== null &&
          s.bestAward !== null &&
          s.myAward === s.bestAward),
      isLowerBid:
        (s.myBid !== null && s.bestBid !== null && s.myBid < s.bestBid) ||
        (s.myAward !== null && s.bestAward !== null && s.myAward < s.bestAward),
    };
  });

  function handleShowOptionsClick(e: any, offer: any) {
    setAnchorEl(e.currentTarget);
    setShowMenuOfferId(offer.number);
    setShowMenuOfferSelected(offer);
    setShowMenu(true);
  }

  function handleClose() {
    setShowMenu(false);
  }

  return (
    <OffersListStyled>
      <div className="offersListContainer">
        <TableContainer component={Paper} style={{ maxHeight: "350px" }}>
          <Table
            // className={classes.table}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Número</TableCell>
                <TableCell align="left">Ofertante</TableCell>
                <TableCell align="left">Cidade</TableCell>
                <TableCell align="left">Corretora</TableCell>
                <TableCell align="left">UF</TableCell>
                <TableCell align="left">Produto</TableCell>
                <TableCell align="left">Qtd</TableCell>
                <TableCell align="left" width="100">
                  Preço
                </TableCell>
                <TableCell align="left" width="100">
                  Melhor lance
                </TableCell>
                <TableCell align="left" width="100">
                  Meu lance
                </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.number}
                  className={
                    [
                      row.isBestBid
                        ? `bestBid${
                            row.changed
                              ? `Changed${renderCounter.current % 2}`
                              : ""
                          }`
                        : "",
                      row.isLowerBid
                        ? `lowBid${
                            row.changed
                              ? `Changed${renderCounter.current % 2}`
                              : ""
                          }`
                        : "",
                    ].join(" ") +
                    (row.changed && row.myBid === null
                      ? `changed${renderCounter.current % 2}`
                      : "")
                  }
                >
                  <TableCell align="left">{row.number}</TableCell>
                  <TableCell align="left">
                    <span>
                      {row.isOfferCreator || row.publishParticipantData
                        ? row.name
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span>
                      {row.isOfferCreator || row.publishParticipantData
                        ? row.city
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span>
                      {row.isOfferCreator || row.publishParticipantData
                        ? row.broker
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell align="left">{row.uf}</TableCell>
                  <TableCell align="left">{row.product}</TableCell>
                  <TableCell align="left">
                    {formatQuanty(row.amount)}
                    {formatProductUnit(row.unit)}
                  </TableCell>
                  <TableCell align="left">
                    {row.valuetobedefined
                      ? "A FIXAR"
                      : `${formatCurrency(row.price / 100, row.coin)} / ${
                          row.unit
                        }`}
                  </TableCell>
                  <TableCell align="left">
                    {row.bestBid && formatCurrency(row.bestBid / 100, row.coin)}
                    {row.bestAward &&
                      formatCurrency(row.bestAward / 100, row.coin)}
                  </TableCell>
                  <TableCell align="left">
                    <div
                      className="bidIcon"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {row.myBid && formatCurrency(row.myBid / 100, row.coin)}{" "}
                      {row.myAward &&
                        formatCurrency(row.myAward / 100, row.coin)}{" "}
                      <CancelIcon
                        style={{
                          marginLeft: "10px",
                          display:
                            row.myBid !== null &&
                            row.bestBid !== null &&
                            row.myBid < row.bestBid
                              ? "block"
                              : "none",
                        }}
                      />
                      <CheckIcon
                        style={{
                          marginLeft: "10px",
                          display:
                            row.myBid !== null &&
                            row.bestBid !== null &&
                            row.myBid === row.bestBid
                              ? "block"
                              : "none",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="offerButtonContainer">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOfferDialogVIsualizationOnly(false);
                          setOfferIdBidDialog(row.number);
                          setShowBigDialog(true);
                        }}
                        disabled={
                          marketIsClosed() ||
                          row.isOfferCreator ||
                          (AppStore.selectedStockBroker !== null &&
                            AppStore.selectedStockBroker.id === "*") ||
                          (AppStore.selectedParticipant !== null &&
                            AppStore.selectedParticipant.id === "none") ||
                          (AppStore.selectedParticipant !== null &&
                            AppStore.selectedParticipant.allowedAtBroker ===
                              false)
                        }
                      >
                        LANCE
                      </Button>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleShowOptionsClick(e, row as any);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={showMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
              boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.25)",
            },
          }}
        >
          {/* <MenuItem
          // key={option}
          // selected={option === "Pyxis"}
          // onClick={handleClose}
          >
            Executar
          </MenuItem> */}
          {/* {AppStore.selectedStockBroker !== null &&
            AppStore.selectedStockBroker.id !== "*" && (
              <MenuItem
                onClick={() => {
                  setOfferDialogVIsualizationOnly(false);
                  setOfferIdBidDialog(showMenuOfferId);
                  setShowBigDialog(true);
                  setShowMenu(false);
                }}
              >
                Dar lance
              </MenuItem>
            )} */}
          <MenuItem
            onClick={() => {
              setOfferDialogVIsualizationOnly(true);
              setOfferIdBidDialog(showMenuOfferId);
              setShowBigDialog(true);
              setShowMenu(false);
            }}
          >
            Ver resumo
          </MenuItem>
          {showMenuOfferSelected?.isOfferCreator &&
            AppStore.selectedStockBroker !== null &&
            AppStore.selectedStockBroker.id !== "*" && (
              <MenuItem
                onClick={() => {
                  setOfferIdBidDialog(showMenuOfferId);
                  setShowDeleteOfferDialog(true);
                  setShowMenu(false);
                }}
              >
                Cancelar oferta
              </MenuItem>
            )}
          {showMenuOfferSelected?.isOfferCreator &&
            AppStore.selectedStockBroker !== null &&
            AppStore.selectedStockBroker.id !== "*" && (
              <MenuItem
                onClick={() => {
                  setOfferIdBidDialog(showMenuOfferId);
                  setShowEditOfferDialog(true);
                  setShowMenu(false);
                }}
              >
                {showMenuOfferSelected?.valuetobedefined
                  ? "Alterar prêmio"
                  : "Alterar valor"}
              </MenuItem>
            )}
          {/* {showMenuOfferSelected?.isOfferCreator &&
            AppStore.selectedStockBroker !== null &&
            AppStore.selectedStockBroker.id !== "*" && (
              <MenuItem
                onClick={() => {
                  history.push(
                    `${
                      offerType === "sell"
                        ? "registrar-oferta-venda"
                        : "registrar-oferta-compra"
                    }?from=${showMenuOfferId.replace("/", "-")}`
                  );
                }}
              >
                Clonar
              </MenuItem>
            )} */}
        </Menu>
      </div>
      <BidModal
        open={showBidDialog}
        id={offerIdBidDialog}
        onClose={() => {
          setShowBigDialog(false);
        }}
        onConfirm={(winner: boolean) => {
          setOfferWasFinished(winner);
          setShowBidFinishedDialog(true);
        }}
        visualizerMode={offerDialogVisualizationOnly}
      />
      <BidFinishedDialog
        open={showBidFinishedDialog}
        onConfirm={() => {
          setShowBidFinishedDialog(false);
          setShowBigDialog(false);
        }}
        onClose={() => setShowBidFinishedDialog(false)}
        winner={offerWasFinished}
      />
      {AppStore.selectedParticipant && (
        <DeleteOfferDialog
          participantId={AppStore.selectedParticipant!.id}
          stockbrokerId={AppStore.selectedStockBroker!.id}
          onConfirm={() => {
            setShowDeleteOfferDialog(false);
          }}
          onClose={() => {
            setShowDeleteOfferDialog(false);
          }}
          open={showDeleteOfferDialog}
          offerId={showMenuOfferId}
        />
      )}
      <EditOfferDialog
        show={showEditOfferDialog}
        offerId={showMenuOfferId}
        onClose={() => {
          setShowEditOfferDialog(false);
        }}
      />
    </OffersListStyled>
  );
};

export default OffersList;
