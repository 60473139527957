import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AuthState } from 'store/auth/reducer';
import RegisterProduct from './registerProduct';

export default () => {
  const authStore: AuthState = useSelector((state: any) => state.auth);
  if (authStore.user.isAdmin === false) {
    return (
      <Redirect to='/'/>
    )
  }
  return (
  <div>
    <RegisterProduct></RegisterProduct>
  </div>
  );
};
