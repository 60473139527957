import { Button, FormControl, Grid } from "@material-ui/core";
import { useState } from "react";
import { createApiService } from "service";
import HeaderStyled from "./header.styled";

const Header: React.FC<any> = () => {
  return (
    <HeaderStyled>
      <div className="productVsParticipantsHeaderContainer">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <span style={{ fontWeight: "bold" }}>
              Produtos e Participantes
            </span>
          </Grid>
        </Grid>
      </div>
    </HeaderStyled>
  );
};

export default Header;
