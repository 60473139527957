import OffersPanelStyled from "./offersPanelStyled";
import Header from "./header";
import OffersList from "./offersList";

type props = {
  offerType: "buy" | "sell";
};

const Offers: React.FC<props> = ({ offerType }) => {
  return (
    <OffersPanelStyled>
      <div className="offersContainer">
        <Header offerType={offerType}></Header>
        <OffersList offerType={offerType}></OffersList>
      </div>
    </OffersPanelStyled>
  );
};

export default Offers;
