import styled from "styled-components";
import { VariantType } from "theme/variants";

const offersHistoryList: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(offersHistoryList)`
  .offersHistoryListContainer {
    margin-top: 20px;
    .MuiTableHead-root {
      tr {
        th {
          font-weight: bold !important;
        }
      }
    }
    .offersHistoryButtonContainer {
      .MuiButton-contained {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.main};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
      .MuiButton-contained:hover {
        background: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.hover};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimitiveDark.contrastText};
      }
    }

  .MuiTableHead-root {
    th {
      background-color: white !important;
    }
  }
  .MuiTableBody-root {
  }

  .closed-offer {
    font-weight: bold;
  }

  .canceled-offer {
    color: #E37714;
  }

  .open-offer {
    font-weight: bold;
  }

  .negotiated-offer {
    color: #5DC73F;
  }

  .partially-negotiated-offer {
    color: grey;
  }
`;
