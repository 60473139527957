import ProductsPanelStyled from "./productsPanel.styled";
import ProductsList from "./productsList";
import { Grid, MenuItem, Select, FormControl, Input } from "@material-ui/core";
import HeaderStyled from "./header.styled";
import { Button } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { createApiService } from "service";
import { useSelector } from "react-redux";
import { AppState } from "store/app/reducer";
import { Box } from "@material-ui/core";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateLuxonUtils from "@date-io/luxon";
import { useHistory } from "react-router-dom";

type SelectFilter = {
  label: string;
  options: any[];
};

const Products: React.FC<any> = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [periodFrom, setPeriodFrom] = useState(null);
  const [periodTo, setPeriodTo] = useState(null);
  const offerNumber = useRef("");
  const [type, setType] = useState("");
  const [situation, setSituation] = useState("");
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    const { data } = await service.get(
      '/products/baseProducts'
    );
    setProducts(data);
  }

  const SelectFilterComponent = (
    selectFilter: SelectFilter,
    setFunction: any
  ) => {
    return (
      <Grid item>
        <FormControl>
          <Select
            defaultValue="none"
            labelId={`${selectFilter.label}-select-label`}
            label={selectFilter.label}
            id={`${selectFilter.label}-select`}
            variant="standard"
            style={{ width: "190px" }}
            onChange={(e) => {
              setFunction(e.target.value);
            }}
          >
            <option value="none" disabled>
              {selectFilter.label}
            </option>
            {selectFilter.options.map((option) => (
              <MenuItem value={option.value}>{option.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  function buildQueryParam(key: string, value: string) {
    return value ? `&${key}=${value}` : "";
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    let periodParam = "";
    if (periodFrom || periodTo) {
      periodParam = `createdAt=${periodFrom}to${periodTo}`;
    }
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    const queryParams = `?${periodParam}${buildQueryParam(
      "type",
      type
    )}${buildQueryParam("offerStatus", situation)}${buildQueryParam(
      "offerNumber",
      offerNumber.current
    )}`;
    const { data } = await service.get(
      `/products/participant/${participantId}/stockbrocker/${stockbrockerId}${queryParams}`
    );
    setProducts(data);
  }

  return (
    <ProductsPanelStyled>
      <div className="productsContainer">
        <HeaderStyled>
          <div className="productsHeaderContainer">
            <Grid container>
              <Grid item sm={10}>
                {/* <h3>Filtrar Produto:</h3> */}
                <h3>Produtos</h3>
              </Grid>
              <Grid item sm={2} style={{ marginTop: "10px" }}>
                <FormControl>
                  <Button variant="outlined" color="primary" onClick={() => {
                    history.push(`/registrar-produto`);
                  }}>
                    CADASTRAR PRODUTO
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
            {/* <div className="filters">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>
                    <Input
                        placeholder="Nome do Produto"
                        id="productName"
                        style={{ width: "190px" }}
                        onKeyUp={(event: any) => {
                          offerNumber.current = event.currentTarget.value;
                        }}
                      />
                  </Grid>
                  
                  <Grid item>
                    <FormControl>
                      <Button type="submit" variant="outlined" color="primary">
                        FILTRAR
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </div> */}
          </div>
        </HeaderStyled>
      </div>
      <div className="productsContainer">
        <ProductsList products={products}></ProductsList>
      </div>
    </ProductsPanelStyled>
  );
};

export default Products;
