import styled from "styled-components";

const genericInfos: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(genericInfos)`
  .genericInfos {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    box-shadow: rgba(238, 238, 238, 0.3);
    padding: 20px;
  }
`;
