import merge from "deepmerge";
import { green, grey } from "@material-ui/core/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    type: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const bbmVariant = merge(defaultVariant, {
  name: THEMES.BBM,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    BBMprimary: {
      main: "#003C1D",
      contrastText: "#FFF",
      hover: "#003017",
    },
    BBMprimary2: {
      main: "#005326",
      contrastText: "#FFF",
      hover: "#003017",
    },
    BBMprimaryLight: {
      main: "#5DC73F",
      contrastText: "#FFF",
      hover: "#53b338",
    },
    BBMprimitiveWhite: {
      main: "#FFF",
      contrastText: "black",
    },
    BBMprimitiveGrey: {
      main: "#EEEEEE",
      contrastText: "black",
    },
    BBMprimitiveDark: {
      main: "#474646",
      contrastText: "white",
      hover: "#383838",
    },
    BBMprimitiveGrey2: {
      main: "#E0E0E0",
      contrastText: "black",
    },
    BBMtableBG1: {
      main: "#F9F9F9",
      contrastText: "black",
    },
    GoodBidBackground: {
      main: "#E1FFD9",
      contrastText: "black",
    },
    BadBidBackground: {
      main: "#FFF1E4",
      contrastText: "black",
    },
  },
  header: {
    background: "#FFF",
    indicator: {
      background: "green[600]",
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants: Array<VariantType> = [bbmVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    BBMprimary: MainContrastTextType;
    BBMprimary2: MainContrastTextType;
    BBMprimaryLight: MainContrastTextType;
    BBMprimitiveWhite: MainContrastTextType;
    BBMprimitiveGrey: MainContrastTextType;
    BBMprimitiveDark: MainContrastTextType;
    BBMprimitiveGrey2: MainContrastTextType;
    BBMtableBG1: MainContrastTextType;
    GoodBidBackground: MainContrastTextType;
    BadBidBackground: MainContrastTextType;
  };
  header: ColorBgType & {
    background: string;
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
  hover?: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
