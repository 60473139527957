import { OfferDTO } from "models/offer.model";

export enum actionTypes {
  SET_SELL_OFFERS = "[OFFERS] Set sell offers",
  SET_BUY_OFFERS = "[OFFERS] Set buy offers",
}

export interface OfferActionsT {
  setSellOffers(offers: OfferDTO[]): void;
  setBuyOffers(offers: OfferDTO[]): void;
}

export const OfferActions = (): OfferActionsT => {
  const actions = {
    setSellOffers(offers: OfferDTO[]) {
      return {
        type: actionTypes.SET_SELL_OFFERS,
        payload: offers,
      };
    },
    setBuyOffers(offers: OfferDTO[]) {
      return {
        type: actionTypes.SET_BUY_OFFERS,
        payload: offers,
      };
    },
  };

  function setSellOffers(offers: OfferDTO[]) {
    return (dispatch: any) => {
      dispatch(actions.setSellOffers(offers));
    };
  }

  function setBuyOffers(offers: OfferDTO[]) {
    return (dispatch: any) => {
      dispatch(actions.setBuyOffers(offers));
    };
  }

  return { setSellOffers, setBuyOffers };
};
