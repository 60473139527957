import { Button as B, Avatar } from "@material-ui/core";

const Test: React.FC = () => {
  return (
    <div>
      <Avatar title="Thomas" />
      <B variant="contained" color="primary">
        Teste
      </B>
    </div>
  );
};

export default Test;
