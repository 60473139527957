import Keycloak from "keycloak-js";
import { UserKC } from "models/user.mode";
import { createAuthService } from "./service";
import { actionTypes } from "./reducer";

function utf8_to_b64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

export interface AuthActions {
  setUserAccessToken(token: string): void;
  checkUserAuthKeyCloak(): void;
  logout: () => void;
}

export const authActions = (): AuthActions => {
  const service = createAuthService();

  const actions = {
    setUserAccessToken(access_token: string) {
      return {
        type: actionTypes.SET_USER_ACCESS_TOKEN,
        payload: access_token,
      };
    },
    clearUserInfo() {
      return {
        type: actionTypes.CLEAR_USER_INFO,
      };
    },
    setUserInfo(user: UserKC) {
      return {
        type: actionTypes.SET_USER_INFO,
        payload: user,
      };
    },
  };

  function setUserAccessToken(access_token: string) {
    return (dispatch: any) => {
      dispatch(actions.setUserAccessToken(access_token));
    };
  }

  function clearUserInfo() {
    localStorage.removeItem("refresh");
    window.setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function logout() {
    return () => {
      localStorage.removeItem("refresh");
      window.location.replace(
        `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout?redirect_uri=${process.env.REACT_APP_URL}`
      );
    };
  }

  async function refreshToken(dispatch: any) {
    try {
      const access_token = await service.refreshToken(
        localStorage.getItem("refresh") || ""
      );
      dispatch(actions.setUserAccessToken(access_token));

      const userInfoFromToken = JSON.parse(
        b64_to_utf8(access_token.split(".")[1])
      );

      // console.log(userInfoFromToken);

      const user: UserKC = {
        name: userInfoFromToken.given_name,
        photo: null,
        isAdmin: userInfoFromToken.realm_access.roles.includes('datagro:admin')
      };

      dispatch(actions.setUserInfo(user));

      const expirationTimeInSecnods =
        userInfoFromToken.exp - userInfoFromToken.iat;

      setTimeout(() => {
        // console.log("refreshing...");
        refreshToken(dispatch);
      }, (expirationTimeInSecnods - 20) * 1000); // 100 * 1000);
    } catch (e: any) {
      if (!e.response) {
        clearUserInfo();
      } else if (
        e.response.status === 400 &&
        e.response.data.error_description === "Token is not active"
      ) {
        clearUserInfo();
      } else if (
        e.response.status === 400 &&
        e.response.data.error_description === "Session not active"
      ) {
        clearUserInfo();
      }
    }
  }

  function checkUserAuthKeyCloak() {
    return async (dispatch: any) => {
      const keycloak = Keycloak({
        realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
        url: process.env.REACT_APP_KEYCLOAK_AUTH_URL as string,
      });

      if (
        !localStorage.getItem("auth_method") &&
        !localStorage.getItem("refresh")
      ) {
        keycloak
          .init({
            onLoad: "check-sso",
            silentCheckSsoRedirectUri:
              window.location.origin + "/silent-check-sso.html",
          })
          .then((auth) => {
            // localStorage.setItem("token", keycloak.token as any);
            if (!auth) {
              localStorage.setItem("auth_method", "kc");
              window.location.reload();
            } else {
              localStorage.setItem("refresh", keycloak.refreshToken as any);
              refreshToken(dispatch);
            }
            // console.log(auth);
          })
          .catch((e) => {
            localStorage.setItem("auth_method", "kc");
          });
      } else if (localStorage.getItem("auth_method") === "kc") {
        await new Promise((resolve, reject) => {
          setTimeout(resolve, 2000);
        });
        localStorage.removeItem("auth_method");

        keycloak
          .init({ onLoad: "login-required", checkLoginIframe: false })
          .then((auth) => {
            if (auth) {
              // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@");
            }
            // console.log(auth);
            // console.log(keycloak.token);
            localStorage.setItem("refresh", keycloak.refreshToken as any);
            refreshToken(dispatch);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (localStorage.getItem("refresh")) {
        // console.log("has Refreshhhhh");
        refreshToken(dispatch);
      }
    };
  }

  return { setUserAccessToken, checkUserAuthKeyCloak, logout };
};
