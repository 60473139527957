import { OfferDTO } from "models/offer.model";
import { actionTypes } from "./actions";

export type OffersState = {
  sell_offers: OfferDTO[];
  buy_offers: OfferDTO[];
};

const initialState: OffersState = {
  sell_offers: [],
  buy_offers: [],
};

type actionType = {
  type: actionTypes;
  payload: any;
};

const reducer = (state: OffersState = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.SET_SELL_OFFERS:
      return { ...state, sell_offers: action.payload };
    case actionTypes.SET_BUY_OFFERS:
      return { ...state, buy_offers: action.payload };
    default:
      return state;
  }
};

export default reducer;
