import { createApiService } from "service";
import qs from "qs";

export const createAuthService = () => {
  const service = createApiService();

  const testRoute = (cnpj: string): Promise<void> => {
    return service.get(`/cnpj/${cnpj}`);
  };

  const refreshToken = async (refreshToken: string): Promise<string> => {
    const formUrl = {
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };

    const { data } = await service({
      method: "post",
      url: `${process.env.REACT_APP_KEYCLOAK_AUTH_URL as string}/realms/${
        process.env.REACT_APP_KEYCLOAK_REALM
      }/protocol/openid-connect/token`,
      data: qs.stringify(formUrl),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return data.access_token;
  };

  return { testRoute, refreshToken };
};
