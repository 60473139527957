import HomeBrokerLayout from "../layouts/homeBroker";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import React from "react";
import { RouteType } from "../types/routes";

import Teste from "../views/teste";
import AuthTeste from "views/testeauth";
import Offers from "views/offers";
import RegisterSellOffer from "views/registerSellOffer";
import RegisterBuyOffer from "views/registerBuyOffer";
import Contracts from "views/contracts";
import Reports from "views/reports";
import OffersHistory from "views/offers-history";
import ContractDetails from "components/contracts/contractDetails/contractDetailsPanel";
import OfferHistoryDetails from "components/offersHistory/offerHistoryDetails/offerHistoryDetailsPanel";
import Products from "views/products";
import RegisterProduct from "../components/products/register";
import ContractSimulator from "views/contractSimulator2";
import ProductDetails from "components/products/productDetails/productDetailsPanel";
import ProductsVsParticipants from "../components/productsVsParticipants/productsVsParticipantsPanel";

const dashboardsRoutes: RouteType = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: undefined,
  containsHome: true,
  children: [
    {
      path: "/",
      name: "Home",
      component: (() => {
        return <Redirect to="/ofertas" />;
      }) as any,
    },
    {
      path: "/dashboard/default",
      name: "Default",
      component: Teste,
    },
    {
      path: "/dashboard/auth",
      name: "Default",
      component: AuthTeste,
    },
    {
      path: "/ofertas",
      name: "Ofertas",
      component: Offers,
    },
    {
      path: "/registrar-oferta-venda",
      name: "Registrar oferta de venda",
      component: RegisterSellOffer,
    },
    {
      path: "/registrar-oferta-compra",
      name: "Registrar oferta de compra",
      component: RegisterBuyOffer,
    },
    {
      path: "/contratos",
      name: "Contratos",
      component: Contracts,
    },
    {
      path: "/contratos/:id",
      name: "Detalhes Contrato",
      component: ContractDetails,
    },
    {
      path: "/relatorios",
      name: "Relatórios",
      component: Reports,
    },
    {
      path: "/historico-ofertas",
      name: "Histórico de ofertas",
      component: OffersHistory,
    },
    {
      path: "/historico-ofertas/:id",
      name: "Histórico de ofertas",
      component: OfferHistoryDetails,
    },
    {
      path: "/produtos",
      name: "Produtos",
      component: Products,
    },
    {
      path: "/registrar-produto",
      name: "Produtos",
      component: RegisterProduct,
    },
    {
      path: "/editar-produto/:id",
      name: "Produtos",
      component: RegisterProduct,
    },
    {
      path: "/produto/:baseProductId",
      name: "Produtos",
      component: ProductDetails,
    },
    {
      path: "/simulador-contrato/:productId",
      name: "Simulador de Contrato",
      component: ContractSimulator,
    },
    {
      path: "/associar-produtos",
      name: "Participantes e Produtos",
      component: ProductsVsParticipants,
    },
  ],
  component: null,
};

const homeBrokerLayoutRoutes = [dashboardsRoutes];

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element: any, index: number) => {
          const Guard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <Guard>
                    <ElementComponent {...props} />
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const Routes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(HomeBrokerLayout, homeBrokerLayoutRoutes)}
        {/* <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
