import styled from "styled-components";

const Context: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(Context)`
  .contextcontainer {
    display: flex;
    align-items: center;
  }
  .editcontainer {
    margin-left: 15px;
    background-color: #f2f2f2;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .editicondisabled {
    cursor: default;
    opacity: 0.1;
  }
`;
