import { OfferDTO } from "models/offer.model";
import { useDispatch } from "react-redux";
import io, { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io-client/build/typed-events";
import { useAction } from "store";
import { OfferActions } from "store/offers/actions";

let initialized = false;
let sio: Socket<DefaultEventsMap, DefaultEventsMap>;

let sell_productFilter = "*";
let sell_stateFilter = "*";
let buy_productFilter = "*";
let buy_stateFilter = "*";
let sell_personalFilter = "*";
let buy_personalFilter = "*";

function useOffers() {
  const dispatch = useDispatch();
  const OffersAction = useAction(OfferActions());

  function setSellOffers(offers: OfferDTO[]) {
    dispatch(OffersAction.setSellOffers(offers));
  }

  function setBuyOffers(offers: OfferDTO[]) {
    dispatch(OffersAction.setBuyOffers(offers));
  }

  return {
    setSellOffers,
    setBuyOffers,
  };
}

const watcherOfferFunctions: any = {};

function useSocket() {
  const { setSellOffers, setBuyOffers } = useOffers();

  function dispatchBuyFilters() {
    sio.emit("setBuyFilter", {
      product: buy_productFilter,
      UF: buy_stateFilter,
      personalFilter: buy_personalFilter,
    });
  }

  function dispatchSellFilters() {
    sio.emit("setSellFilter", {
      product: sell_productFilter,
      UF: sell_stateFilter,
      personalFilter: sell_personalFilter,
    });
  }

  function watchOffer(offerId: string, cb: () => void) {
    watcherOfferFunctions[offerId] = cb;
    sio.emit("joinOfferRoom", offerId);
    return () => {
      sio.emit("leaveOfferRoom", offerId);
      delete watcherOfferFunctions[offerId];
    };
  }

  function auth(
    authData: {
      token: string;
      participant: string;
      broker: string;
    },
    reconnectedCB: () => void
  ) {
    sio.emit("auth", authData);
    sio.on("connect", () => {
      reconnectedCB();
    });
  }

  function setSellFilters(state: string, product: string) {
    sell_stateFilter = state;
    sell_productFilter = product;
    dispatchSellFilters();
  }

  function setSellPersonalFilter(filter: string) {
    sell_personalFilter = filter;
    dispatchSellFilters();
  }

  function setBuyFilters(state: string, product: string) {
    buy_stateFilter = state;
    buy_productFilter = product;
    dispatchBuyFilters();
  }

  function setBuyPersonalFilter(filter: string) {
    buy_personalFilter = filter;
    dispatchBuyFilters();
  }

  if (initialized) {
    return {
      setSellFilters,
      setBuyFilters,
      setSellPersonalFilter,
      setBuyPersonalFilter,
      watchOffer,
      auth,
    };
  }

  console.log("initing----------");
  initialized = true;
  sio = io(process.env.REACT_APP_REALTIME_SERVER_ADDRESS as string);

  sio.on("sell_offers", (offers) => {
    setSellOffers(offers);
  });

  sio.on("buy_offers", (offers) => {
    setBuyOffers(offers);
  });

  sio.on("offerChanged", (offerId) => {
    if (watcherOfferFunctions[offerId]) {
      watcherOfferFunctions[offerId]();
    }
  });
  return {
    setSellFilters,
    setBuyFilters,
    setSellPersonalFilter,
    setBuyPersonalFilter,
    watchOffer,
    auth,
  };
}

export default useSocket;