import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LogsHistoryStyled from "./logsHistory.styled";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import { ReactElement, useState } from "react";
import { formatCurrency, formatProductUnit, formatQuanty } from "util/format";
import Util from "../../../utils/util";

const LogsHistory: React.FC<any> = ({ offerHistory }) => {
  const [expandAcordeonShipment, setExpandAcordeonShipment] = useState(false);
  const [expandAcordeonDocuments, setExpandAcordeonDocuments] = useState(false);
  const [expandAcordeonClients, setExpandAcordeonClients] = useState(false);

  function getLogElement(log: any): ReactElement {
    const [date, time] = Util.formatDate(
      log.createdAt,
      "dd/MM/yy HH:mm:ss"
    ).split(" ");

    switch (log.event) {
      case "OFFER_CREATED":
        return (
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{time}hrs</TableCell>
            <TableCell align="left">Oferta criada</TableCell>
          </TableRow>
        );
      case "BID_CREATED":
        return (
          <>
            {log.meta.offerFinished && (
              <TableRow>
                <TableCell align="left">{date}</TableCell>
                <TableCell align="left">{time}hrs</TableCell>
                <TableCell align="left">Oferta finalizada</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="left">{date}</TableCell>
              <TableCell align="left">{time}hrs</TableCell>
              <TableCell align="left">
                Lance{log.meta.partialBid ? " parcial de" : ""}{" "}
                {log.meta.partialBid && (
                  <>
                    {log.meta.quantity}
                    {formatProductUnit(log.meta.unit)}{" "}
                  </>
                )}{" "}
                {log.meta.valueToBeDefined ? "com prêmio" : "no valor"} de{" "}
                {formatCurrency(
                  (log.meta.valueToBeDefined
                    ? log.meta.award
                    : log.meta.value) / 100,
                  offerHistory.coin
                )}{" "}
                {!log.meta.valueToBeDefined
                  ? `/ ${formatProductUnit(log.meta.unit)}`
                  : ""}{" "}
                recebido{" "}
                {log.meta.offerExecuted ? "e executado automaticamente" : ""}
              </TableCell>
            </TableRow>
          </>
        );
      case "BID_CANCELED":
        return (
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{time}hrs</TableCell>
            <TableCell align="left">
              Lance{log.meta.partialBid ? " parcial de" : ""}{" "}
              {log.meta.partialBid && (
                <>
                  {log.meta.quantity}
                  {formatProductUnit(log.meta.unit)}{" "}
                </>
              )}
              no valor de{" "}
              {formatCurrency(log.meta.value / 100, offerHistory.coin)} /{" "}
              {formatProductUnit(log.meta.unit)} cancelado{" "}
              {log.meta.offerExecuted ? "e executado automaticamente" : ""}
            </TableCell>
          </TableRow>
        );
      case "SET_WINNER_BID":
        return (
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{time}hrs</TableCell>
            <TableCell align="left">
              Lance{log.meta.partialBid ? " parcial de" : ""}{" "}
              {log.meta.partialBid && (
                <>
                  {log.meta.quantity}
                  {formatProductUnit(log.meta.productUnit)}{" "}
                </>
              )}
              no valor de{" "}
              {formatCurrency(log.meta.value / 100, offerHistory.coin)} /{" "}
              {formatProductUnit(log.meta.productUnit)} aceito manualmente por
              operador
            </TableCell>
          </TableRow>
        );
      case "OFFER_CLOSED":
        return (
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{time}hrs</TableCell>
            <TableCell align="left">
              Oferta encerrada automaticamente - fechamento de mercado
            </TableCell>
          </TableRow>
        );
      case "OFFER_CANCELED":
        return (
          <TableRow>
            <TableCell align="left">{date}</TableCell>
            <TableCell align="left">{time}hrs</TableCell>
            <TableCell align="left">Oferta encerrada cancelada</TableCell>
          </TableRow>
        );
      case "OFFER_UPDATED":
        return (
          <>
            {log.meta.offerFinished && (
              <TableRow>
                <TableCell align="left">{date}</TableCell>
                <TableCell align="left">{time}hrs</TableCell>
                <TableCell align="left">Oferta finalizada</TableCell>
              </TableRow>
            )}
            {log.meta.autoExecutedBids.map((bid: any) => {
              return (
                <TableRow>
                  <TableCell align="left">{date}</TableCell>
                  <TableCell align="left">{time}hrs</TableCell>
                  <TableCell align="left">
                    Lance{bid.partialBid ? " parcial de" : ""}{" "}
                    {bid.partialBid && (
                      <>
                        {bid.quantity}
                        {formatProductUnit(log.meta.unit)}{" "}
                      </>
                    )}
                    {bid.value ? "no valor de" : "com prêmio de"}{" "}
                    {formatCurrency(
                      (bid.value ? bid.value : bid.award) / 100,
                      offerHistory.coin
                    )}
                    {!log.meta.award
                      ? `/ ${formatProductUnit(log.meta.unit)}`
                      : ""}{" "}
                    executado automaticamente após alteração de valor da oferta
                    por operador{" "}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align="left">{date}</TableCell>
              <TableCell align="left">{time}hrs</TableCell>
              <TableCell align="left">
                Oferta atualizada,
                {log.meta.price
                  ? ` novo valor: ${formatCurrency(
                      log.meta.price / 100,
                      offerHistory.coin
                    )}`
                  : ""}
                {log.meta.award
                  ? ` novo prêmio: ${formatCurrency(
                      log.meta.award / 100,
                      offerHistory.coin
                    )}`
                  : ""}{" "}
                {!log.meta.award ? formatProductUnit(log.meta.unit) : ""}{" "}
              </TableCell>
            </TableRow>
          </>
        );
    }

    return (
      <>
        <TableRow>
          <TableCell align="left">{date}</TableCell>
          <TableCell align="left">{time}hrs</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <LogsHistoryStyled>
      <div className="logsHistory">
        <Accordion
          expanded={expandAcordeonShipment}
          onChange={(v) => {
            setExpandAcordeonShipment((v) => !v);
          }}
          style={{
            borderBottom: !expandAcordeonShipment ? "0px" : undefined,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <b>Cronograma de Entrega</b>
          </AccordionSummary>
          <AccordionDetails>
            <div className="shipment">
              <div className="sectionContent">
                <TableContainer
                  component={Paper}
                  className={
                    (offerHistory?.deliverySchedules as any[])?.length === 1
                      ? "noBorderTable"
                      : ""
                  }
                >
                  <Table
                    // className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                  >
                    <TableBody>
                      {offerHistory?.deliverySchedules.map((d: any) => {
                        return (
                          <TableRow>
                            <TableCell align="left">
                              {d.date.split("-")[1]}/{d.date.split("-")[0]}
                            </TableCell>
                            <TableCell align="left">
                              {formatQuanty(d.quantity)} m³{" "}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {offerHistory?.participants?.length > 0 && (
          <Accordion
            expanded={expandAcordeonClients}
            onChange={(v) => {
              setExpandAcordeonClients((v) => !v);
            }}
            style={{
              borderBottom: !expandAcordeonClients ? "0px" : undefined,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <b>Clientes selecionados</b>
            </AccordionSummary>
            <AccordionDetails>
              <div className="shipment">
                <div className="sectionContent">
                  <TableContainer
                    component={Paper}
                    className={
                      (offerHistory?.participants as any[])?.length === 1
                        ? "noBorderTable"
                        : ""
                    }
                  >
                    <Table
                      // className={classes.table}
                      size="small"
                      aria-label="a dense table"
                      stickyHeader
                    >
                      <TableBody>
                        {offerHistory?.participants.map((d: any, key: any) => {
                          return (
                            <TableRow key={key}>
                              <TableCell align="left">
                                {d.companyCnpj}
                              </TableCell>
                              <TableCell align="left">
                                {d.companyName}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {offerHistory?.documents?.length > 0 && (
          <Accordion
            expanded={expandAcordeonDocuments}
            onChange={(v) => {
              setExpandAcordeonDocuments((v) => !v);
            }}
            style={{
              borderBottom: !expandAcordeonDocuments ? "0px" : undefined,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <b>Documentos</b>
            </AccordionSummary>
            <AccordionDetails>
              <div className="documents">
                <div className="sectionContent">
                  <TableContainer
                    component={Paper}
                    className={
                      offerHistory?.documents?.length === 1
                        ? "noBorderTable"
                        : ""
                    }
                  >
                    <Table
                      // className={classes.table}
                      size="small"
                      aria-label="a dense table"
                      stickyHeader
                    >
                      <TableBody>
                        {offerHistory?.documents.map((doc: any, key: any) => {
                          return (
                            <TableRow>
                              <TableCell align="left">
                                {doc.originalFilename}
                              </TableCell>
                              <TableCell align="right">
                                <a
                                  style={{ color: "black" }}
                                  href={doc.downloadURL as string}
                                >
                                  {" "}
                                  <GetAppIcon style={{ marginRight: "20px" }} />
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <br />
        <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
          <span style={{ fontWeight: "bold" }}>Histórico da Sessão:</span>
          <Table
            className="mt-19"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Data</TableCell>
                <TableCell align="left">Hora</TableCell>
                <TableCell align="left">Evento</TableCell>
                {/* <TableCell align="left">Operador</TableCell>
                <TableCell align="left">Participante</TableCell>
                <TableCell align="left">Ordem</TableCell>
                <TableCell align="left">Preço</TableCell>
                <TableCell align="left">Situação</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {offerHistory?.log.map((log: any) => {
                const [date, time] = Util.formatDate(
                  log.createdAt,
                  "dd/MM/yy HH:mm:ss"
                ).split(" ");
                return getLogElement(log);
                // // <TableRow>
                // //   <TableCell align="left">{date}</TableCell>
                // //   <TableCell align="left">{time}hrs</TableCell>
                //   {/* <TableCell align="left">{bid.bidCreatorName}</TableCell>
                //   <TableCell align="left">{bid.participantName}</TableCell>
                //   <TableCell align="left">Lance</TableCell>
                //   <TableCell align="left">{`R$ ${
                //     bid.value / 100
                //   }`}</TableCell>
                //   <TableCell align="left">
                //     <span
                //       style={{
                //         fontWeight:
                //           offerHistory?.winnerBid !== bid.id
                //             ? undefined
                //             : "bold",
                //         color: bid.status === "CREATED" ? undefined : "red",
                //       }}
                //     >
                //       {offerHistory?.winnerBid !== bid.id
                //         ? bid.status === "CREATED"
                //           ? "Aberta"
                //           : "Cancelado"
                //         : "Vencedora"}
                //     </span>
                //   </TableCell> */}
                // // </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </LogsHistoryStyled>
  );
};

export default LogsHistory;
