import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import VersionsHistoryStyled from "./versionsHistory.styled";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import { ReactElement, useState } from "react";
import { formatCurrency, formatProductUnit, formatQuanty } from "util/format";
import Util from "../../../utils/util";

const VersionsHistory: React.FC<any> = ({ products }) => {
  const [acordeonIndex, setAcordeonIndex] = useState(0);

  return (
    <VersionsHistoryStyled>
      <div className="versionsHistory">
        {products?.map((p: any, i: number) => {
          return (
            <Accordion
              expanded={acordeonIndex === i}
              onChange={(v) => {
                setAcordeonIndex(i);
              }}
            >
              <AccordionSummary
                expandIcon={acordeonIndex !== i ? <ExpandMoreIcon /> : <></>}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <b>
                  Versão de {Util.formatDate(p.createdAt, "dd/MM/yy HH:mm")}
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <div className="shipment">
                  <div className="sectionContent">
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        Nome do Produto:{" "}
                      </span>
                      {p.name}
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        Unidade de Medida:{" "}
                      </span>
                      {p.measurementUnit.description}(
                      {p.measurementUnit.abbreviation})
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        ID do Contrato:{" "}
                      </span>
                      {p.contractTemplateId || "Não Definido"}
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        ID do Contrato de preço a fixar:{" "}
                      </span>
                      {p.contractTemplateIdIndexValue || "Não definido"}{" "}
                    </div>
                    <br />
                    <div>
                      <TableContainer component={Paper}>
                        <span style={{ fontWeight: "bold" }}>Atributos: </span>
                        <Table
                          size="small"
                          aria-label="metadados"
                          style={{ marginTop: "10px" }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Descrição do Campo
                              </TableCell>
                              <TableCell align="left">Tipo do Campo</TableCell>
                              <TableCell align="left">
                                Parâmetro no Contrato
                              </TableCell>
                              <TableCell align="left">Obrigatório</TableCell>
                              <TableCell align="left">Tamanho Máximo</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {p.metadataDefinition?.map((m: any, i: number) => {
                              const FIELD_TYPES: any = {
                                boolean: "Lógico",
                                string: "Texto",
                                number: "Número",
                              };
                              return (
                                <TableRow key={i}>
                                  <TableCell align="left">{m.label}</TableCell>
                                  <TableCell align="left">
                                    {FIELD_TYPES[m.fieldType]}
                                  </TableCell>
                                  <TableCell align="left">
                                    {m.fromToContractTemplate}
                                  </TableCell>
                                  <TableCell align="left">
                                    {m.required ? "Sim" : "Não"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {m.maxLength || ""}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </VersionsHistoryStyled>
  );
};

export default VersionsHistory;
