import ContractsPanelStyled from "./contractsPanel.styled";
import HeaderStyled from "./header.styled";
import {
  Grid,
  InputLabel,
  MenuItem,
  Input,
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import ContractsList from "./contractsList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createApiService } from "service";
import { AppState } from "store/app/reducer";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateLuxonUtils from "@date-io/luxon";
const qs = require("query-string");

type SelectFilter = {
  label: string;
  options: any[];
};

const Contracts: React.FC<any> = () => {
  const [contracts, setContracts] = useState([]);
  const [periodFrom, setPeriodFrom] = useState(null);
  const [periodTo, setPeriodTo] = useState(null);
  const [seller, setSeller] = useState("");
  const [buyer, setBuyer] = useState("");
  const [offerNumber, setOfferNumber] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [situation, setSituation] = useState("");

  const AppStore: AppState = useSelector((state: any) => state.app);

  const service = createApiService();

  useEffect(() => {
    const baseOfferId = qs.parse((window.location as any).search).offerId;

    if (baseOfferId) {
      setOfferNumber(baseOfferId.replace("-", "/"));
      (document.getElementById("offerNumber") as any).value =
        baseOfferId.replace("-", "/");
      handleSubmit(null, baseOfferId);
    } else {
      getContracts();
    }
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  async function getContracts() {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    if (participantId && stockbrockerId) {
      const { data } = await service.get(
        `/contracts/participant/${participantId}/stockbrocker/${stockbrockerId}`
      );
      setContracts(data);
    }
  }

  const SelectFilterComponent = (
    selectFilter: SelectFilter,
    setFunction: any
  ) => {
    return (
      <Grid item>
        <FormControl>
          <Select
            defaultValue="none"
            labelId={`${selectFilter.label}-select-label`}
            label={selectFilter.label}
            id={`${selectFilter.label}-select`}
            variant="standard"
            style={{ width: "190px" }}
            onChange={(e) => {
              setFunction(e.target.value);
            }}
          >
            <option value="none" disabled>
              {selectFilter.label}
            </option>
            {selectFilter.options.map((option) => (
              <MenuItem value={option.value}>{option.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  function buildQueryParam(key: string, value: string) {
    return value ? `&${key}=${value}` : "";
  }

  function removeCpfCnpjMask(cpfCnpj = "") {
    return cpfCnpj.replace(/[.|\/|-]/g, "");
  }

  async function handleSubmit(event: any, offerId?: string) {
    if (event) {
      event.preventDefault();
    }
    let periodParam = "";
    if (periodFrom || periodTo) {
      // console.log(periodFrom, periodTo);
      periodParam = `createdAt=${periodFrom}to${periodTo}`;
    }
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    const queryParams = `?${periodParam}${buildQueryParam(
      "buyer",
      removeCpfCnpjMask(buyer)
    )}${buildQueryParam("seller", removeCpfCnpjMask(seller))}${buildQueryParam(
      "status",
      situation
    )}${buildQueryParam(
      "offerId",
      (offerId || offerNumber).replace("/", "-")
    )}${buildQueryParam("contractNumber", contractNumber)}`;
    const { data } = await service.get(
      `/contracts/participant/${participantId}/stockbrocker/${stockbrockerId}${queryParams}`
    );
    setContracts(data);
  }

  const cnpjMask = (e: any) => {
    const x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    e.target.value = !x[2]
      ? x[1]
      : x[1] + "." + x[2] + "." + x[3] + "/" + x[4] + (x[5] ? "-" + x[5] : "");
  };

  return (
    <ContractsPanelStyled>
      <div className="contractsContainer">
        <HeaderStyled>
          <div className="contractsHeaderContainer">
            <h3>Filtrar contrato:</h3>
            <div className="filters">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <FormControl>
                      <Input
                        placeholder="Comprador"
                        id="buyer"
                        style={{ width: "190px" }}
                        onKeyUp={(event: any) => {
                          cnpjMask(event);
                          setBuyer(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <Input
                        placeholder="Vendedor"
                        id="seller"
                        style={{ width: "190px" }}
                        onKeyUp={(event: any) => {
                          cnpjMask(event);
                          setSeller(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <MuiPickersUtilsProvider
                        utils={DateLuxonUtils}
                        locale={"pt-BR"}
                      >
                        <KeyboardDatePicker
                          clearable
                          format="dd/MM/yyyy"
                          style={{ width: "190px" }}
                          value={periodFrom}
                          invalidDateMessage=""
                          maxDateMessage=""
                          placeholder="Período de"
                          onChange={(date: any) => {
                            setPeriodFrom(date);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <MuiPickersUtilsProvider
                        utils={DateLuxonUtils}
                        locale={"pt-BR"}
                      >
                        <KeyboardDatePicker
                          clearable
                          format="dd/MM/yyyy"
                          style={{ width: "190px" }}
                          value={periodTo}
                          maxDateMessage=""
                          invalidDateMessage=""
                          placeholder="Até"
                          onChange={(date: any) => {
                            setPeriodTo(date);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  {SelectFilterComponent(
                    {
                      label: "Situação",
                      options: [
                        {
                          description: "Pendente de Assinatura",
                          value: "PENDING",
                        },
                        { description: "Assinado", value: "SIGNED" },
                      ],
                    },
                    setSituation
                  )}
                  <Grid item>
                    <FormControl>
                      <Input
                        placeholder="Nº Oferta"
                        id="offerNumber"
                        style={{ width: "190px" }}
                        onKeyUp={(event: any) => {
                          setOfferNumber(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <Input
                        placeholder="Nº Contrato"
                        id="contractNumber"
                        style={{ width: "190px" }}
                        onKeyUp={(event: any) => {
                          setContractNumber(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <Button type="submit" variant="outlined" color="primary">
                        FILTRAR
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </HeaderStyled>
      </div>
      <div className="contractsContainer">
        <ContractsList contracts={contracts}></ContractsList>
      </div>
    </ContractsPanelStyled>
  );
};

export default Contracts;
