import { Radio } from "@material-ui/core";
import { useState } from "react";
import RadioGroupStyled from "./radioGroup.styled";

type props = {
  label: string;
  value: string;
  options: {
    code: string;
    name: string;
  }[];
  onChange?: (v: string) => void;
  valid: boolean;
  style?: React.CSSProperties;
};

const RadioGroup: React.FC<props> = ({
  label,
  value,
  options,
  valid,
  onChange,
  style,
}) => {
  const [checkedItem, setCheckedItem] = useState(value);

  function handleChange(isChecked: boolean, code: string) {
    let checked = checkedItem;

    if (isChecked) {
      checked = code;
      setCheckedItem(checked);
    }

    if (onChange) {
      onChange(checked);
    }
  }

  return (
    <RadioGroupStyled>
      <div
        className={"checkgroupcontainer" + (valid ? "" : " checkgrouperror")}
        style={{ ...(style || {}) }}
      >
        <div className="label">{label}</div>
        <div className="checklist">
          {options.map((option) => {
            return (
              <div className="checkitem" key={option.code}>
                <div className="checkBox">
                  <Radio
                    defaultChecked={value === option.code}
                    checked={value === option.code}
                    color="primary"
                    onChange={(e) => {
                      handleChange(e.target.checked, option.code);
                    }}
                  />
                </div>
                <div className="checklabel">
                  <span>{option.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </RadioGroupStyled>
  );
};

export default RadioGroup;
