import OffersHistoryPanelStyled from "./offersHistoryPanel.styled";
import OffersHistoryList from "./offersHistoryList";
import { Grid, MenuItem, Select, FormControl, Input } from "@material-ui/core";
import HeaderStyled from "./header.styled";
import { Button } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { createApiService } from "service";
import { useSelector } from "react-redux";
import { AppState } from "store/app/reducer";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateLuxonUtils from "@date-io/luxon";

type SelectFilter = {
  label: string;
  options: any[];
};

const OffersHistory: React.FC<any> = () => {
  const [offers, setOffers] = useState([]);
  const [periodFrom, setPeriodFrom] = useState(null);
  const [periodTo, setPeriodTo] = useState(null);
  const offerNumber = useRef("");
  const [type, setType] = useState("");
  const [situation, setSituation] = useState("");
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();

  useEffect(() => {
    getOffersHistory();
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  async function getOffersHistory() {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    if (participantId && stockbrockerId) {
      const { data } = await service.get(
        `/offers/participant/${participantId}/stockbrocker/${stockbrockerId}`
      );
      setOffers(data);
    }
  }

  const SelectFilterComponent = (
    selectFilter: SelectFilter,
    setFunction: any
  ) => {
    return (
      <Grid item>
        <FormControl>
          <Select
            defaultValue="none"
            labelId={`${selectFilter.label}-select-label`}
            label={selectFilter.label}
            id={`${selectFilter.label}-select`}
            variant="standard"
            style={{ width: "190px" }}
            onChange={(e) => {
              setFunction(e.target.value);
            }}
          >
            <option value="none" disabled>
              {selectFilter.label}
            </option>
            {selectFilter.options.map((option) => (
              <MenuItem value={option.value}>{option.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  function buildQueryParam(key: string, value: string) {
    return value ? `&${key}=${value}` : "";
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    let periodParam = "";
    if (periodFrom || periodTo) {
      periodParam = `createdAt=${periodFrom}to${periodTo}`;
    }
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    const queryParams = `?${periodParam}${buildQueryParam(
      "type",
      type
    )}${buildQueryParam("offerStatus", situation)}${buildQueryParam(
      "offerNumber",
      offerNumber.current
    )}`;
    const { data } = await service.get(
      `/offers/participant/${participantId}/stockbrocker/${stockbrockerId}${queryParams}`
    );
    setOffers(data);
  }

  return (
    <OffersHistoryPanelStyled>
      <div className="offersHistoryContainer">
        <HeaderStyled>
          <div className="offersHistoryHeaderContainer">
            <h3>Filtrar Oferta:</h3>
            <div className="filters">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>
                    <FormControl>
                      <MuiPickersUtilsProvider
                        utils={DateLuxonUtils}
                        locale={"pt-BR"}
                      >
                        <KeyboardDatePicker
                          clearable
                          format="dd/MM/yyyy"
                          style={{ width: "190px" }}
                          value={periodFrom}
                          invalidDateMessage=""
                          maxDateMessage=""
                          placeholder="Período de"
                          onChange={(date: any) => {
                            setPeriodFrom(date);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <FormControl>
                    <MuiPickersUtilsProvider
                      utils={DateLuxonUtils}
                      locale={"pt-BR"}
                    >
                      <KeyboardDatePicker
                        clearable
                        format="dd/MM/yyyy"
                        style={{ width: "190px" }}
                        value={periodTo}
                        maxDateMessage=""
                        invalidDateMessage=""
                        placeholder="Até"
                        onChange={(date: any) => {
                          setPeriodTo(date);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  {SelectFilterComponent(
                    {
                      label: "Tipo",
                      options: [
                        { description: "Venda", value: "SALE_OFFER" },
                        { description: "Compra", value: "PURCHASE_OFFER" },
                      ],
                    },
                    setType
                  )}
                  {SelectFilterComponent(
                    {
                      label: "Situação",
                      options: [
                        { description: "Aberta", value: "CREATED" },
                        { description: "Negociada", value: "FINISHED" },
                      ],
                    },
                    setSituation
                  )}
                  <FormControl>
                    <Input
                      placeholder="Nº Oferta"
                      id="offerNumber"
                      style={{ width: "190px" }}
                      onKeyUp={(event: any) => {
                        offerNumber.current = event.currentTarget.value;
                      }}
                    />
                  </FormControl>
                  <Grid item>
                    <FormControl>
                      <Button type="submit" variant="outlined" color="primary">
                        FILTRAR
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </HeaderStyled>
      </div>
      <div className="offersHistoryContainer">
        <OffersHistoryList offers={offers}></OffersHistoryList>
      </div>
    </OffersHistoryPanelStyled>
  );
};

export default OffersHistory;
