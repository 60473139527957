import { useSelector } from "react-redux";
import { AppState } from "store/app/reducer";
import ClockStyled from "./index.styled";
import { DateTime } from "luxon";

const Clock: React.FC = () => {
  const appStore: AppState = useSelector((state: any) => state.app);

  return (
    <ClockStyled>
      <div className="datetime-container">
        <span className="datetime">
          {DateTime.fromMillis(
            appStore.systemTime +
              (appStore.currentLocalTime - appStore.systemTimeSetAt)
          ).toFormat("dd/MM/yyyy - HH:mm")}
        </span>
        <span className="location"> (Horário de Brasília)</span>
      </div>
    </ClockStyled>
  );
};

export default Clock;
