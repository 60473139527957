import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import HomeBrokerStyled from "./homeBroker.styled";
import { VariantType } from "theme/variants";
import logo from "assets/logo-bolsa.png";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "store/auth/reducer";
import Clock from "components/clock";
import { withRouter } from "react-router-dom";
import { AppState } from "store/app/reducer";
import { appActions } from "store/app/actions";
import { authActions } from "store/auth/actions";
import { useAction } from "store";
import ContextPicker from "components/contextPicker";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { MenuItem, Menu } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: (theme as unknown as VariantType).palette.BBMprimitiveWhite
      .main,
    color: (theme as unknown as VariantType).palette.BBMprimitiveWhite
      .contrastText,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: (theme.palette as any).BBMprimary.main,
    width: drawerWidth,
    color: (theme.palette as any).BBMprimary.contrastText,
    border: "unset",
  },
  drawerHeader: {
    backgroundColor: (theme as unknown as VariantType).palette.BBMprimitiveGrey
      .main,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  image: {
    width: "50%",
    height: "50%",
  
  }
}));

const Layout: React.FC<any> = function PersistentDrawerLeft({
  history,
  children,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const authStore: AuthState = useSelector((state: any) => state.auth);

  const appStore: AppState = useSelector((state: any) => state.app);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const appAction = useAction(appActions());
  const authAction = useAction(authActions());

  useEffect(() => {
    const unlisten = history.listen((location: any, action: any) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
      dispatch(appAction.setCurrentPage(location.pathname));
    });
    return () => {
      unlisten();
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [showUserMenu, setShowUserMenu] = useState(false);

  function handleShowUserOptionsClick(e: any) {
    setAnchorEl(e.currentTarget);
    setShowUserMenu(true);
  }

  function logout() {
    dispatch(authAction.logout());
  }

  return (
    <HomeBrokerStyled>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <div className="headerContainer">
              <Typography variant="h6" noWrap>
                {appStore.currentPage.title}
              </Typography>
              <div className="rightHeaderContent">
                <div style={{ marginRight: "20px" }}>
                  <ContextPicker />
                </div>
                <Clock />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img src={logo} className={classes.image} alt="logo" />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List style={{ marginTop: "10px" }}>
            <ListItem
              button
              key={"Painel de ofertas"}
              className={
                appStore.currentPage.highlightMenu === 1
                  ? "menuItemActive"
                  : undefined
              }
              onClick={() => history.push("/ofertas")}
            >
              <ListItemText primary={"Painel de ofertas"} />
            </ListItem>
            <ListItem
              disabled={
                appStore.selectedParticipant !== null &&
                appStore.selectedParticipant.id === "none"
              }
              button
              key={"Histórico de Ofertas"}
              className={
                appStore.currentPage.highlightMenu === 2
                  ? "menuItemActive"
                  : undefined
              }
              onClick={() => history.push("/historico-ofertas")}
            >
              <ListItemText primary={"Histórico de Ofertas"} />
            </ListItem>
            <ListItem
              disabled={
                appStore.selectedParticipant !== null &&
                appStore.selectedParticipant.id === "none"
              }
              button
              key={"Contratos"}
              className={
                appStore.currentPage.highlightMenu === 3
                  ? "menuItemActive"
                  : undefined
              }
              onClick={() => history.push("/contratos")}
            >
              <ListItemText primary={"Contratos"} />
            </ListItem>
            {/* <ListItem
              button
              key={"Relatórios"}
              className={
                appStore.currentPage.highlightMenu === 4
                  ? "menuItemActive"
                  : undefined
              }
              onClick={() => history.push("/relatorios")}
            >
              <ListItemText primary={"Relatórios"} />
            </ListItem> */}
            { authStore.user.isAdmin === true &&
              <ListItem
                button
                key={"Produtos"}
                className={
                  appStore.currentPage.highlightMenu === 5
                    ? "menuItemActive"
                    : undefined
                }
                onClick={() => history.push("/produtos")}
              >
                <ListItemText primary={"Produtos"} />
              </ListItem>
            }
            { authStore.user.isAdmin === true &&
              <ListItem
                button
                key={"Participantes e Produtos"}
                className={
                  appStore.currentPage.highlightMenu === 6
                    ? "menuItemActive"
                    : undefined
                }
                onClick={() => history.push("/associar-produtos")}
              >
                <ListItemText primary={"Participantes e Produtos"} />
              </ListItem>
            }
          </List>
          <div className="userInfo">
            <Avatar
              src={(authStore.user.photo as string) || "/not-found-image"}
            />
            <span className="name">{authStore.user.name}</span>
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  handleShowUserOptionsClick(e);
                }}
              >
                <MoreHorizIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div
            className={classes.drawerHeader + " " + "drawerHeaderHeightSet"}
          />
          <div style={{ marginTop: "17px" }}></div>
          {children}
        </main>
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={showUserMenu}
        onClose={() => {
          setShowUserMenu(false);
        }}
        PaperProps={{
          style: {
            width: "20ch",
            boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {/* <MenuItem
          // key={option}
          // selected={option === "Pyxis"}
          // onClick={handleClose}
          >
            Executar
          </MenuItem> */}
        <MenuItem
          onClick={() => {
            logout();
            setShowUserMenu(false);
          }}
        >
          Sair
        </MenuItem>
      </Menu>
    </HomeBrokerStyled>
  );
};

export default withRouter(Layout);
