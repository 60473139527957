import styled from "styled-components";

const header: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
}

export default styled(header)`
.productDetailsHeaderContainer {
  padding: 20px;
}
`