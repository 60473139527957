import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ContractsListStyled from "./contractsList.styled";
import { useHistory } from "react-router";
import Util from "../../utils/util";
import { formatCurrency, formatProductUnit } from "util/format";

const ContractsList: React.FC<any> = ({ contracts }) => {
  const history = useHistory();

  const SIGNED_STATUS: any = {
    SIGNED: { ptBr: "Assinado", class: "signed-text" },
    PENDING: { ptBr: "Pendente de Assinatura", class: "unsigned-text" },
  };

  return (
    <ContractsListStyled>
      <div className="contractsListContainer">
        <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
          <Table
            // className={classes.table}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Número</TableCell>
                <TableCell align="left">Emissão</TableCell>
                <TableCell align="left">Comprador</TableCell>
                <TableCell align="left">Corretora</TableCell>
                <TableCell align="left">Vendedor</TableCell>
                <TableCell align="left">Corretora</TableCell>
                <TableCell align="left">Produto</TableCell>
                <TableCell align="left">Quantidade</TableCell>
                <TableCell align="left">Preço/Volume</TableCell>
                <TableCell align="left">Valor Total</TableCell>
                <TableCell align="left">Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.map((contract: any) => (
                <TableRow
                  key={contract.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/contratos/${contract.id}`)}
                >
                  <TableCell align="left">{contract.contractNumber}</TableCell>
                  <TableCell align="left">
                    {Util.formatDate(contract.createdAt, "dd/MM/yy")}
                  </TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(contract.buyer || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(contract.buyerStockbroker || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(contract.seller || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {Util.cnpjMask(contract.sellerStockbroker || "") || "-"}
                  </TableCell>
                  <TableCell align="left">
                    {contract.offer?.product.name}
                  </TableCell>
                  <TableCell align="left">
                    {contract.offer?.quantity}
                    {formatProductUnit(contract.offer?.product.unit)}
                  </TableCell>
                  {!contract.offer?.valueToBeDefined && (
                    <TableCell align="left">
                      {formatCurrency(contract.offer?.bid?.value / 100)} /{" "}
                      {formatProductUnit(contract.offer?.product.unit)}
                    </TableCell>
                  )}
                  {contract.offer?.valueToBeDefined && (
                    <TableCell align="left">A FIXAR</TableCell>
                  )}
                  {!contract.offer?.valueToBeDefined && (
                    <TableCell align="left">
                      {formatCurrency(contract.totalPrice)}
                    </TableCell>
                  )}
                  {contract.offer?.valueToBeDefined && (
                    <TableCell align="left">-</TableCell>
                  )}
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">
                    <span className={SIGNED_STATUS[contract.status].class}>
                      {SIGNED_STATUS[contract.status].ptBr}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ContractsListStyled>
  );
};

export default ContractsList;
