import OfferHistoryDetailsStyled from "./offerHistoryDetailsPanel.styled";
import GenericInfos from "./genericInfos";
import Header from "./header";
import { useEffect } from "react";
import { createApiService } from "service";
import { useState } from "react";
import LogsHistory from "./logsHistory";
import { AppState } from "store/app/reducer";
import { useSelector } from "react-redux";

const OfferHistoryDetails: React.FC<any> = (...props) => {
  const [offerHistory, setOfferHistory] = useState(null);
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();

  useEffect(() => {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    async function getOfferHistory(id: number) {
      if (participantId && stockbrockerId) {
        const { data } = await service.get(
          `/offers/${id}?participant=${participantId}&stockbroker=${stockbrockerId}&includelogs=true`
        );
        setOfferHistory(data);
      }
    }
    getOfferHistory(props[0].match.params.id);
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  return (
    <OfferHistoryDetailsStyled>
      <div className="offerHistoryDetailsContainer">
        <Header offerHistory={offerHistory}></Header>
      </div>
      <GenericInfos offerHistory={offerHistory}></GenericInfos>
      <LogsHistory offerHistory={offerHistory}></LogsHistory>
    </OfferHistoryDetailsStyled>
  );
};

export default OfferHistoryDetails;
