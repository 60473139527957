import { useRef, useState } from "react";

const useForm = () => {
  const vRef = useRef({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event: { name: string; value: any }) => {
    const auxValues: any = { ...vRef.current };
    auxValues[event.name] = event.value;
    vRef.current = auxValues;
  };

  const handleSubmit = (callback: any) => (event: any) => {
    event.preventDefault();
    setLoading(true);
    callback();
    setLoading(false);
  };

  return [{ values: vRef, loading }, handleChange, handleSubmit];
};

export default useForm;
