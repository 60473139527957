import ProductDetailsStyled from "./productDetailsPanel.styled";
import Header from "./header";
import { useEffect } from "react";
import { createApiService } from "service";
import { useState } from "react";
import VersionsHistory from "./versionsHistory";
import { AppState } from "store/app/reducer";
import { useSelector } from "react-redux";

const ProductDetails: React.FC<any> = (...props) => {
  const [products, setProducts] = useState([]);
  const AppStore: AppState = useSelector((state: any) => state.app);
  const service = createApiService();

  useEffect(() => {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    async function getProduct(baseProductId: number) {
      if (participantId && stockbrockerId) {
        const { data } = await service.get(
          `/products/find-base-product/${baseProductId}`
        );
        setProducts(data);
      }
    }
    getProduct(props[0].match.params.baseProductId);
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  return products.length ? (
    <ProductDetailsStyled>
      <div className="productDetailsContainer">
        <Header product={products[0]}></Header>
      </div>
      <VersionsHistory products={products}></VersionsHistory>
    </ProductDetailsStyled>
  ) : <></>
};

export default ProductDetails;
