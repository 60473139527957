import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import DocumentsSelectorStyled from "./documentsSelector.styled";
import CancelIcon from "@material-ui/icons/Cancel";
import { createApiService } from "service";
import GetAppIcon from "@material-ui/icons/GetApp";
import { DateTime } from "luxon";
const cloudStorageSignedResumer = require("cloud-storage-resume-signed");

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export type documentsSelected = {
  name: string;
  GCSfilename: string;
  timeselected: Date;
  downloadURL: string;
  uploadFinished: boolean;
  uploadId?: number;
}[];

type props = {
  value: documentsSelected;
  onChange?: (v: documentsSelected) => void;
  hasPreloadedDocuments: boolean;
};

const DocumentsSelector: React.FC<props> = ({
  value,
  onChange,
  hasPreloadedDocuments,
}) => {
  const fileInputRef = useRef<any | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState([...value]);
  const uploadNextId = useRef(1);
  const currentUploads = useRef<{
    [key: number]: { percent: number };
  }>({});

  currentUploads.current[0] = { percent: 100 };

  useEffect(() => {
    setSelectedDocuments([...value]);
  }, [hasPreloadedDocuments]);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function getNextUploadId() {
    return uploadNextId.current++;
  }

  async function generateGCSURL(fileType: string, fileName: string) {
    const service = createApiService();
    const { data } = await service.get(
      `/offers/documents/signed-document-upload-url?fileType=${fileType}&fileNameDownload=${fileName}`
    );
    return data;
  }

  const handleFinishedUpload = useCallback(
    (uploadId: number) => {
      setSelectedDocuments((docs) => {
        const documents = [...docs];
        const finishedDocs = documents.filter((d) => d.uploadId === uploadId);
        if (finishedDocs.length > 0) {
          finishedDocs[0].uploadFinished = true;
        }
        console.log(docs);
        if (onChange) {
          onChange(docs);
        }
        return documents;
      });
    },
    [selectedDocuments]
  );

  async function handleChange(e: any) {
    if (e.target.files.length === 0) {
      return;
    }
    const uploadId = getNextUploadId();
    let gcsURL = "";
    let downloadUrl = "";
    let gcsFilename = "";

    try {
      const {
        url: gcsURL_,
        downloadUrl: downloadUrl_,
        filename: gcsFilename_,
      } = await generateGCSURL(e.target.files[0].type, e.target.files[0].name);
      gcsURL = gcsURL_;
      downloadUrl = downloadUrl_;
      gcsFilename = gcsFilename_;
    } catch (e: any) {
      if (e.response.status) {
        switch (e.response.data.code) {
          case 1001:
            window.alert("Formato não aceito");
            break;
          default:
            window.alert("Erro interno");
        }
      } else {
        window.alert("Verifique sua conexão");
      }

      return;
    }
    const uploader = new cloudStorageSignedResumer.Uploader({
      file: e.target.files[0],
      signedURI: gcsURL,
    });

    currentUploads.current[uploadId] = { percent: 0 };
    const fileName = e.target.files[0].name;

    const newDocuments = [
      ...selectedDocuments,
      {
        downloadURL: downloadUrl,
        name: fileName,
        timeselected: new Date(),
        uploadFinished: false,
        uploadId: uploadId,
        GCSfilename: gcsFilename,
      },
    ];
    setSelectedDocuments(newDocuments);

    if (onChange) {
      onChange(newDocuments);
    }

    uploader.on("progress", (progress: number) => {
      currentUploads.current[uploadId] = {
        percent: Math.round(progress * 100),
      };
      forceUpdate();
    });

    uploader.on("uploadfinished", () => {
      currentUploads.current[uploadId] = { percent: 100 };
      handleFinishedUpload(uploadId);
      forceUpdate();
    });

    uploader.on("uploadstarted", (info: any) => {
      console.log(info);
    });

    uploader.upload();

    // console.log(e.target.files[0].name);
  }

  function addFile() {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  }

  function removeDocument(index: number) {
    const newDocuments = [
      ...selectedDocuments.slice(0, index),
      ...selectedDocuments.slice(index + 1, selectedDocuments.length),
    ];

    setSelectedDocuments(newDocuments);

    if (onChange) {
      onChange(newDocuments);
    }
  }

  return (
    <DocumentsSelectorStyled>
      <div className="docTypesMessage">
        <b>
          Podem ser anexados documentos importantes para auxiliar no fechamento
          do negócio:
        </b>
        <br />
        <b>Exemplo: </b>
        Certificado de Classificação, comprovante de selo social, autorização
        para comercialização de combustíveis da ANP, certidões e outros
      </div>
      <div className="buttonRow">
        <input
          ref={fileInputRef}
          id="myfile"
          type="file"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <Button
          style={{ marginRight: "20px" }}
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addFile();
            return false;
          }}
          // disabled={!canIncludeDelivery()}
        >
          Anexar documento
        </Button>
        <span className="fileformats">
          <i>
            (Formatos aceitos: .txt .doc .docx .pdf .xls .xlsx .png .jpeg .jpg)
          </i>
        </span>
      </div>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="tabela de entregas"
          style={{ marginTop: "30px" }}
        >
          <TableBody>
            {selectedDocuments.map((document, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">
                    {DateTime.fromJSDate(document.timeselected).toFormat(
                      "dd/MM/yyyy HH:mm'h'"
                    )}
                  </TableCell>
                  <TableCell align="left">{document.name}</TableCell>
                  {/* <TableCell align="left">{delivery.value} m³</TableCell> */}
                  <TableCell
                    align="right"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexDirection: "unset",
                    }}
                  >
                    <div className="iconsContainer" style={{ display: "flex" }}>
                      <div
                        className="downloadButtonContainer"
                        style={{ marginRight: "20px" }}
                      >
                        {currentUploads.current[document.uploadId as number]
                          .percent < 100 && (
                          <CircularProgressWithLabel
                            value={
                              currentUploads.current[
                                document.uploadId as number
                              ].percent
                            }
                          />
                        )}
                        {currentUploads.current[document.uploadId as number]
                          .percent === 100 && (
                          <a href={document.downloadURL}>
                            <GetAppIcon
                              style={{
                                cursor: "pointer",
                                color: "black",
                              }}
                            />
                          </a>
                        )}
                      </div>
                      <CancelIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeDocument(i);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DocumentsSelectorStyled>
  );
};

export default DocumentsSelector;
