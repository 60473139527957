import styled from "styled-components";

const metadata: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(metadata)`
  .inputsContainer {
    display: flex;
    align-items: flex-end;
  }
  table {
    width: 70%;
  }
`;
