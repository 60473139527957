import styled from "styled-components"
import { VariantType } from "theme/variants"

const deliverySchedule: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(deliverySchedule)`
  .deliverySchedule {
    box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
    background-color: white;
    padding: 20px;

    .MuiButton-root {
      border: 2px solid
        ${(props) => (props.theme as VariantType).palette.BBMprimary2.main};
      color: ${(props) =>
        (props.theme as VariantType).palette.BBMprimary2.main};
      font-weight: bold;
    }
  }
`