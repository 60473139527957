import styled from "styled-components";
import { VariantType } from "theme/variants";

const header: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
}

export default styled(header)`
.contractDetailsHeaderContainer {
  padding: 20px;
}
`