import React, { useState } from "react";
import "./App.css";
import { ThemeProvider } from "styled-components/macro";
import Routes from "./routes";
import createTheme from "./theme";
import { create } from "jss";
import { SnackbarProvider } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/auth/actions";
import { appActions } from "store/app/actions";
import { useAction } from "store";
import useSocket from "service/socket";

import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { useEffect } from "react";
import { AuthState } from "store/auth/reducer";
import { AppState } from "store/app/reducer";
import { createAppService } from "store/app/service";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  // const theme = useSelector((state: AppStateType) => state.themeReducer);
  const theme = { currentTheme: "BBM" };

  const AuthStore: AuthState = useSelector((state: any) => state.auth);
  // const AppStore: AppState = useSelector((state: any) => state.app);
  const selectedParticipant: { name: string; id: number } | null = useSelector(
    (state: any) => state.app.selectedParticipant
  );
  const selectedStockBroker: { name: string; id: number } | null = useSelector(
    (state: any) => state.app.selectedStockBroker
  );
  const systemTime = useSelector((state: any) => state.app.systemTime);

  const dispatch = useDispatch();
  const authAction = useAction(authActions());
  const appAction = useAction(appActions());
  const [socketAuthenticated, setSocketAuthenticated] = useState(false);
  const { auth } = useSocket();

  function authWS() {
    if (
      AuthStore.accessToken !== null &&
      selectedParticipant !== null &&
      selectedStockBroker !== null
    ) {
      auth(
        {
          token: AuthStore.accessToken,
          participant: selectedParticipant.id + "",
          broker: selectedStockBroker.id + "",
        },
        authWS
      );
    }
  }

  useEffect(() => {
    authWS();
    setSocketAuthenticated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthStore.accessToken, selectedParticipant, selectedStockBroker]);

  useEffect(() => {
    dispatch(authAction.checkUserAuthKeyCloak());
    if (systemTime === 0) {
      const appService = createAppService();
      appService.getTime().then((data) => {
        dispatch(appAction.setClockBaseTime(data.data.time));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <StylesProvider jss={jss}>
        <SnackbarProvider maxSnack={3}>
          <div
            className="appWall"
            style={{
              position: "fixed",
              left: "0",
              top: "0",
              width: "100vw",
              height: "100vh",
              backgroundColor: "white",
              zIndex: 2000,
              display: AuthStore.accessToken !== null ? "none" : "block",
            }}
          ></div>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              {/* <MuiPickersUtilsProvider> */}
              <Routes />
              {/* </MuiPickersUtilsProvider> */}
            </ThemeProvider>
          </MuiThemeProvider>
        </SnackbarProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
