import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { createReducerManager } from "store/reduceManager.ts";
import authReducer from "store/auth/reducer";
import appReducer from "store/app/reducer";
import offersReducer from "store/offers/reducer";
import { useRef } from "react";

export const staticReducers = {
  app: appReducer,
  auth: authReducer,
  offers: offersReducer,
};

export function useAction<TAction>(action: TAction): TAction;
export function useAction(action: any): any {
  return useRef(action).current;
}

export const reducerManager = createReducerManager(staticReducers);
export const store = createStore(
  reducerManager.reduce,
  composeWithDevTools(applyMiddleware(thunk))
);
