import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import SelectCustomerStyled from "./selectCustomer.styled";
import CancelIcon from "@material-ui/icons/Cancel";
import MaskedInput from "react-text-mask";
import { createApiService } from "service";

type participant = {
  companyCNPJ: string;
};

function CNPJmask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
      guide={false}
    />
  );
}

type props = {
  value: participant[]; // array de CNPJs
  onChange?: (v: participant[]) => void;
};

const SelectCustomer: React.FC<props> = ({ value, onChange }) => {
  const [CNPJ, setCNPJ] = useState("");
  const [searchingCNPJ, setSearchingCNPJ] = useState(false);
  const [customers, setCustomers] = useState([...value]);
  const razaoSocialCache = useRef({});
  const companyIdCache = useRef({});

  const service = createApiService();

  useEffect(() => {
    setCustomers(value);
  }, [value]);

  async function addCustomer() {
    if (!customers.some((c) => c.companyCNPJ === CNPJ)) {
      // setSearchingCNPJ(true); // toDo fazer requisição web para buscar razão social a partir do CNPJ
      // const { data } = await service.get(
      //   `/participants?cnpj=${CNPJ.replace(/-/g, "")
      //     .replace(/\./g, "")
      //     .replace(/\//g, "")}`
      // );
      // if (data.length === 0) {
      //   alert("Participante não encontrado no sistema");
      //   setSearchingCNPJ(false);
      //   return;
      // }
      // const [{ companyName, id }] = data;

      // razaoSocialCache.current = {
      //   ...razaoSocialCache.current,
      //   [CNPJ]: companyName,
      // };
      // companyIdCache.current = {
      //   ...companyIdCache.current,
      //   [CNPJ]: id,
      // };
      const newCustomers = [
        ...customers,
        {
          companyCNPJ: CNPJ,
        },
      ];
      setCustomers(newCustomers);
      setCNPJ(" ");
      setSearchingCNPJ(false);
      if (onChange) {
        onChange(newCustomers);
      }
    }
  }

  function canIncludeCustomer() {
    return (
      !searchingCNPJ &&
      CNPJ.match(/\d\d\.\d\d\d\.\d\d\d\/\d\d\d\d-\d\d/) !== null
    );
  }

  function removeCustomer(index: number) {
    const newCustomers = [
      ...customers.slice(0, index),
      ...customers.slice(index + 1, customers.length),
    ];

    setCustomers(newCustomers);
    if (onChange) {
      onChange(newCustomers);
    }
  }

  return (
    <SelectCustomerStyled>
      <div className="inputsContainer">
        <FormControl>
          <InputLabel htmlFor="deliverydateinput">CNPJ</InputLabel>
          <Input
            value={CNPJ}
            onChange={(e) => {
              setCNPJ(e.target.value);
            }}
            id="deliverydateinput"
            inputComponent={CNPJmask}
            onKeyDown={(e) => {
              if (
                e.code &&
                e.code.toLowerCase() === "enter" &&
                canIncludeCustomer()
              ) {
                addCustomer();
              }
            }}
          />
        </FormControl>
        <Button
          style={{ marginLeft: "20px", height: "100%" }}
          variant="outlined"
          color="primary"
          onClick={() => {
            addCustomer();
          }}
          disabled={!canIncludeCustomer()}
        >
          Incluir
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="clientes selecionados"
          style={{ marginTop: "30px" }}
        >
          <TableBody>
            {customers.map((customer, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">{customer.companyCNPJ}</TableCell>
                  {/* <TableCell align="left">
                    {(razaoSocialCache.current as any)[customer.companyCNPJ]}
                  </TableCell> */}
                  <TableCell align="right">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CancelIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeCustomer(i);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </SelectCustomerStyled>
  );
};

export default SelectCustomer;
