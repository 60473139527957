import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Select,
  MenuItem,
  TableHead,
} from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import MetadataStyled from "./metadata.styled";
import CancelIcon from "@material-ui/icons/Cancel";
import { createApiService } from "service";

const Metadata: React.FC<any> = ({ metadata, setMetadata }) => {

  const [CNPJ, setCNPJ] = useState("");
  
  
  const [label, setLabel] = useState('');
  const [contractParam, setContractParam] = useState('');
  const [fieldType, setFieldType] = useState('string');
  const [isRequired, setIsRequired] = useState(false);
  const [maxLength, setMaxLength] = useState(0);

  useEffect(() => {
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    let automaticContractParam = label.slice(0, 30);
    automaticContractParam = `{{${automaticContractParam.toLocaleUpperCase().normalize('NFD').replace(regex, "").replace(/[\u0300-\u036f]/g, "")}}}`;
    setContractParam(automaticContractParam);
  }, [label]);

  const service = createApiService();

  async function addMetadata() {
    const newMetadata = [
      ...metadata,
      {
        label,
        fromToContractTemplate: contractParam,
        fieldType,
        required: isRequired,
        maxLength
      }
    ];
    setMetadata(newMetadata);
  }
  

  function removeMetadata(index: number) {
    const newMetadata = [
      ...metadata.slice(0, index),
      ...metadata.slice(index + 1, metadata.length),
    ];

    setMetadata(newMetadata);
  }

  type FieldType = {
    key: string;
    description: string;
  }

  const fieldTypes: FieldType[] = [
    {
      key: 'string',
      description: 'Texto'
    },
    {
      key: 'number',
      description: 'Número'
    },
    {
      key: 'boolean',
      description: 'Lógico'
    },
  ];

  const getFieldType = (key: string): FieldType | undefined => {
    return fieldTypes.find(ft => ft.key === key);
  }

  const isButtonIncludeEnabled = (): boolean => {
    if (fieldType === 'string') {
      return (
        label && fieldType && contractParam && 
        isRequired != undefined && maxLength != undefined ? true : false
      );
    }
    return label && fieldType && contractParam && isRequired != undefined ? true : false;
  }

  return (
    <MetadataStyled>
      <div className="inputsContainer">
        <FormControl style={{ marginLeft: "20px", width: "40%"}}>
          <InputLabel htmlFor="label">Descrição</InputLabel>
          <Input
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            id="label"
          />
        </FormControl>
        <FormControl style={{ marginLeft: "20px", width: "10%" }}>
          <InputLabel htmlFor="fieldType">Tipo do Campo</InputLabel>
          <Select
            labelId="fieldType"
            id={"fieldType"}
            onChange={(e: any) => {
              setFieldType(e.target.value);
            }}
            value={fieldType}
          >
            {fieldTypes.map((f, i) => (
              <MenuItem value={f.key}>{f.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl style={{ marginLeft: "20px", width: "20%" }}>
          <InputLabel htmlFor="contractParam">Parâmetro no Contrato</InputLabel>
          <Input
            value={contractParam}
            onChange={(e) => {
              setContractParam(e.target.value);
            }}
            id="contractParam"
          />
        </FormControl> */}
        <FormControl style={{ marginLeft: "20px", width: "10%" }}>
          <InputLabel htmlFor="isRequired">É Obrigatório?</InputLabel>
          <Select
            labelId="isRequired"
            id={"isRequired"}
            onChange={(e: any) => {
              setIsRequired(e.target.value == 'true');
            }}
            value={isRequired}
          >
            <MenuItem value={'false'}>Não</MenuItem>
            <MenuItem value={'true'}>Sim</MenuItem>
          </Select>
        </FormControl>
        {
          fieldType === 'string' ? (
            <FormControl style={{ marginLeft: "20px", width: "10%" }}>
              <InputLabel htmlFor="maxLength">Tamanho Máximo</InputLabel>
              <Input
                type="number"
                value={maxLength}
                inputProps={{
                  step: "1"
                }}
                onChange={(e) => setMaxLength(parseInt(e.target.value, 10))}
                id="maxLength"
              />
            </FormControl>
          )
          : ''
        }
        <Button
          style={{ marginLeft: "20px", height: "100%" }}
          variant="outlined"
          color="primary"
          onClick={() => {
            addMetadata();
            setLabel('');
            setContractParam('');
            setFieldType('string');
            setIsRequired(false);
            setMaxLength(0);
          }}
          disabled={!isButtonIncludeEnabled()}
        >
          Incluir
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="metadados"
          style={{ marginTop: "30px" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Descrição</TableCell>
              <TableCell align="left">Tipo do Campo</TableCell>
              <TableCell align="left">Parâmetro no Contrato</TableCell>
              <TableCell align="left">Obrigatório</TableCell>
              <TableCell align="left">Tamanho Máximo</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metadata.map((m: any, i: number) => {
              return (
                <TableRow key={i}>
                  <TableCell align="left">{m.label}</TableCell>
                  <TableCell align="left">{getFieldType(m.fieldType)?.description}</TableCell>
                  <TableCell align="left">{m.fromToContractTemplate}</TableCell>
                  <TableCell align="left">{m.required === true ? 'Sim' : 'Não'}</TableCell>
                  <TableCell align="left">{m.fieldType === 'string' ? m.maxLength : '-'}</TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CancelIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeMetadata(i);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MetadataStyled>
  );
};

export default Metadata;
