import { actionTypes } from "./actions";

export type AppState = {
  systemTime: number;
  systemTimeSetAt: number;
  currentLocalTime: number;
  currentPage: {
    title: string;
    highlightMenu: number;
  };
  selectedStockBroker: {
    id: string;
    name: string;
  } | null;
  selectedParticipant: {
    id: string;
    name: string;
    allowedAtBroker: boolean;
  } | null;
};

const initialState: AppState = {
  systemTime: 0,
  systemTimeSetAt: 0,
  currentLocalTime: 0,
  currentPage: {
    title: "Painel de ofertas",
    highlightMenu: 1,
  },
  selectedStockBroker: null,
  selectedParticipant: null,
};

type actionType = {
  type: actionTypes;
  payload: any;
};

const reducer = (state: AppState = initialState, action: actionType) => {
  switch (action.type) {
    case actionTypes.SET_CLOCK_BASE_TIME:
      return {
        ...state,
        systemTime: action.payload.time,
        systemTimeSetAt: action.payload.localTime,
        currentLocalTime: action.payload.localTime,
      };
    case actionTypes.UPDATE_CURRENT_TIME:
      return { ...state, currentLocalTime: action.payload.localTime };
    case actionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case actionTypes.SET_CONTEXT_STOCKBROKER_PARTICIPANT:
      return {
        ...state,
        selectedStockBroker: {
          id: action.payload.stockbrokerId,
          name: action.payload.stockbrokerName,
        },
        selectedParticipant: {
          id: action.payload.participantId,
          name: action.payload.participantName,
          allowedAtBroker: action.payload.participantIsAllowedAtBroker,
        },
      };
    default:
      return state;
  }
};

export default reducer;
