import RegisterSellOfferStyled from "./registerOffer.styled";
import FormAccordion from "components/formAccordion";
import FormGroupRender from "components/formAccordion/formGroupRender";
import { formItemGroup } from "models/formItem.model";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  Radio,
  Checkbox,
} from "@material-ui/core";
import useForm from "components/form";
import {
  ReactElement,
  ReactFragment,
  useCallback,
  useReducer,
  useRef,
  useState,
} from "react";
import CheckGroup from "components/inputs/checkGroup";
import RadioGroup from "components/inputs/radioGroup";
import DeliverySchedule, {
  deliverySchedules,
} from "components/inputs/deliverySchedule";
import SelectCustomers from "components/inputs/selectCustomer";
import DocumentsSelector, {
  documentsSelected,
} from "components/inputs/documentsSelector";
import { useEffect } from "react";
import { createApiService } from "service";
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "store/app/reducer";
import { useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import createNumberMask from "util/createNumberMaskDatagro";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateLuxonUtils from "@date-io/luxon";
import { useSnackbar } from "notistack";
import {
  formatCNPJ,
  formatCurrency,
  formatProductUnit,
  formatQuanty,
} from "util/format";
import ptLocale from "date-fns/locale/pt-BR";
import { FullOfferDTO } from "models/offer.model";
const qs = require("query-string");

function DateMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
      guide={false}
    />
  );
}

const defaultMaskOptions = {
  prefix: "R$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: true,
};

const CurrencyInput: React.FC<any> = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

const CurrencyInputNegative: React.FC<any> = ({
  maskOptions,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    allowNegative: true,
    prefix: "",
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

const quantityMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: false,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const QuantyInput: React.FC<any> = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...quantityMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};

function ValueMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={" "}
      showMask
      guide={false}
    />
  );
}

function CepMask(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
      placeholderChar={" "}
      showMask
      guide={true}
    />
  );
}

type finishedDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  offerType: "sell" | "buy";
};

function FinishedDialog(props: finishedDialogProps) {
  const { onClose, onConfirm, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Oferta de {props.offerType === "sell" ? "venda" : "compra"} lançada com
        sucesso
      </DialogTitle>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
          >
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

type NoProductsAllowedDialogProps = {
  onClose: () => void;
  open: boolean;
};

function NoProductsAllowedDialog(props: NoProductsAllowedDialogProps) {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
    >
      <DialogContent>
        Este participante não está autorizado a negociar para nenhum produto.
        Favor entrar em contato com o administrador!
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button
            fullWidth
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            Voltar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

type confirmContractDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmContractDialog(props: confirmContractDialogProps) {
  const { open, onClose, onConfirm } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Confirmar Pré-Visualização de contrato
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "575px" }}>
          Pré-Visualizar contrato com os dados da oferta informados?
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button fullWidth onClick={onClose} style={{ marginRight: "10px" }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
            // disabled={isSubmiting}
          >
            Confirmar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

type confirmDialogProps = {
  open: boolean;
  onClose: () => void;
  unitValue: number;
  value: number;
  onConfirm: () => void;
  onCancel: () => void;
  broker: string;
  participant: string;
  isSubmiting: boolean;
  offerType: "sell" | "buy";
  unit: string;
  coin?: string;
};

function ConfirmDialog(props: confirmDialogProps) {
  const {
    onClose,
    onConfirm,
    open,
    value,
    broker,
    participant,
    unitValue,
    isSubmiting,
    unit,
    coin,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
        Confirmar lançamento
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "575px" }}>
          Tem certeza que deseja registrar a{" "}
          <b>
            oferta de tipo {props.offerType === "sell" ? "Venda" : "Compra"}
          </b>{" "}
          em nome do participante “{participant}“ pelo valor de{" "}
          <b>
            {unitValue
              ? `${formatCurrency(unitValue, coin)} / ${unit}`
              : "A FIXAR"}{" "}
          </b>{" "}
          ?
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div style={{ display: "flex" }}>
          <Button fullWidth onClick={onClose} style={{ marginRight: "10px" }}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onConfirm}
            disabled={isSubmiting}
          >
            Confirmar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

type measurementUnit = {
  id: number;
  description: string;
  abbreviation: string;
};

type productMetadata = {
  key: string;
  label: string;
  fieldType: string;
  fromToContractTemplate: string;
  maxLength: number | null;
  required: boolean;
};

type ProductType = {
  id: number;
  baseProductId: number;
  name: string;
  measurementUnit: measurementUnit;
  metadataDefinition: productMetadata[];
  contractTemplateIdIndexValue: string;
};

type member = {
  id: number;
  cpfCnpj: string;
  companyName: string | null;
};

type broker = {
  id: number;
  cpfCnpj: string;
  companyName: string | null;
};

type transportType = {
  id: number;
  type: string;
};

type paymentCondition = {
  id: number;
  description: string;
};

type props = {
  offerType: "sell" | "buy";
  contractSimulation?: boolean;
};

const RegisterSellOffer: React.FC<props> = ({
  offerType,
  contractSimulation,
}) => {
  const form = useForm();
  const [{ values }] = form as any;

  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productUnit, setProductUnit] = useState<measurementUnit | null>(null);
  const [productType, setProductType] = useState<ProductType | undefined>(
    undefined
  );

  const [avaiableCoins, setAvaiableCoins] = useState<any>([]);
  const [avaiablePriceIndexes, setAvaiablePriceIndexes] = useState<any>([]);
  const [offerCoin, setOfferCoin] = useState<any>(null);

  const [metadata, setMetadata] = useState(false);
  const [transportTypes, setTransportTypes] = useState<transportType[]>([]);
  const [paymentConditions, setPaymentConditions] = useState<
    paymentCondition[]
  >([]);

  const [showNoProductsAllowedDialog, setShowNoProductsAllowedDialog] =
    useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showContractDialog, setShowContractDialog] = useState(false);
  const [contractUrl, setContractUrl] = useState("");
  const [showFinishedDialog, setShowFinishedDialog] = useState(false);

  const [firstShipmentDate, setFirstShipmentDate] = useState("");

  const [isCompleteForm1, setIsCompleteForm1] = useState(false);
  const [isCompleteForm2, setIsCompleteForm2] = useState(false);
  const [isCompleteForm3, setIsCompleteForm3] = useState(false);
  const [isCompleteForm4, setIsCompleteForm4] = useState(false);

  const [form3WasOpened, setForm3WasOpened] = useState(false);
  const [form4WasOpened, setForm4WasOpened] = useState(false);

  const [addressWasAutomaticallySet, setAddressWasAutomaticallySet] =
    useState(false);

  const AppStore: AppState = useSelector((state: any) => state.app);

  const [currentForm, setCurrentForm] = useState(1);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const service = createApiService();

  const sendFormData = () => {
    const data = prepareDataFromForm();
    postForm(data);
  };

  const sendContractFormData = () => {
    const data = prepareDataFromForm();
    postContractForm(data);
  };

  const [baseOffer, setBaseOffer] = useState<null | FullOfferDTO>(null);

  // carregamento de lista de moedas

  useEffect(() => {
    service.get("/coins").then(({ data }) => {
      setAvaiableCoins(data);
      if (data.length > 0) {
        setOfferCoin(data[0]);
      }
    });
    service.get("/price-indexes").then(({ data }) => {
      setAvaiablePriceIndexes(data);
      if (data.length > 0) {
        values.current.priceindex = data[0].id;
      }
    });
  }, []);

  // preenchimento p/ funcionalidade de clonar

  useEffect(() => {
    const [participantId, stockbrockerId] = [
      AppStore.selectedParticipant?.id,
      AppStore.selectedStockBroker?.id,
    ];
    const baseOfferId = qs.parse((window.location as any).search).from;
    setFormOptions().then((res) => {
      const latestProductVersions = res[0] as ProductType[];
      if (participantId && stockbrockerId && baseOfferId) {
        service
          .get(
            `/offers/${baseOfferId}?participant=${participantId}&stockbroker=${stockbrockerId}`
          )
          .then((res) => {
            setBaseOffer(res.data);
            const productToBeUsed = latestProductVersions.find(
              ({ baseProductId }) =>
                baseProductId === res.data.product.baseProductId
            );
            if (productToBeUsed) {
              values.current.product = productToBeUsed.id;
            }
            values.current.quanty = res.data.quantity + "";
            values.current.price = `${formatCurrency(
              res.data.unitPrice / 100
            )}`;
            values.current.allowpartial = res.data.acceptPartialBid
              ? "yes"
              : "no";
            values.current.publishParticipantData =
              res.data.publishParticipantData === true ? "yes" : "no";

            values.current.classification = res.data.classification;

            if (res.data.type === "SALE_OFFER") {
              values.current.street = res.data.sellerUnitAddress.addressLine;
              values.current.addressnumber = res.data.sellerUnitAddress.number;
              values.current.complementaryaddress =
                res.data.sellerUnitAddress.complement;
              values.current.state = res.data.sellerUnitAddress.state;
              values.current.city = res.data.sellerUnitAddress.city;
              values.current.district = res.data.sellerUnitAddress.district;
              // if (
              //   res.data.sellerUnitAddress.zipCode.match(/(\d\d\d\d\d)(\d\d\d)/)
              // ) {
              // }
              values.current.cep =
                res.data.sellerUnitAddress.zipCode.match(/(.{5})(.*)/)[1] +
                "-" +
                res.data.sellerUnitAddress.zipCode.match(/(.{5})(.*)/)[2];
            } else {
              values.current.street = res.data.buyerUnitAddress.addressLine;
              values.current.addressnumber = res.data.buyerUnitAddress.number;
              values.current.complementaryaddress =
                res.data.buyerUnitAddress.complement;
              values.current.state = res.data.buyerUnitAddress.state;
              values.current.city = res.data.buyerUnitAddress.city;
              values.current.district = res.data.buyerUnitAddress.district;
              // if (
              //   res.data.buyerUnitAddress.zipCode.match(/(\d\d\d\d\d)(\d\d\d)/)
              // ) {
              // }
              values.current.cep =
                res.data.buyerUnitAddress.zipCode.match(/(.{5})(.*)/)[1] +
                "-" +
                res.data.buyerUnitAddress.zipCode.match(/(.{5})(.*)/)[2];
            }

            values.current.transport = res.data.shippingModes.map((s: any) => {
              return s.shippingMode.id + "";
            });

            values.current["payment-condition"] =
              res.data.payment.paymentConditionId;
            values.current.bankcode = res.data.payment.bankCode;
            values.current.bankagency = res.data.payment.agency;
            values.current.bankaccount = res.data.payment.account;
            values.current.bankpix = res.data.payment.pix;

            values.current["payment-details"] = res.data.payment.details || "";
            values.current.sellertaxes = res.data.payment.sellerTributes;
            values.current.buyertaxes = res.data.payment.buyerTributes;

            values.current.hasspecialtax =
              res.data.hasSpecialTax === true ? "yes" : "no";
            values.current.taxcategory = res.data.taxRegime || "";
            // values.current.hassocialseal =
            //   res.data.hasSocialSeal === true ? "yes" : "no";

            values.current.deliverySchedule = res.data.deliverySchedules.map(
              (d: any) => {
                const year = d.date.split("-")[0];
                const month = d.date.split("-")[1];
                return {
                  date: `${month}/${year}`,
                  value: d.quantity,
                };
              }
            );
            values.current.dailylimit = res.data.shipmentDailyLimit;
            values.current.firstshipment = DateTime.fromISO(
              res.data.firstShipmentDate
            ) as any;
            setFirstShipmentDate(
              DateTime.fromISO(res.data.firstShipmentDate) as any
            );
            values.current.anothershipmentcosts =
              res.data.anotherShipmentCosts || "";

            values.current.customers = res.data.allowedParticipants.map(
              (c: any) => {
                const formatedCNPJ = formatCNPJ(c.companyCnpj);
                return { companyCNPJ: formatedCNPJ };
              }
            );

            values.current.documents = res.data.documents.map((d: any) => {
              return {
                name: d.originalFilename,
                GCSfilename: d.gcsFilename,
                timeselected: new Date(d.selectedAt),
                downloadURL: d.downloadURL,
                uploadFinished: true,
                uploadId: 0,
              };
            });
            if (productToBeUsed) {
              setProductType(productToBeUsed);
              const { productSnapshot = [] } = res.data;
              if (productSnapshot.length) {
                productSnapshot.forEach((ps: any) => {
                  if (values.current[ps.key] !== undefined) {
                    values.current[ps.key] = ps.value;
                  }
                });
              }
              setMetadata(true);
            }
          });
      }
    });
  }, [AppStore.selectedParticipant, AppStore.selectedStockBroker]);

  const params = useParams() as any;

  function setFormOptions() {
    const p1 = new Promise((resolve) => {
      service
        .get(
          contractSimulation
            ? "/products/baseProducts"
            : `/products/allowed-products/${AppStore.selectedParticipant?.id}`
        )
        .then(({ data: productTypes }) => {
          setProductTypes(productTypes);
          if (productTypes.length > 0) {
            if (contractSimulation) {
              const pt = productTypes.find(
                (p: any) => p.id == params.productId
              );
              setProductUnit(pt.measurementUnit);
              setProductType(pt);
              values.current.product = pt.id;
            } else {
              setProductUnit(productTypes[0].measurementUnit);
              setProductType(productTypes[0]);
              values.current.product = productTypes[0].id;
            }
            forceUpdate();
            resolve(productTypes);
          } else {
            setShowNoProductsAllowedDialog(true);
          }
        });
    });

    const p2 = new Promise((resolve) => {
      service.get("/shipping-modes").then(({ data: transportTypes }) => {
        setTransportTypes(transportTypes);
        resolve(true);
      });
    });

    const p3 = new Promise((resolve) => {
      service.get("/payment-conditions").then(({ data: paymentConditions }) => {
        setPaymentConditions(paymentConditions);
        resolve(true);
      });
    });
    return Promise.all([p1, p2, p3]);
  }

  async function checkAutocompleteAddress(cep: string) {
    if (cep.match(/[0-9]{5}-[0-9]{3}/)) {
      const { data: cepData } = await service.get(
        `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`
      );
      console.log(cepData);

      if (!cepData.erro) {
        values.current.street = cepData.logradouro;
        values.current.district = cepData.bairro;
        values.current.state = cepData.uf;
        values.current.city = cepData.localidade;
        if(
          cepData.logradouro != "" &&
          cepData.bairro != "" &&
          cepData.uf != "" &&
          cepData.localidade){
          setAddressWasAutomaticallySet(true);
        }else{
          setAddressWasAutomaticallySet(false);
        }
      } else {
        setAddressWasAutomaticallySet(false);
      }
      forceUpdate();
    }
  }

  function formatProductUnit(unit: string | null): ReactElement {
    switch (unit) {
      case "m3":
        return (
          <span>
            m<sup>3</sup>
          </span>
        );
      case null:
        return <span></span>;
      default:
        return <span></span>;
    }
  }

  function generateProductMetadataForm(
    productMetadatas: productMetadata[] = []
  ) {
    const validateFunctions = (metadata: productMetadata) => [
      {
        function: (v: string) => {
          return metadata.required === true ? v !== "" && v !== null : true;
        },
        message: "Campo obrigatório",
      },
      {
        function: (v: string) => {
          return metadata.maxLength && v.length > metadata.maxLength
            ? false
            : true;
        },
        message: `O campo deve conter no máximo ${metadata.maxLength} caracteres`,
      },
    ];
    return productMetadatas.map((metadata, i) => {
      const formByMetadataType: any = {
        string: () => {
          return {
            valid: false,
            render: ({ value, onChange, valid }: any) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={metadata.key}>{metadata.label}</InputLabel>
                <Input
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  id={metadata.key}
                />
              </FormControl>
            ),
            value: "",
            collums: 12,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: validateFunctions(metadata),
            onChange: (v: any) => {},
            formid: metadata.key,
          };
        },
        number: () => {
          return {
            valid: false,
            render: ({ value, onChange, valid }: any) => (
              <FormControl fullWidth>
                <InputLabel htmlFor={metadata.key}>{metadata.label}</InputLabel>
                <Input
                  type="number"
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  id={metadata.key}
                />
              </FormControl>
            ),
            value: "",
            collums: 12,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: validateFunctions(metadata),
            onChange: (v: any) => {},
            formid: metadata.key,
          };
        },
        boolean: () => {
          return {
            valid: false,
            render: ({ value, onChange, valid }: any) => (
              <FormControl fullWidth>
                <InputLabel id={metadata.key}>{metadata.label}</InputLabel>
                <Select
                  labelId={metadata.key}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                >
                  {[
                    { name: "Não", id: "false" },
                    { name: "Sim", id: "true" },
                  ].map((p, i) => {
                    return (
                      <MenuItem value={p.id} key={i} selected={value === null}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
            value: "",
            collums: 12,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: validateFunctions(metadata),
            onChange: (v: any) => {},
            formid: metadata.key,
          };
        },
      };
      const form = formByMetadataType[metadata.fieldType]();
      return form;
    });
  }

  enum validTransports {
    rail = "rail", // Ferroviário
    road = "road", // Rodoviário
    aquatic = "aquatic", // Aquático
  }

  type transport = {
    code: string;
    name: string;
  };

  const history = useHistory();

  const metadataForms: any = generateProductMetadataForm(
    productType?.metadataDefinition
  );

  const refValueDate = useRef("");
  function handleDateChange(e: any) {
    refValueDate.current = e.target.value;
    forceUpdate();
  }

  const form1: formItemGroup[] = [
    {
      groupTitle: undefined,
      removePadding: true,
      items: [
        [
          {
            onChange: (v) => {
              forceUpdate();
            }, // necessário pois a unidade de medida do produto está nessa lista, para mostrar nos outros lugares onde for necessário
            formid: "definedprice",
            value: "yes",
            valid: false,
            collums: 12,
            render: ({ value, onChange }) => (
              <div
                style={{
                  backgroundColor: "#eaeaea",
                  width: "100%",
                  padding: "15px",
                  paddingLeft: "11px",
                  fontWeight: "bold",
                }}
              >
                <Grid container spacing={3} style={{ width: "100%" }}>
                  <Grid item xs={4} sm={2}>
                    Produto
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    Quantidade{" "}
                    {productUnit && <span>/ {productUnit.abbreviation}</span>}
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <div style={{ position: "relative" }}>
                      Preço {productUnit ? "/ " : ""}
                      {productUnit?.abbreviation}
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "-9px",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <div
                      style={{
                        position: "relative",
                        marginTop: "-10px",
                        height: "20px",
                      }}
                    >
                      <RadioGroup
                        label=""
                        value={values.current.definedprice || value}
                        onChange={(v) => {
                          onChange(v);
                          if (v === "no") {
                            values.current.price = undefined;
                          }
                          forceUpdate();
                        }}
                        options={[
                          {
                            code: "yes",
                            name: "Fixo",
                          },
                          ...(productType?.contractTemplateIdIndexValue !== ""
                            ? [
                                {
                                  code: "no",
                                  name: "A fixar",
                                },
                              ]
                            : []),
                        ]}
                        valid={true}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ),
          },
        ],
      ],
    },
    {
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel id="select-product">
                  {productTypes.length > 0 ? "" : "Produto"}
                </InputLabel>
                <Select
                  labelId="select-product"
                  fullWidth
                  value={
                    value
                      ? value
                      : productTypes.length > 0
                      ? productTypes[0].id
                      : null
                  }
                  onChange={(e) => {
                    onChange(e.target.value);
                    const pt = productTypes.find(
                      (p) => p.id === e.target.value
                    );
                    values.current.definedprice = "yes";
                    setProductType(pt);
                    setProductUnit(
                      productTypes.filter((p) => p.id === e.target.value)[0]
                        ?.measurementUnit
                    );
                  }}
                >
                  {productTypes.map((p, i) => {
                    return (
                      <MenuItem value={p.id} key={i} selected={value === null}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
            value: productTypes.length > 0 ? productTypes[0].id : null,
            collums: 2,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {
              forceUpdate();
            }, // necessário pois a unidade de medida do produto está nessa lista, para mostrar nos outros lugares onde for necessário
            formid: "product",
          },
          {
            valid: false,
            render: ({ value, onChange, valid }) => (
              <div style={{ marginTop: "3px" }}>
                <InputLabel htmlFor="productquantyinput">Quantidade</InputLabel>
                <Input
                  value={formatQuanty(parseInt(value))}
                  inputComponent={QuantyInput}
                  onChange={(e) => {
                    onChange(e.target.value.replace(/\./g, ""));
                  }}
                  id="productquantyinput"
                  fullWidth
                  error={valid === false}
                />
              </div>
            ),
            value: baseOffer ? baseOffer.quantity + "" : "0",
            collums: 1,
            collumsxs: 6,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "" && v !== null;
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {
              forceUpdate(); // a quantidade afeta o componente de datas de entrega, necessário re-render
            },
            formid: "quanty",
          },
          ...(values.current.definedprice !== "no"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange, valid }: any) => (
                    <FormControl
                      fullWidth
                      style={{
                        display:
                          values.current.definedprice !== "no"
                            ? "inline-flex"
                            : "hidden",
                      }}
                    >
                      <InputLabel htmlFor="valueinput">Preço</InputLabel>
                      <Input
                        // value={value ? value.replace(".", ",") : undefined}
                        inputComponent={CurrencyInput}
                        onChange={(e: any) => {
                          onChange(
                            e.target.value
                              .replace(/\./g, "")
                              .replace(",", ".")
                              .replace("R$", "")
                          );
                        }}
                        id="valueinput"
                      />
                    </FormControl>
                    // <TextField
                    //   label="Preço (R$)"
                    //   value={value}
                    //   onChange={(e) => {
                    //     onChange(e.target.value);
                    //   }}
                    //   fullWidth
                    //   error={valid === false}
                    // />
                  ),
                  value: "",
                  collums: 2,
                  collumsxs: 6,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  validateFunctions: [
                    {
                      function: (v: string) => {
                        return v !== "" && v !== null;
                      },
                      message: "Campo obrigatório",
                    },
                  ],
                  onChange: (v: any) => {},
                  formid: "price",
                },
              ]
            : []),
          ...(values.current.definedprice !== "no"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange }: any) => (
                    <FormControl fullWidth style={{ maxWidth: "50px" }}>
                      <InputLabel id="select-coin">
                        {avaiableCoins.length > 0 ? "" : "Moeda"}
                      </InputLabel>
                      <Select
                        labelId="select-coin"
                        fullWidth
                        value={
                          value
                            ? value
                            : avaiableCoins.length > 0
                            ? avaiableCoins[0].id
                            : null
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          const coin = avaiableCoins.find(
                            (c: any) => c.id === e.target.value
                          );
                          setOfferCoin(coin);
                        }}
                      >
                        {avaiableCoins.map((c: any, i: any) => {
                          return (
                            <MenuItem
                              value={c.id}
                              key={i}
                              selected={value === null}
                            >
                              {c.prefix}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ),
                  value: avaiableCoins.length > 0 ? avaiableCoins[0].id : null,
                  collums: 1,
                  collumsxs: 12,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  onChange: (v: any) => {
                    forceUpdate();
                  }, // necessário pois a unidade de medida do produto está nessa lista, para mostrar nos outros lugares onde for necessário
                  formid: "coin",
                },
              ]
            : []),
          ...(values.current.definedprice !== "yes"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange }: any) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-price-index">Índice</InputLabel>
                      <Select
                        labelId="select-price-index"
                        fullWidth
                        value={values.current.priceindex || value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        {avaiablePriceIndexes.map((p: any, i: any) => {
                          return (
                            <MenuItem
                              value={p.id}
                              key={i}
                              selected={value === null}
                            >
                              {p.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ),
                  value:
                    avaiablePriceIndexes.length > 0
                      ? avaiablePriceIndexes[0].id
                      : null,
                  collums: 1,
                  collumsxs: 12,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  onChange: (v: any) => {
                    forceUpdate();
                  }, // necessário pois a unidade de medida do produto está nessa lista, para mostrar nos outros lugares onde for necessário
                  formid: "priceindex",
                },
              ]
            : []),
          ...(values.current.definedprice !== "yes"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange, valid }: any) => {
                    return (
                      <FormControl style={{}}>
                        <InputLabel htmlFor="deliverydateinput">
                          Mês de fixação
                        </InputLabel>
                        <Input
                          value={refValueDate.current}
                          onChange={handleDateChange}
                          id="deliverydateinput"
                          inputComponent={DateMask}
                          onBlur={forceUpdate}
                          onKeyDown={(e) => {
                            // console.log(e);
                            if (
                              e.key !== "Backspace" &&
                              ((refValueDate.current.length === 0 &&
                                e.key !== "1" &&
                                e.key !== "0") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 1 &&
                                  refValueDate.current[0] === "1" &&
                                  e.key !== "0" &&
                                  e.key !== "1" &&
                                  e.key !== "2") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 1 &&
                                  refValueDate.current[0] === "0" &&
                                  e.key === "0") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 3 &&
                                  e.key !== "2") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 2 &&
                                  e.key !== "2") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 4 &&
                                  e.key !== "0") ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 5 &&
                                  parseInt(
                                    (refValueDate.current + e.key + "9").split(
                                      "/"
                                    )[1]
                                  ) < 2021) ||
                                (refValueDate.current.replace(/\s/g, "")
                                  .length === 6 &&
                                  parseInt(
                                    (refValueDate.current + e.key).split("/")[1]
                                  ) < DateTime.now().get("year")))
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                            // console.log(refValueDate.current.replace(/\s/g, "").length);
                            // console.log(refValueDate.current);
                            // console.log(e.key);
                            // e.preventDefault();
                            // e.stopPropagation();
                          }}
                        />
                      </FormControl>
                    );
                  },
                  value: "",
                  collums: 1,
                  collumsxs: 12,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  onChange: (v: any) => {},
                  formid: "pricedefinitiondate",
                },
              ]
            : []),
          ...(values.current.definedprice !== "yes"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange, valid }: any) => (
                    <FormControl fullWidth>
                      <InputLabel htmlFor="awardinput">Prêmio</InputLabel>
                      <Input
                        // value={value.replace(".", ",")}
                        inputComponent={CurrencyInputNegative}
                        onChange={(e: any) => {
                          onChange(
                            e.target.value
                              .replace(/\./g, "")
                              .replace(",", ".")
                              .replace("R$", "")
                          );
                        }}
                        id="awardinput"
                      />
                    </FormControl>
                    // <TextField
                    //   label="Preço (R$)"
                    //   value={value}
                    //   onChange={(e) => {
                    //     onChange(e.target.value);
                    //   }}
                    //   fullWidth
                    //   error={valid === false}
                    // />
                  ),
                  value: "",
                  collums: 1,
                  collumsxs: 6,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  validateFunctions: [
                    {
                      function: (v: string) => {
                        return (
                          (v !== "" && v !== null) ||
                          values.current.definedprice === "yes"
                        );
                      },
                      message: "Campo obrigatório",
                    },
                  ],
                  onChange: (v: any) => {},
                  formid: "award",
                },
              ]
            : []),
        ],
        [
          ...(values.current.definedprice !== "yes"
            ? [
                {
                  valid: false,
                  render: ({ value, onChange }: any) => (
                    <TextField
                      id="value-formula"
                      label="Detalhar a fórmula de fixação do preço"
                      multiline
                      rows={5}
                      defaultValue={value}
                      value={values.current.valueformula || value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      fullWidth
                    />
                  ),
                  value: "",
                  collums: 6,
                  collumsxs: 12,
                  validateFunction: (v: string) => {
                    return true;
                  },
                  validateFunctions: [],
                  onChange: (v: any) => {},
                  formid: "valueformula",
                },
              ]
            : []),
        ],
        [
          {
            valid: false,
            render: ({ value, onChange, valid }) => (
              <RadioGroup
                label="Aceitar lances com volume/quantia variável?"
                value={values.current.allowpartial || value}
                onChange={(v) => {
                  onChange(v);
                  forceUpdate();
                }}
                options={[
                  {
                    code: "yes",
                    name: "Sim",
                  },
                  {
                    code: "no",
                    name: "Não",
                  },
                ]}
                valid={valid}
              />
            ),
            value: "no",
            collums: 8,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {
              forceUpdate();
            }, // necessário pois a unidade de medida do produto está nessa lista, para mostrar nos outros lugares onde for necessário
            formid: "allowpartial",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange, valid }) => (
              <RadioGroup
                label="Tornar público os dados do participante? (nome do participante, endereço e corretora)"
                value={values.current.publishParticipantData || value}
                onChange={(v) => {
                  onChange(v);
                  forceUpdate();
                }}
                options={[
                  {
                    code: "yes",
                    name: "Sim",
                  },
                  {
                    code: "no",
                    name: "Não",
                  },
                ]}
                valid={valid}
              />
            ),
            value: "no",
            collums: 8,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "publishParticipantData",
          },
        ],
      ],
      groupTitle: undefined,
    },
    {
      groupTitle: "Informar a classificação do Produto (detalhar o produto): ",
      items: [
        [
          ...metadataForms,
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                id="product-classification"
                label="Detalhar classificação - campo de digitação livre (obrigatório) limite 300 caracteres"
                multiline
                rows={5}
                defaultValue={value}
                value={values.current.classification || value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 6,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v.length <= 300;
                },
                message: "O texto deve conter no máximo 300 caracteres",
              },
            ],
            onChange: (v) => {},
            formid: "classification",
          },
        ],
      ],
    },
    {
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange, valid }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="cepinput">CEP</InputLabel>
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id="cepinput"
                  inputComponent={CepMask}
                  fullWidth
                  error={valid === false}
                />
              </FormControl>
            ),
            value: "",
            collums: 2,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {
              checkAutocompleteAddress(v);
            },
            formid: "cep",
          },
          {
            valid: true,
            render: ({ value, onChange }) => (
              <TextField
                label="Logradouro"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
                disabled={addressWasAutomaticallySet}
              />
            ),
            value: "",
            collums: 4,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "";
                },
                message: "Campo obrigatório",
              },
            ],
            autoCompletedValue: addressWasAutomaticallySet,
            onChange: (v) => {},
            formid: "street",
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Número"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 2,
            collumsxs: 4,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "";
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "addressnumber",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Bairro"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
                disabled={addressWasAutomaticallySet}
              />
            ),
            value: "",
            collums: 2,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "";
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "district",
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Complemento"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 2,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "complementaryaddress",
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Cidade"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
                disabled={addressWasAutomaticallySet}
              />
            ),
            value: "",
            collums: 2,
            collumsxs: 8,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "";
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "city",
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl>
                <InputLabel id="uf-select-label">Estado</InputLabel>
                <Select
                  labelId="uf-select-label"
                  id={"uf-select"}
                  style={{ width: "80px" }}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  value={values.current.state}
                  MenuProps={{ className: "menuState" }}
                  disabled={addressWasAutomaticallySet}
                >
                  <MenuItem value={"AC"}>AC</MenuItem>
                  <MenuItem value={"AL"}>AL</MenuItem>
                  <MenuItem value={"AP"}>AP</MenuItem>
                  <MenuItem value={"AM"}>AM</MenuItem>
                  <MenuItem value={"BA"}>BA</MenuItem>
                  <MenuItem value={"CE"}>CE</MenuItem>
                  <MenuItem value={"DF"}>DF</MenuItem>
                  <MenuItem value={"ES"}>ES</MenuItem>
                  <MenuItem value={"GO"}>GO</MenuItem>
                  <MenuItem value={"MA"}>MA</MenuItem>
                  <MenuItem value={"MT"}>MT</MenuItem>
                  <MenuItem value={"MS"}>MS</MenuItem>
                  <MenuItem value={"MG"}>MG</MenuItem>
                  <MenuItem value={"PA"}>PA</MenuItem>
                  <MenuItem value={"PB"}>PB</MenuItem>
                  <MenuItem value={"PR"}>PR</MenuItem>
                  <MenuItem value={"PE"}>PE</MenuItem>
                  <MenuItem value={"PI"}>PI</MenuItem>
                  <MenuItem value={"RJ"}>RJ</MenuItem>
                  <MenuItem value={"RN"}>RN</MenuItem>
                  <MenuItem value={"RS"}>RS</MenuItem>
                  <MenuItem value={"RO"}>RO</MenuItem>
                  <MenuItem value={"RR"}>RR</MenuItem>
                  <MenuItem value={"SC"}>SC</MenuItem>
                  <MenuItem value={"SP"}>SP</MenuItem>
                  <MenuItem value={"SE"}>SE</MenuItem>
                  <MenuItem value={"TO"}>TO</MenuItem>
                </Select>
              </FormControl>
              // <TextField
              //   label="Estado"
              //   value={value}
              //   onChange={(e) => {
              //     onChange(e.target.value);
              //   }}
              //   fullWidth
              // />
            ),
            value: "",
            collums: 2,
            collumsxs: 4,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function(v: string) {
                  if (!v) {
                    return false;
                  }
                  return true;
                },
                message: "Seleção obrigatória",
              },
            ],
            onChange: (v) => {},
            formid: "state",
          },
        ],
      ],
      groupTitle:
        "Local da Unidade fornecedora (p/entrega) ou receptora (p/recebimento) do produto: ",
    },
    {
      groupTitle: undefined,
      removePadding: true,
      items: [
        [
          {
            formid: "transport",
            onChange: () => {},
            render: ({ onChange, valid, value }) => {
              return (
                <CheckGroup
                  style={{
                    marginTop: "30px",
                    backgroundColor: "#eaeaea",
                    width: "100%",
                    padding: "6px",
                    paddingLeft: "11px",
                  }}
                  label="Modalidade do frete:"
                  value={values.current.transport || value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  valid={valid}
                  options={
                    transportTypes.map((t) => {
                      return {
                        code: `${t.id}`,
                        name: t.type,
                      };
                    }) as transport[]
                  }
                ></CheckGroup>
              );
            },
            value: [] as unknown as validTransports[],
            collums: 12,
            valid: false,
            validateFunctions: [
              {
                function: (v: validTransports[]) => {
                  return v.length > 0;
                },
                message: "Obrigatório ao menos uma modalidade de frete",
              },
            ],
          },
        ],
      ],
    },
    {
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <span>
                    <b>Condições de pagamento</b>
                  </span>
                </div>
              );
            },
            formid: "labelpayment",
            onChange: () => {},
            value: "",
            collums: 2,
            collumsxs: 4,
            validateFunctions: [],
          },
          {
            valid: false,
            render: ({ value, onChange }) => (
              <FormControl fullWidth>
                <InputLabel id="select-payment-condition"></InputLabel>
                <Select
                  labelId="select-payment-condition"
                  fullWidth
                  value={values.current["payment-condition"] || value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                >
                  {paymentConditions.map((p) => {
                    return <MenuItem value={p.id}>{p.description}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ),
            value: paymentConditions[0] ? paymentConditions[0].id : 0,
            collums: 6,
            collumsxs: 8,
            validateFunction: (v: number) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: number) => {
                  return v !== 0;
                },
                message: "Selecione a condição de pagamento",
              },
            ],
            onChange: (v) => {},
            formid: "payment-condition",
          },
        ],
        offerType === "sell"
          ? [
              {
                valid: false,
                render: ({ value, onChange, valid }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="bankcodeinput">Cód Banco</InputLabel>
                    <Input
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      id="bankcodeinput"
                      inputComponent={ValueMask}
                      fullWidth
                      error={valid === false}
                    />
                  </FormControl>
                ),
                value: "",
                collums: 2,
                collumsxs: 3,
                validateFunction: (v: string) => {
                  return true;
                },
                validateFunctions:
                  offerType === "sell"
                    ? [
                        {
                          function: (v: string) => {
                            return v !== "";
                          },
                          message: "Campo obrigatório",
                        },
                      ]
                    : [],
                onChange: (v) => {},
                formid: "bankcode",
              },
              {
                valid: false,
                render: ({ value, onChange, valid }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="bankagencyinput">Agência</InputLabel>
                    <Input
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      id="bankagencyinput"
                      inputComponent={ValueMask}
                      fullWidth
                      error={valid === false}
                    />
                  </FormControl>
                ),
                value: "",
                collums: 2,
                collumsxs: 3,
                validateFunction: (v: string) => {
                  return true;
                },
                validateFunctions:
                  offerType === "sell"
                    ? [
                        {
                          function: (v: string) => {
                            return v !== "";
                          },
                          message: "Campo obrigatório",
                        },
                      ]
                    : [],
                onChange: (v) => {},
                formid: "bankagency",
              },
              {
                valid: false,
                render: ({ value, onChange }) => (
                  <TextField
                    label="Conta"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    fullWidth
                  />
                ),
                value: "",
                collums: 2,
                collumsxs: 3,
                validateFunction: (v: string) => {
                  return true;
                },
                validateFunctions:
                  offerType === "sell"
                    ? [
                        {
                          function: (v: string) => {
                            return v !== "";
                          },
                          message: "Campo obrigatório",
                        },
                      ]
                    : [],
                onChange: (v) => {},
                formid: "bankaccount",
              },
              {
                valid: false,
                render: ({ value, onChange }) => (
                  <TextField
                    label="Chave PIX (opcional)"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    fullWidth
                  />
                ),
                value: "",
                collums: 2,
                collumsxs: 3,
                validateFunction: (v: string) => {
                  return true;
                },
                onChange: (v) => {},
                formid: "bankpix",
              },
            ]
          : [],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                id="payment-description"
                label="Outras informações pertinentes ao pagamento  - campo de digitação livre (obrigatorio) - limite 300 caracteres  "
                multiline
                rows={5}
                defaultValue={value}
                value={values.current["payment-details"] || value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 8,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v.length <= 300;
                },
                message: "O texto deve conter no máximo 300 caracteres",
              },
              {
                function: (v: string) => {
                  return v !== "";
                },
                message: "Campo obrigatório",
              },
            ],
            onChange: (v) => {},
            formid: "payment-details",
          },
        ],
      ],
      groupTitle: undefined,
    },
    {
      groupTitle: "Tributos (informar):",
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Tributos de responsabilidade de pagamento pelo vendedor (Ex. Pis, Cofins, CSLL)"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 12,
            collumsmd: 12,
            collumslg: 6,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "sellertaxes",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Tributos de Responsabilidade de pagamento pelo Comprador (ICMS será acrescentado ao valor do produto arrematado) "
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 12,
            collumsmd: 12,
            collumslg: 6,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "buyertaxes",
          },
        ],
      ],
    },
    {
      groupTitle: "Outras informações do Ofertante: ",
      items: [
        [
          {
            valid: false,
            render: ({ value, onChange, valid }) => {
              return (
                <RadioGroup
                  label="O ofertante está enquadrado em algum regime especial de tributação?"
                  value={values.current.hasspecialtax || value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  options={[
                    {
                      code: "yes",
                      name: "Sim",
                    },
                    {
                      code: "no",
                      name: "Não",
                    },
                  ]}
                  valid={valid}
                />
              );
            },
            value: "no",
            collums: 6,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            onChange: (v) => {},
            formid: "hasspecialtax",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <TextField
                label="Se sim, informar detalhes : (campo livre 100 caracteres)"
                value={values.current.taxcategory || value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                fullWidth
              />
            ),
            value: "",
            collums: 6,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v.length <= 100;
                },
                message: "O texto deve conter no máximo 100 caracteres",
              },
            ],
            onChange: (v) => {},
            formid: "taxcategory",
          },
        ],
        // [
        //   {
        //     onChange: (v) => {},
        //     render: ({ valid, value, onChange }) => (
        //       <RadioGroup
        //         label="É detentor do Selo Social (somente para produtores de biodiesel) ?"
        //         value={value}
        //         onChange={(v) => {
        //           onChange(v);
        //         }}
        //         options={[
        //           {
        //             code: "yes",
        //             name: "Sim",
        //           },
        //           {
        //             code: "no",
        //             name: "Não",
        //           },
        //         ]}
        //         valid={valid}
        //       />
        //     ),
        //     valid: false,
        //     validateFunctions: [],
        //     value: "no",
        //     collums: 5,
        //     collumsxs: 12,
        //     formid: "hassocialseal",
        //   },
        // ],
      ],
    },
  ];

  const form2: formItemGroup[] = [
    {
      items: [
        [
          {
            onChange: () => {},
            valid: false,
            render: ({ value, onChange }) => {
              return (
                <div>
                  <span
                    style={{
                      fontWeight: "bold",
                      marginBottom: "20px",
                      display: "inline-block",
                    }}
                  >
                    Informe abaixo o(s) mês(es) de entrega e/ou recebimento por
                    mês (cadência mensal)
                  </span>
                  <DeliverySchedule
                    quanty={parseInt(values.current.quanty)}
                    value={values.current.deliverySchedule || value}
                    onChange={onChange}
                    allowMultiple={values.current.allowpartial !== "yes"}
                    unit={productUnit?.abbreviation}
                  ></DeliverySchedule>
                </div>
              );
            },
            value: [] as deliverySchedules,
            collums: 4,
            collumsxs: 12,
            validateFunctions: [
              {
                function: (v: deliverySchedules) => {
                  return (
                    v.reduce((acum, current) => {
                      return acum + current.value;
                    }, 0) === parseInt(values.current.quanty) ||
                    values.current.allowpartial === "yes"
                  );
                },
                message:
                  "O somatório das entregas deve ser igual a quantia total",
              },
              {
                function: (v: deliverySchedules) => {
                  return (
                    v.reduce((acum, current) => {
                      return acum + current.value;
                    }, 0) === parseInt(values.current.quanty) ||
                    values.current.allowpartial === "no"
                  );
                },
                message:
                  "Ofertas com lances parciais devem conter uma única data de entrega com todo o volume",
              },
              {
                function: (v: deliverySchedules) => {
                  return (
                    v.reduce((acum, current) => {
                      return acum + current.value;
                    }, 0) !== 0
                  );
                },
                message: "O somatório do volume das entregas não pode ser 0",
              },
              {
                function: (v: deliverySchedules) => {
                  return !v.some((d) => {
                    const month = d.date.split("/")[0];
                    const year = d.date.split("/")[1];

                    return (
                      DateTime.fromISO(`${year}-${month}-01`).toMillis() <
                      DateTime.now().startOf("month").toMillis()
                    );
                  });
                },
                message: "A data de entrega não pode ser anterior a data atual",
              },
            ],
            formid: "deliverySchedule",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <div style={{ marginTop: "20px" }}>
                <div style={{ position: "relative", marginBottom: "50px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "800px",
                      marginTop: "-30px",
                      maxWidth: "100vw",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Informe abaixo o limite máximo diário previsto para
                      entrega e/ou recebimento (cadência diária)
                    </span>
                  </div>
                </div>
                <InputLabel htmlFor="dailylimitinput">
                  Limite máximo diário em {productUnit?.abbreviation}
                </InputLabel>
                <Input
                  value={values.current.dailylimit || value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id="dailylimitinput"
                  inputComponent={ValueMask}
                  defaultValue={0}
                  fullWidth
                />
              </div>
            ),
            value: 0,
            collums: 2,
            collumsxs: 6,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: number) => {
                  return v != 0;
                },
                message: "Valor não pode ser 0",
              },
            ],
            onChange: (v) => {},
            formid: "dailylimit",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <div style={{ marginTop: "20px" }}>
                <div style={{ position: "relative", marginBottom: "50px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "800px",
                      marginTop: "-30px",
                      maxWidth: "100vw",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Informe abaixo a data prevista para o inicio de entrega
                      e/ou do recebimento do produto
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <MuiPickersUtilsProvider
                    utils={DateLuxonUtils}
                    locale={"pt-BR"}
                  >
                    <KeyboardDatePicker
                      clearable
                      format="dd/MM/yyyy"
                      invalidDateMessage=""
                      maxDateMessage=""
                      placeholder="Selecionar data"
                      onChange={(date: any) => {
                        setFirstShipmentDate(date);
                        onChange(date);
                      }}
                      value={values.current.firstshipment || firstShipmentDate}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ),
            value: "",
            collums: 2,
            collumsxs: 6,
            validateFunction: (v: string) => {
              return true;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v !== "" && v !== null;
                },
                message: "Seleção obrigatória",
              },
              {
                function: (v: DateTime | string) => {
                  if (typeof v === "string" && v === "") {
                    return true;
                  }
                  return (
                    (v as DateTime).toMillis() >=
                    DateTime.now().startOf("day").toMillis()
                  );
                },
                message: "Data não pode ser anterior ao dia de hoje",
              },
            ],
            onChange: (v) => {},
            formid: "firstshipment",
          },
        ],
        [
          {
            valid: false,
            render: ({ value, onChange }) => (
              <div style={{ marginTop: "20px" }}>
                <div style={{ position: "relative", marginBottom: "60px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "800px",
                      marginTop: "-40px",
                      maxWidth: "100vw",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Informe abaixo os custos adicionais da contraparte
                      (arrematante) que incidirão na entrega e/ou no recebimento
                      do produto e de armazenamento, se houverem (campo de
                      preenchimento não obrigatório)
                    </span>
                  </div>
                </div>
                <TextField
                  id="product-classification"
                  label="Outras informações referentes custos de entrega e/ou recebimento do produto ou de armazenamento                                                        
                  campo de digitação livre não obrigatório  - limite 300 caracteres "
                  multiline
                  rows={8}
                  defaultValue={value}
                  value={values.current.anothershipmentcosts || value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  fullWidth
                />
              </div>
            ),
            value: "",
            collums: 8,
            collumsxs: 12,
            validateFunction: (v: string) => {
              return v.length <= 300;
            },
            validateFunctions: [
              {
                function: (v: string) => {
                  return v.length <= 300;
                },
                message: "O texto deve ter no máximo 300 caracteres",
              },
            ],
            onChange: (v) => {},
            formid: "anothershipmentcosts",
          },
        ],
      ],
      groupTitle: undefined,
    },
  ];

  const form3: formItemGroup[] = [
    {
      items: [
        [
          {
            onChange: (v) => {},
            render: ({ value, onChange }) => {
              return (
                <SelectCustomers
                  value={values.current.customers || value}
                  onChange={onChange}
                />
              );
            },
            valid: false,
            value: [] as string[],
            collums: 4,
            collumsxs: 12,
            validateFunctions: [],
            formid: "customers",
          },
        ],
      ],
      groupTitle: undefined,
    },
  ];

  const form4: formItemGroup[] = [
    {
      items: [
        [
          {
            onChange: (v) => {},
            render: ({ value, onChange }) => {
              return (
                <DocumentsSelector
                  value={values.current.documents || value}
                  onChange={onChange}
                  hasPreloadedDocuments={
                    values.current.documents &&
                    values.current.documents.length > 0
                  }
                />
              );
            },
            valid: false,
            collums: 6,
            collumsxs: 12,
            validateFunctions: [
              {
                function: (v: documentsSelected) => {
                  return !v.some((v) => v.uploadFinished === false);
                },
                message:
                  "Aguarde a conclusão dos uploads pendentes ou remova o arquivo",
              },
            ],
            value: [] as documentsSelected,
            formid: "documents",
          },
        ],
      ],
      groupTitle: undefined,
    },
  ];
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function postContractForm(data: any) {
    setIsSubmiting(true);
    service
      .post("products/product-contract-sample", data)
      .then((res) => {
        setContractUrl(res.data.fileUrl);
        setShowContractDialog(false);
      })
      .catch((e) => {
        window.alert("Erro ao simular contrato");
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  }

  function postForm(data: any) {
    setIsSubmiting(true);
    service
      .post("offers", data)
      .then(() => {
        setShowConfirmDialog(false);
        setShowFinishedDialog(true);
      })
      .catch((e) => {
        if (e.response.data.code === 1003) {
          enqueueSnackbar("Erro: mercado encerrado", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
          history.push("/ofertas");
        } else if (e.response.status === 403) {
          enqueueSnackbar(
            "Erro: impedido de realizar operações para este participante",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
          history.push("/ofertas");
        } else if (e.response.data.code === 1009) {
          enqueueSnackbar(
            "Este participante não está autorizado a negociar este produdo. Favor entrar em contato com o administrador!",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
        } else {
          window.alert("Erro ao criar oferta");
        }
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  }

  function prepareDataFromForm() {
    const productSnapshot = productType?.metadataDefinition.map(
      (metadata: productMetadata) => {
        const metadataKey = Object.keys(values.current).find(
          (key) => key === metadata.key
        );
        const metadataValue = values.current[metadataKey || ""];
        return {
          ...metadata,
          value: metadataValue,
        };
      }
    );
    return {
      type: offerType === "sell" ? "SALE_OFFER" : "PURCHASE_OFFER", // values.
      productId: values.current.product,
      productType,
      participantId: AppStore.selectedParticipant
        ? AppStore.selectedParticipant.id
        : 0,
      stockbrockerId: AppStore.selectedStockBroker
        ? AppStore.selectedStockBroker.id
        : 0,
      quantity: parseInt(values.current.quanty),
      unitPrice:
        values.current.definedprice !== "yes"
          ? null
          : parseFloat(
              values.current.price.replace(",", ".").replace("R$", "")
            ) * 100,
      valueToBeDefined: values.current.definedprice === "no",
      valueDefinitionMonth:
        values.current.definedprice === "no" ? refValueDate.current : null,
      valueDefinitionIndex:
        values.current.definedprice === "no" ? values.current.priceindex : null,
      valueDefinitionIndexName:
        values.current.definedprice === "no"
          ? avaiablePriceIndexes.find(
              (i: any) => i.id === values.current.priceindex
            ).name
          : null,
      valueDefinitionFormula:
        values.current.definedprice === "no"
          ? values.current.valueformula
          : null,
      offerAward:
        values.current.definedprice === "no"
          ? parseFloat(
              values.current.award.replace(",", ".").replace("R$", "")
            ) * 100
          : null,
      allowPartial: values.current.allowpartial === "yes",
      classification: values.current.classification,
      isRestricted: values.current.customers.length > 0,
      coin: values.current.coin,
      allowedParticipants: values.current.customers.map((c: any) => {
        return {
          // companyName: c.companyName,
          // participantId: c.id,
          companyCnpj: c.companyCNPJ,
        };
      }),
      sellerUnitAddress: {
        zipCode: values.current.cep.replace("-", ""),
        addressLine: values.current.street,
        number: values.current.addressnumber,
        complement: values.current.complementaryaddress,
        city: values.current.city,
        state: values.current.state,
        district: values.current.district,
      },
      shippingModes: values.current.transport.map((t: string) => {
        return {
          shippingModeId: parseInt(t),
        };
      }),
      shipmentDailyLimit: values.current.dailylimit,
      firstShipmentDate: values.current.firstshipment,
      anotherShipmentCosts: values.current.anothershipmentcosts,
      taxRegime: values.current.taxcategory,
      // hasSocialSeal: values.current.hassocialseal === "yes",
      hasSpecialTax: values.current.hasspecialtax === "yes",
      payment:
        offerType === "sell"
          ? {
              paymentConditionId: values.current["payment-condition"],
              details: values.current["payment-details"],
              sellerTributes: values.current.sellertaxes,
              buyerTributes: values.current.buyertaxes,
              bankCode: values.current.bankcode,
              agency: values.current.bankagency,
              account: values.current.bankaccount,
              pix: values.current.bankpix,
            }
          : {
              paymentConditionId: values.current["payment-condition"],
              details: values.current["payment-details"],
              sellerTributes: values.current.sellertaxes,
              buyerTributes: values.current.buyertaxes,
              bankCode: null,
              agency: null,
              account: null,
              pix: null,
            },
      additionalCosts: "",
      bids: [],
      deliverySchedules: values.current.deliverySchedule.map((d: any) => {
        return {
          date: DateTime.fromISO(
            `${d.date.split("/")[1]}-${d.date.split("/")[0]}-01`
          ).toISO(),
          quantity: d.value,
        };
      }),
      documents: values.current.documents.map((d: any) => {
        return {
          gcsFilename: d.GCSfilename,
          selectedAt: d.timeselected.toISOString(),
          originalFilename: d.name,
        };
      }),
      productSnapshot,
      publishParticipantData: values.current.publishParticipantData === "yes",
    };
  }

  return productType ? (
    <RegisterSellOfferStyled>
      <form>
        {/* ------------------ FORM 1 ------------------ */}

        <FormAccordion
          isComplete={isCompleteForm1}
          expand={currentForm === 1}
          title="Detalhes da oferta"
          onExpand={() => {
            setCurrentForm(1);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            groups={form1}
            form={form}
            productType={productType}
            metadata={metadata}
            onValidateSuccess={() => setIsCompleteForm1(true)}
            onValidateError={() => {
              setIsCompleteForm1(false);
            }}
            onFinish={() => {
              setCurrentForm(2);
            }}
            showNextButton={true}
            isACloneOffer={baseOffer !== null}
            addressWasAutoSet={addressWasAutomaticallySet}
          />
        </FormAccordion>

        {/*------------------ FORM 2 ------------------*/}

        <FormAccordion
          isComplete={isCompleteForm2}
          expand={currentForm === 2}
          title="Cronograma e condições de entrega e/ou de recebimento do produto"
          onExpand={() => {
            setCurrentForm(2);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            groups={form2}
            metadata={metadata}
            form={form}
            productType={productType}
            onValidateSuccess={() => setIsCompleteForm2(true)}
            onValidateError={() => {
              setIsCompleteForm2(false);
            }}
            onFinish={() => {
              setCurrentForm(3);
              setForm3WasOpened(true);
            }}
            showNextButton={true}
            isACloneOffer={baseOffer !== null}
          />
        </FormAccordion>

        {/*------------------ FORM 3 ------------------*/}

        <FormAccordion
          isComplete={form3WasOpened || baseOffer !== null}
          expand={currentForm === 3}
          title="Restringir a oferta aos clientes indicados abaixo"
          onExpand={() => {
            setCurrentForm(3);
            setForm3WasOpened(true);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            groups={form3}
            metadata={metadata}
            form={form}
            productType={productType}
            onValidateSuccess={() => setIsCompleteForm3(true)}
            onValidateError={() => {
              setIsCompleteForm3(false);
            }}
            onFinish={() => {
              setCurrentForm(4);
              setForm4WasOpened(true);
            }}
            showNextButton={true}
            isACloneOffer={baseOffer !== null}
          />
        </FormAccordion>

        {/*------------------ FORM 4 ------------------*/}

        <FormAccordion
          isComplete={form4WasOpened || baseOffer !== null}
          expand={currentForm === 4}
          title="Documentos para anexar na oferta"
          onExpand={() => {
            setCurrentForm(4);
            setForm4WasOpened(true);
          }}
          onHide={() => {
            setCurrentForm(0);
          }}
        >
          <FormGroupRender
            metadata={metadata}
            groups={form4}
            form={form}
            productType={productType}
            onValidateSuccess={() => setIsCompleteForm4(true)}
            onValidateError={() => {
              setIsCompleteForm4(false);
            }}
            showNextButton={false}
            isACloneOffer={baseOffer !== null}
          />
        </FormAccordion>
      </form>
      {contractUrl && (
        <a href={contractUrl} target="_blank">
          Visualizar Contrato Gerado
        </a>
      )}
      <div className="rowOptions">
        <div className="alignedContent">
          <Button
            onClick={() => {
              history.push("/ofertas");
            }}
            style={{ marginRight: "20px" }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (contractSimulation) {
                setShowContractDialog(true);
              } else {
                setShowConfirmDialog(true);
              }
            }}
            disabled={
              !isCompleteForm1 ||
              !isCompleteForm2 ||
              !isCompleteForm3 ||
              !isCompleteForm4 ||
              isSubmiting
            }
          >
            {contractSimulation ? "Pré-Visualizar Contrato" : "Concluir"}
          </Button>
        </div>
      </div>

      {/* Dialógos */}

      <ConfirmDialog
        open={showConfirmDialog}
        value={(values.current.price || 0) * (values.current.quanty || 0)}
        broker={
          AppStore.selectedStockBroker ? AppStore.selectedStockBroker.name : ""
        }
        participant={
          AppStore.selectedParticipant ? AppStore.selectedParticipant.name : ""
        }
        isSubmiting={isSubmiting}
        unitValue={values.current.price}
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
        onConfirm={() => {
          sendFormData();
        }}
        offerType={offerType}
        unit={productUnit?.abbreviation || ""}
        coin={offerCoin ? (offerCoin.code as string) : undefined}
      />

      <ConfirmContractDialog
        open={showContractDialog}
        onClose={() => {
          setShowContractDialog(false);
        }}
        onConfirm={() => {
          sendContractFormData();
        }}
      />

      <FinishedDialog
        open={showFinishedDialog}
        onConfirm={() => {
          setShowFinishedDialog(false);
          history.push("/ofertas");
        }}
        onClose={() => {
          setShowFinishedDialog(false);
          history.push("/ofertas");
        }}
        offerType={offerType}
      />
    </RegisterSellOfferStyled>
  ) : (
    <NoProductsAllowedDialog
      open={showNoProductsAllowedDialog}
      onClose={() => {
        setShowNoProductsAllowedDialog(false);
        history.push("/ofertas");
      }}
    />
  );
};

export default RegisterSellOffer;
