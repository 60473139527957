import { DateTime } from "luxon";
import { ReactElement } from "react";

export function formatCurrency(number: number, coin: string = "BRL") {
  switch (coin) {
    case "BRL":
      return `R$ ${number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`;
    case "USD":
      return `U$ ${number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`;
    default:
      return `R$ ${number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`;
  }
}

export function getCurrencyPrefix(currencyCode: string) {
  switch (currencyCode) {
    case "BRL":
      return "R$";
    case "USD":
      return "U$";
    default:
      return "R$";
  }
}

export function formatQuanty(number: number) {
  return `${number.toLocaleString("pt-br", { minimumFractionDigits: 0 })}`;
}

export function formatProductUnit(unit: string | null): ReactElement {
  switch (unit) {
    case "m3":
      return (
        <span>
          m<sup>3</sup>
        </span>
      );
    case "m³":
      return (
        <span>
          m<sup>3</sup>
        </span>
      );
    case null:
      return <span></span>;
    default:
      return (
        <span>
          {unit}
        </span>
      );
  }
}

export function checkMarketIsClosed(date: Date) {
  if (process.env.REACT_APP_VERIFY_MARKET_TIME !== "true") {
    return false;
  }

  const currentTime = DateTime.fromJSDate(date);

  if (currentTime.get("weekday") > 5) {
    return true;
  }

  if (
    (currentTime.get("hour") > 17 && currentTime.get("minute") > 30) ||
    currentTime.get("hour") < 8 ||
    (currentTime.get("hour") === 8 && currentTime.get("minute") < 30)
  ) {
    return true;
  }

  return false;
}

export function formatCNPJ(cnpj: string) {
  return cnpj.replace(
    /(\d\d)(\d\d\d)(\d\d\d)(\d\d\d\d)(\d\d)/,
    "$1.$2.$3/$4-$5"
  );
}
