import styled from "styled-components";
import { VariantType } from "theme/variants";

const header: React.FC<any> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(header)`
  .offersHeaderContainer {
    max-width: 100vw;
    padding: 16px;
    .offerTypeColorIcon {
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-right: 4px;
    }
    .offerTypeActionButton {
      width: 200px;
    }
    .offerTypeActionButton {
      .MuiButton-contained:disabled {
        background-color: #ccc;
        color: #555;
        opacity: 0.4;
      }
    }
    .offerTypeTitle {
      span {
        font-weight: 800;
        font-size: 14px;
      }
    }
    .offerTypeColorIconsell {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BBMprimary2.main};
    }
    .offerTypeColorIconbuy {
      background-color: ${(props) =>
        (props.theme as VariantType).palette.BBMprimaryLight.main};
    }
    .offerTypeActionButtonsell {
      .MuiButton-contained {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimary2.main};
      }
      .MuiButton-contained:hover {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimary2.hover};
      }
    }
    .offerTypeActionButtonbuy {
      .MuiButton-contained {
        background-color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimaryLight.main};
      }
      .MuiButton-contained:hover {
        background: ${(props) =>
          (props.theme as VariantType).palette.BBMprimaryLight.hover};
      }
    }

    .filters {
      margin-top: 20px;
      .selectFilters {
      }
      .radioFilters {
        .Mui-checked {
          color: ${(props) =>
            (props.theme as VariantType).palette.BBMprimary2.main};
        }
      }
      .MuiButton-root {
        border: 2px solid
          ${(props) => (props.theme as VariantType).palette.BBMprimary2.main};
        color: ${(props) =>
          (props.theme as VariantType).palette.BBMprimary2.main};
        font-weight: bold;
      }
      .MuiButton-root:disabled {
        opacity: 0.2;
      }
      .MuiMenu-paper {
        height: 150px !important;
      }
    }
  }
`;
